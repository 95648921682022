import {
  GET_LAYER_CATEGORIES_SUCCESS,
  GET_LAYER_CATEGORIES_FAILED,
  FETCH_LAYER_CATEGORY_SUCCESS,
  FETCH_LAYER_CATEGORY_FAILED,
  ADD_LAYER_CATEGORY_SUCCESS,
  ADD_LAYER_CATEGORY_FAILED,
  DELETE_LAYER_CATEGORY_SUCCESS,
  DELETE_LAYER_CATEGORY_FAILED
} from "./constants";
import layerCategoryService from "../../../service/LayerCategoryService";
import { loadingActions } from "../../loader/data/action";
import { snackbarActions } from "../../snackbar/data/action";

export const getLayerCategories = (page, limit) => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    layerCategoryService.getLayerCategories(page, limit).then(response => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });

    function success(response) {
      return { type: GET_LAYER_CATEGORIES_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_LAYER_CATEGORIES_FAILED, error };
    }
  };
};

export const addOrUpdateLayerCategory = data => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    let method = data.layer_category_id > 0 ? "PUT" : "POST";
    layerCategoryService
      .addOrUpdateLayerCategory(data, method)
      .then(response => {
        if (response.data) {
          dispatch(success(response));
        } else {
          dispatch(failure(response));
          dispatch(
            snackbarActions.showSnackbar(
              response.response.data.message
                ? response.response.data.message
                : response.response.data.error
            )
          );
        }
        dispatch(loadingActions.loadingComplete());
      });
  };

  function success(response) {
    return { type: ADD_LAYER_CATEGORY_SUCCESS, response };
  }
  function failure(error) {
    return { type: ADD_LAYER_CATEGORY_FAILED, error };
  }
};

export const fetchLayerCategory = layercategory_id => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    layerCategoryService.fetchLayerCategory(layercategory_id).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });
  };

  function success(response) {
    return { type: FETCH_LAYER_CATEGORY_SUCCESS, response };
  }
  function failure(error) {
    return { type: FETCH_LAYER_CATEGORY_FAILED, error };
  }
};

export const deleteLayerCategory = layercategory_id => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    layerCategoryService
      .deleteLayerCategory(layercategory_id)
      .then(response => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(failure(response));
          dispatch(
            snackbarActions.showSnackbar(
              response.response.data.message
                ? response.response.data.message
                : response.response.data.error
            )
          );
        }
        dispatch(loadingActions.loadingComplete());
      });
  };

  function success(response) {
    return { type: DELETE_LAYER_CATEGORY_SUCCESS, response };
  }
  function failure(error) {
    return { type: DELETE_LAYER_CATEGORY_FAILED, error };
  }
};
