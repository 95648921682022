import localStorageHandler from "../../../../util/storage/";
export default () => {
  return localStorageHandler.getLayerCategory();
};

/* export default function getLayerTree() {
  return [
    {
      value: "boundary",
      label: "Boundary",
      icon: "",
      children: [
        {
          value: "Panchayath Boundary",
          label: "Panchayath Boundary",
          layer_name: "athavanad",
          type: "TILE",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["panchayat"],
          detailedInfoAttributes: ["panchayat", "area"]
        },
        {
          value: "Ward Boundary",
          label: "Ward Boundary",
          layer_name: "athavanad:athavanad_wardboundary_v2",
          type: "TILE",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["ward_no", "ward_name"],
          detailedInfoAttributes: [
            "district",
            "panchayat",
            "area",
            "ward_no",
            "ward_name",
            "area"
          ]
        }
      ]
    },
    {
      value: "Residential",
      label: "Residential",
      showCheckbox: "false",
      icon: "",
      children: [
        {
          value: "Residential Polygon",
          label: "Residential Polygon",
          layer_name: "athavanad:assets_atnd_v2",
          type: "TILE",
          url: "http://athavanad.ulgis.com:8080/geoserver/",
          minimalInfoAttributes: ["propertyi2", "owner_name"],
          detailedInfoAttributes: [
            "propertyi2",
            "owner_name",
            "area",
            "ward_no",
            "ward_name",
            "area",
            "add_own",
            "streetname",
            "survey_no",
            "bld_name",
            "place_name",
            "villagenam",
            "bldg_type",
            "area",
            "photo",
            "photo2"
          ]
        },
        {
          value: "Residential Point",
          label: "Residential Point",
          layer_name: "athavanad:residential_point",
          type: "VECTOR",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["propertyi2", "owner_name"],
          detailedInfoAttributes: [
            "propertyi2",
            "owner_name",
            "area",
            "ward_no",
            "ward_name",
            "area",
            "add_own",
            "streetname",
            "survey_no",
            "bld_name",
            "place_name",
            "villagenam",
            "bldg_type"
          ]
        }
      ]
    },
    {
      value: "Envronmental",
      label: "Envronmental",
      icon: "",
      showCheckbox: "false",
      children: [
        {
          value: "Water",
          label: "Water",
          layer_name: "athavanad:athavanad_waterbody_v2",
          type: "TILE",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["name"],
          detailedInfoAttributes: ["name", "area"]
        },
        {
          value: "Land Use",
          label: "Land Use",
          layer_name: "athavanad:athavanad_lulc_v2",
          type: "TILE",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["category"],
          detailedInfoAttributes: ["category", "area"]
        }
      ]
    },

    {
      value: "commersials",
      label: "Commercials",
      showCheckbox: "false",
      icon: "",
      children: [
        {
          value: "Buisiness",
          label: "Buisiness",
          layer_name: "athavanad:commercial",
          type: "VECTOR",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["propertyi2", "owner_name"],
          detailedInfoAttributes: [
            "propertyi2",
            "owner_name",
            "area",
            "ward_no",
            "ward_name",
            "area",
            "add_own",
            "streetname",
            "survey_no",
            "bld_name",
            "place_name",
            "villagenam",
            "bldg_type"
          ]
        },
        { value: "Industries", label: "Industries" }
      ]
    },
    {
      value: "education",
      label: "Education",
      showCheckbox: "false",
      icon: "",
      children: [
        { value: "College", label: "College" },
        {
          value: "School",
          label: "School",
          layer_name: "athavanad:education",
          type: "TILE",
          url: "http://localhost:8080/geoserver/",
          minimalInfoAttributes: ["propertyi2", "owner_name"],
          detailedInfoAttributes: [
            "propertyi2",
            "owner_name",
            "area",
            "ward_no",
            "ward_name",
            "area",
            "add_own",
            "streetname",
            "survey_no",
            "bld_name",
            "place_name",
            "villagenam",
            "bldg_type"
          ]
        }
      ]
    },
    {
      value: "fuel",
      label: "Fuel Sations",
      showCheckbox: "false",
      icon: "",
      children: [
        { value: "Petrol Pump", label: "Petrol Pump" },
        { value: "Gas Agency", label: "Gas Agency" }
      ]
    },
    {
      value: "Government Office",
      label: "Government Office",
      showCheckbox: "false",
      icon: "",
      children: [
        { value: "Government Office", label: "Petrol Pump" },
        { value: "Ward Office", label: "Ward Agency" },
        { value: "Zone Office", label: "Zone Office" }
      ]
    },

    {
      value: "Health Services",
      label: "Health Services",
      showCheckbox: "false",
      icon: "",
      children: [
        { value: "Medical Store", label: "Medical Store" },
        { value: "Hospital", label: "Hospital" },
        { value: "Clinic", label: "Clinic" },
        { value: "Dispensary", label: "Dispensary" }
      ]
    }
  ];
}
 */
