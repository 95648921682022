import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { snackbarActions } from "../../snackbar/data/action";
import queryString from "query-string";
import { reasetPassword } from "./data/actions";
import Snackbar from "../../snackbar";
import {
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  Button,
  Row
} from "react-bootstrap";
import "./ForgotPassword.css";
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        newpsw: "",
        confirmpsw: "",
        token: ""
      },
      newpswerror: "",
      confirmpswerror: ""
    };
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    this.setState({
      data: {
        token: parsed.token
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.APIRequest !== this.props.APIRequest) {
      if (this.props.responses.message == "Success") {
        this.props.showSnackbar("Change Password Successfully..!");
        setTimeout(() => {
          this.props.history.push("/login");
        }, 1);
      } else {
        this.props.showSnackbar(this.props.responses.message);
      }
    }
  }
  handleInputChange = event => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value }
    });
  };
  validateForm = event => {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$/;
    const isValid = reg.test(this.state.data.newpsw);
    if (!reg.test(this.state.data.newpsw)) {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "Password should be strong",
        confirmpswerror: ""
      });
      return false;
    } else if (this.state.data.newpsw != this.state.data.confirmpsw) {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "",
        confirmpswerror: "Password miss match"
      });
      return false;
    } else {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "",
        confirmpswerror: ""
      });
      return true;
    }
  };
  onSubmit = e => {
    e.preventDefault();
    if (this.validateForm()) {
      let args = {
        password: this.state.data.newpsw,
        confirmPassword: this.state.data.confirmpsw,
        token: this.state.data.token
      };
      this.props.reasetPassword(args);
    }
  };
  render() {
    return (
      <div className="forgotContainer">
        <Snackbar />
        <div className="forgot">
          <Form onSubmit={this.onSubmit}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup
                controlId="address"
                bsSize="large"
                className="passwordinpt"
              >
                <FormControl
                  type="password"
                  placeholder="New Password"
                  value={this.state.data.newpsw}
                  name="newpsw"
                  onChange={this.handleInputChange}
                  required
                  className="forgotText"
                />
                <FormControl.Feedback>
                  <Glyphicon glyph="lock" />
                </FormControl.Feedback>
              </FormGroup>
              <div>
                <p className="errorClass">{this.state.newpswerror}</p>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup controlId="email" bsSize="large">
                <FormControl
                  type="password"
                  placeholder="Confirm Password"
                  value={this.state.data.confirmpsw}
                  name="confirmpsw"
                  onChange={this.handleInputChange}
                  required
                  className="forgotText"
                />
                <FormControl.Feedback>
                  <Glyphicon glyph="lock" />
                </FormControl.Feedback>
              </FormGroup>{" "}
              <div>
                <p className="errorClass">{this.state.confirmpswerror}</p>
              </div>
            </Col>
            <Row>
              <FormGroup>
                <Col xs={1} sm={1} md={1} lg={1} />
                <Col xs={5} sm={5} md={5} lg={5}>
                  <Button
                    className="forgotButton"
                    bsStyle="warning"
                    bsSize="large"
                    onClick={this.handleCancel}
                    block
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5}>
                  <Button
                    className="forgotButton"
                    type="submit"
                    bsSize="large"
                    bsStyle="success"
                    block
                  >
                    Submit
                  </Button>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1} />
              </FormGroup>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    responses: state.forgotPasswordReducer.responses,
    APIRequest: state.forgotPasswordReducer.APIRequest
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reasetPassword: data => dispatch(reasetPassword(data)),
    showSnackbar: snackbarMessage =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePassword)
);
