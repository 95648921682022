import { combineReducers } from "redux";
import loginReducer from "../components/user/login/data/reducer";
import forgotPasswordReducer from "../components/user/forgot_password/data/reducer";
import userReducer from "../components/user/master/data/reducer";
import changePasswordReducer from "../components/user/change_password/data/reducer";
import mapSearch from "../components/map_viewer/map_header/navigation/navbar_form/data/reducer";
import mapReducer from "../components/map_viewer/map/map_component/data/reducer";
import exportPdfReducer from "../components/map_viewer/map/widgets/export_pdf/data/reducer";
import advancedFilter from "../components/map_viewer/map_header/advanced_filter/data/reducer";
import loading from "../components/loader/data/reducer";
import snackbar from "../components/snackbar/data/reducer";
import pageHeader from "../containers/page_layout/data/reducer";
import layerCategory from "../components/layercategory/data/reducer";
import layer from "../components/layer/data/reducer";
import dropdown from "../containers/dropdown/data/reducer";
import buffer from "../components/map_viewer/map/widgets/buffer/data/reducer";
import mapQuery from "../components/map_viewer/map/widgets/select_by_polygon/data/reducer";
import addAsset from "../components/map_viewer/map/widgets/add_asset/data/reducer";
import updateReport from "../components/updateReport/data/reducer";

export default combineReducers({
  loginReducer,
  userReducer,
  changePasswordReducer,
  mapSearch,
  mapReducer,
  advancedFilter,
  loading,
  snackbar,
  pageHeader,
  layerCategory,
  layer,
  dropdown,
  forgotPasswordReducer,
  buffer,
  mapQuery,
  addAsset,
  exportPdfReducer,
  updateReport,
});
