import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Filter.css";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
} from "react-bootstrap";
import { toggleAdvancedFilterWindow } from "../../map_viewer/map_header/navigation/navbar_form/data/action";

import FilterForm from "../../map_viewer/map_header/advanced_filter/components/FilterForm";
import closebtn from "../../map_viewer/map/widgets/iconimages/close_icon.svg";

var temp;
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: [],
    };
  }
  render() {
    return (
      <Modal show={this.props.show} className="reportFilterModal">
        <Panel bsStyle="primary" className="reportFilterPanel">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Filter</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closebtn}
                className="panelheadbtn"
                onClick={this.okClick}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body>
            <Row>
              {this.props.filterFormCount.map((element, index) => {
                if (index < 8)
                  return (
                    <FilterForm
                      data={this.props.data}
                      handleInputChange={this.props.handleInputChange}
                      handleInputChangedropdown={
                        this.props.handleInputChangedropdown
                      }
                      handleInputChangeAttribute={
                        this.props.handleInputChangeAttribute
                      }
                      handleToUpdate={this.props.handleToUpdate}
                      handleInputChangeOperation={
                        this.props.handleInputChangeOperation
                      }
                      index={index}
                      key={index}
                      temp={temp}
                    />
                  );
              })}
            </Row>
            <Row style={{ margin: "20px 10px 10px 10px" }}>
              <Button
                bsStyle="danger"
                className="pull-left clearButton"
                disabled={this.props.filterFormCount.length === 0}
                onClick={this.props.clearFilter}
              >
                Clear Filter
              </Button>
              <Button
                inline="true"
                bsStyle="success"
                className="pull-right clearButton"
                style={{ marginLeft: "3px" }}
                onClick={this.okClick}
                disabled={
                  this.props.data.layer === "" ||
                  this.props.filterFormCount.length == 0
                }
              >
                OK
              </Button>
              <Button
                inline="true"
                bsStyle="primary"
                className="pull-right clearButton"
                // style={{ padding: "3px" }}
                onClick={() => this.props.addFilterForm("AddMore")}
                disabled={
                  this.props.data.layer === "" ||
                  this.props.filterFormCount.length === 8
                }
              >
                <i className="glyphicon glyphicon-plus-sign" />
                Add Filter
              </Button>
            </Row>
          </Panel.Body>
        </Panel>
      </Modal>
    );
  }
  okClick = () => {
    this.props.hide();
    this.props.filterCheck();
  };
}

function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedfilterWindow,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hide: () => dispatch(toggleAdvancedFilterWindow()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filter));
