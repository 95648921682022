import React, { Component } from "react";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import { connect } from "react-redux";
import { updateLegendUrls } from "../../map_component/data/actions";
import getLayerTree from "../../helper/LayerTreeLoader";

class LegendControl extends Component {
  state = {
    visibleImageUrls: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showLegendControl !== this.props.showLegendControl) {
      this.setState({
        visibleImageUrls: this.getLegendUrls(),
        showLegendControl: this.props.showLegendControl,
      });
    }
  }
  getLegendUrls = () => {
    const { mapLayers } = this.props;
    let layerTree = getLayerTree();
    let urls = [];
    for (let i = 0; i < mapLayers.length; i++) {
      if (mapLayers[i].getVisible() && mapLayers[i].type == "TILE") {
        let layer = mapLayers[i].getSource().getParams().LAYERS;
        let layerName = "";
        let layerNameValue = mapLayers[i].getProperties().name;
        let url = null;
        let style = null;

        layerTree.map((treeItem) => {
          if (treeItem.hasOwnProperty("children")) {
            treeItem.children.forEach((layer) => {
              if (layer.value == layerNameValue) {
                layerName = treeItem.label;

                if (layer.style != null) style = layer.style;
              }
            });
          }
        });
        if (style == null) {
          url =
            mapLayers[i].getSource().urls[0] +
            "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
            layer +
            "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        } else {
          url =
            mapLayers[i].getSource().urls[0] +
            "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
            layer +
            "&STYLE=" +
            style +
            "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        }
        var element = {};

        element.layerName = layerName;
        element.url = url;
        element.layer = layerNameValue;

        urls.push(element);
      }
    }
    for (let i = 0; i < urls.length; i++) {
      for (let j = i + 1; j < urls.length; j++) {
        if (urls[i].layerName === urls[j].layerName) {
          urls[j].layerName = "";
        }
      }
    }

    this.props.updateLegendUrls(urls);
    return urls;
  };

  render() {
    return (
      <OverlayPanel
        showControl={this.state.showLegendControl}
        handleShowControl={this.props.handleShowControl}
        titleName="Legends"
      >
        {this.state.visibleImageUrls.length === 0 && (
          <h5>There are no Layers selected</h5>
        )}
        {this.state.visibleImageUrls.map((image, index) => (
          <React.Fragment key={index}>
            <h5 style={{ marginLeft: "20px", color: "#204B84 " }}>
              <b>{image.layerName}</b>
            </h5>
            {image.layer !== "" ? (
              <h5 style={{ marginLeft: "20px" }}>
                <b>{image.layer}</b>
              </h5>
            ) : (
              ""
            )}
            <img src={image.url} alt="" style={{ marginLeft: "25px" }} />
            <br />
          </React.Fragment>
        ))}
      </OverlayPanel>
    );
  }
}
function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateLegendUrls: (urls) => dispatch(updateLegendUrls(urls)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LegendControl);
