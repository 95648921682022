import React, { Component } from "react";
import {
  Collapse,
  Row,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Table,
  Carousel,
  Modal,
  Badge,
  Label,
  ProgressBar,
} from "react-bootstrap";
import "./DetailedInfo.css";
import "./RelatedTables.css";
import LocalStorageHandler from "../../../../../util/storage";
import closebtn from "../iconimages/close_icon.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ADMIN_URL } from "../../../../../../src/config";
class DetailedInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageName: null,
      show: false,
      showpdf: false,
      img: null,
      key: null,
      pdf_mgmt: false,
      pdf_doc: false,
      pdffile: null,
      total_count: 0,
      pdf_doc_file: null,
      pdf_mgmt_file: null,
      image_status: true,
      image_class: "img-responsive center-block",
      memberDetailStatus: false,
      basicDetailStatus: true,
    };
    this.displayImage = this.displayImage.bind(this);
  }
  displayImage() {
    this.setState({
      show: !this.state.show,
    });
  }
  rotateImage() {
    if (this.state.image_status) {
      this.setState({
        image_class: "img-responsive center-block image-rotate",
        image_status: !this.state.image_status,
      });
    } else {
      this.setState({
        image_class: "img-responsive center-block ",
        image_status: !this.state.image_status,
      });
    }
  }
  updationStatusAvailable = (value) => {
    if (value === "0") {
      return <h7 style={{ color: "#5400db" }}>created</h7>;
    }
    if (value === "1") {
      return <h7 style={{ color: "#039935" }}>submitted</h7>;
    }
    if (value === "2") {
      return <h7 style={{ color: "#11612c" }}>verified</h7>;
    }
    if (value === "3") {
      return <h7 style={{ color: "#059936" }}>approved</h7>;
    }
    if (value === "4") {
      return <h7 style={{ color: "#0095ff" }}>edited</h7>;
    }
    if (value === "5") {
      return <h7 style={{ color: "#b80229" }}>Rejected from verification</h7>;
    }
    if (value === "6") {
      return <h7 style={{ color: "#f00537" }}>Rejected from approval</h7>;
    } else {
      return <h7>{value}</h7>;
    }
  };
  formatInfo() {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent =
        Object.keys(this.props.detailedInfoContents).length > 0
          ? this.props.detailedInfoContents[this.props.count]
          : undefined;
      // const entries = Object.entries(detailedInfoContent);
      // console.log("entries: ", entries);
      // console.log(
      //   entries.sort(function (a, b) {
      //     return a[1].index - b[1].index;
      //   })
      // );

      //console.log("detailedInfoContent: ", detailedInfoContent);

      if (
        detailedInfoContent != undefined &&
        Object.keys(detailedInfoContent).length > 0
      )
        return Object.keys(detailedInfoContent).map((key) => {
          if (detailedInfoContent[key].display_name === "Updation status") {
            this.props.detailedInfoContents[this.props.count].updation_status =
              detailedInfoContent[key].value;
            return (
              <>
                {!(
                  detailedInfoContent[key].value === "NA" ||
                  detailedInfoContent[key].value === -2
                ) && (
                  <tr key={key}>
                    <td style={{ width: "350px" }}>
                      {detailedInfoContent[key].display_name}
                    </td>
                    <td style={{ width: "350px" }}>
                      {detailedInfoContent[key].value === "false"
                        ? "NO"
                        : detailedInfoContent[key].value === "true"
                        ? "YES"
                        : detailedInfoContent[key].value === "NR" ||
                          detailedInfoContent[key].value === -1
                        ? "NOT RECEIVED"
                        : this.updationStatusAvailable(
                            detailedInfoContent[key].value
                          )}
                    </td>
                  </tr>
                )}
              </>
            );
          }

          if (
            !key.includes("photo") &&
            !key.includes("p_d_f_") &&
            detailedInfoContent[key].value != "NA"
          ) {
            return (
              <>
                {!(
                  detailedInfoContent[key].value === "NA" ||
                  detailedInfoContent[key].value === -2
                ) && (
                  <tr key={key}>
                    <td style={{ width: "300px" }}>
                      {detailedInfoContent[key].display_name}
                    </td>
                    <td style={{ width: "300px" }}>
                      {detailedInfoContent[key].value === "false"
                        ? "NO"
                        : detailedInfoContent[key].value === "true"
                        ? "YES"
                        : detailedInfoContent[key].value === "NR" ||
                          detailedInfoContent[key].value === -1
                        ? "NOT RECEIVED"
                        : detailedInfoContent[key].value}
                    </td>
                  </tr>
                )}
              </>
            );
          }
        });
    }
  }

  formatInfoForeign() {
    if (this.props.detailedInfoContentsForeignKey != null) {
      const detailedInfoContentForeignKey =
        Object.keys(this.props.detailedInfoContentsForeignKey).length > 0
          ? this.props.detailedInfoContentsForeignKey[this.props.count]
          : undefined;
      // console.log("detailedInfoContent", detailedInfoContentForeignKey);
      //console.log(
      //   "detailedInfoContentForeignKey: ",
      //   detailedInfoContentForeignKey
      // );
      if (
        detailedInfoContentForeignKey != undefined &&
        Object.keys(detailedInfoContentForeignKey).length > 0
      )
        return Object.keys(detailedInfoContentForeignKey.foreignKeys).map(
          (key) => {
            if (
              !key.includes("photo") &&
              !key.includes("p_d_f_") &&
              detailedInfoContentForeignKey.foreignKeys[key].value != "NA"
            ) {
              return (
                <tr key={key}>
                  <td>
                    {detailedInfoContentForeignKey.foreignKeys[key].display_name
                      ? detailedInfoContentForeignKey.foreignKeys[key]
                          .display_name
                      : detailedInfoContentForeignKey.foreignKeys[key].field}
                  </td>
                  <td>
                    {detailedInfoContentForeignKey.foreignKeys[key]
                      .actual_value === "false"
                      ? "NO"
                      : detailedInfoContentForeignKey.foreignKeys[key]
                          .actual_value === "true"
                      ? "YES"
                      : detailedInfoContentForeignKey.foreignKeys[key]
                          .actual_value === "NR"
                      ? "NOT RECEIVED"
                      : detailedInfoContentForeignKey.foreignKeys[key]
                          .actual_value}
                  </td>
                </tr>
              );
            }
          }
        );
    }
  }

  renderProgres() {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent =
        this.props.detailedInfoContents[this.props.count];
      let prog = [];
      var element = {};
      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("c_h_p_")) {
              element = {};
              element.name = detailedInfoContent[key].display_name
                ? detailedInfoContent[key].display_name
                : detailedInfoContent[key].field;
              element.per = detailedInfoContent[key].value;
              prog.push(element);
            }
          });
        }
        return prog;
      } catch (e) {
        console.log("Exception", e);
      }
    }
  }
  renderImage() {
    let imageName = [];
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent =
        this.props.detailedInfoContents[this.props.count];

      try {
        if (detailedInfoContent != undefined) {
          Object.keys(detailedInfoContent).map((key) => {
            if (key.includes("photo") && detailedInfoContent[key].value != null)
              imageName.push(
                detailedInfoContent[key].value.replace("JPG", "jpg")
              );
          });
        }
        return imageName;
      } catch (e) {
        console.log("Exception", e);
      }
    }
  }

  roadPreview = () => {
    if (this.props.detailedInfoContents != null) {
      const detailedInfoContent =
        this.props.detailedInfoContents[this.props.count];
      if (detailedInfoContent != undefined)
        return Object.keys(detailedInfoContent).map((key) => {
          if (
            key.includes("road_name") &&
            detailedInfoContent[key].value != "NA"
          ) {
            return (
              <Button
                className="editAddButton"
                onClick={this.handleRoadDetailPreview}
              >
                Print Details
              </Button>
            );
          }
        });
    }
  };

  handleRoadDetailPreview = () => {
    const image = this.renderImage();
    const img_url = LocalStorageHandler.getLocalBody().image_url;
    const roadImage = img_url.concat(image);
    function toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }
    var doc = jsPDF();
    // Create and save  the PDF.
    // The argument is the Base64 string for the image.
    function makePDF(base64Img) {
      doc.setProperties({
        title: "KWA Road Details",
      });
      doc.addImage(base64Img, "JPEG", 15, 190, 180, 100);
      doc.autoTable({ html: "#roadDetails" });
      window.open(doc.output("bloburl"), "_blank");
    }
    // Convert URL to Base64, then call makePDF function.
    toDataUrl(roadImage, makePDF);
  };
  openPdf() {
    var pdf = "aa.pdf";
    window.open(pdf);
  }
  render() {
    const items = this.formatInfo();
    const itemsForeign = this.formatInfoForeign();
    const images = this.renderImage();
    const prog = this.renderProgres();
    const image_class = this.state.image_class;
    const img_url = LocalStorageHandler.getLocalBody().image_url;
    const { detailedInfoContent, selectedLayer } = this.props;
    var layerName = "";
    if (selectedLayer) {
      layerName = selectedLayer.getProperties().name;
    }
    var layer_id =
      this.props.detailedInfoContentsForeignKey &&
      this.props.detailedInfoContentsForeignKey[this.props.count].id;
    return (
      <React.Fragment>
        <Collapse
          in={this.props.isDetailedInfoVisible}
          className="featureInfoPanel"
        >
          <Panel bsStyle="success" className="detailedInfoPanel">
            <Panel.Heading>
              <Panel.Title>
                <Label className="padding" bsStyle="primary">
                  Total Features
                </Label>{" "}
                <Badge>{this.props.total_count + 1}</Badge>
              </Panel.Title>

              <ButtonToolbar className="pull-right button">
                <ButtonGroup>
                  <Button
                    // bsStyle="primary"
                    onClick={this.props.onCountDecrement}
                    className="topbutton"
                    disabled={this.props.count == 0}
                  >
                    <i className="fa fa-angle-left" />
                  </Button>
                  <Button
                    // bsStyle="primary"
                    className="topbutton infobutton"
                  >
                    {this.props.count + 1}
                  </Button>
                  <Button
                    // bsStyle="primary"
                    className="topbutton"
                    disabled={this.props.count == this.props.total_count}
                    onClick={this.props.onCountIncrement}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                  <button
                    className="dpanelheadbtn"
                    onClick={this.props.onDetailedInfoClose}
                  >
                    <img src={closebtn} />
                  </button>
                </ButtonGroup>
              </ButtonToolbar>
            </Panel.Heading>
            <Modal
              className="imageModel"
              show={this.state.show}
              bsSize="large"
              aria-labelledby="contained-modal-title-lg"
              onHide={this.displayImage}
            >
              <Modal.Header className="imageModelHeader">
                <Modal.Title id="contained-modal-title-lg">
                  Photographs
                  <ButtonToolbar className="pull-right button">
                    <img
                      src={closebtn}
                      className="photoheadbtn"
                      onClick={this.displayImage}
                    />
                  </ButtonToolbar>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="imageModelBody">
                {this.props.detailedInfoContents != null && (
                  <div className="imageContainerModel">
                    {images != undefined && images.length > 0 && (
                      <Carousel>
                        {images.map(function (image, index) {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                className={image_class}
                                key={index}
                                src={img_url.concat(image)}
                                width={750}
                                height={750}
                                alt="350x350"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="imageModelFooter">
                <Button bsStyle="primary" onClick={() => this.rotateImage()}>
                  Rotate
                </Button>
                <Button bsStyle="primary" onClick={this.displayImage}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {this.props.detailedInfoContents != null && (
              <Panel.Body
                className="featureInfoPanelBody"
                id="featureInfoPanelBody"
              >
                <div className="imageContainer" onClick={this.displayImage}>
                  {images != undefined && images.length > 0 && (
                    <Carousel>
                      {images.map(function (image, index) {
                        return (
                          <Carousel.Item key={index}>
                            <img
                              key={index}
                              src={img_url.concat(image)}
                              width={350}
                              height={350}
                              alt="350x350"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  )}
                </div>
                <div>
                  {prog.length == 3 && (
                    <div className="show-gridsss">
                      {prog.map((element) => {
                        return (
                          <Row>
                            <Col md={4} className="progresbarname">
                              <p>
                                <b>{element.name}</b>
                              </p>
                            </Col>
                            <Col md={8} className="progresbar">
                              <ProgressBar
                                striped
                                active
                                bsStyle="success"
                                now={element.per}
                                label={`${element.per}%`}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* <div style={{width: "283px"}}> */}
                <Button
                  bsStyle="primary"
                  block
                  onClick={(e) => {
                    this.setState({
                      basicDetailStatus: !this.state.basicDetailStatus,
                    });
                  }}
                >
                  Basic Details
                </Button>
                <Collapse
                  in={this.state.basicDetailStatus}
                  className="featureInfoPanel"
                >
                  <Table
                    striped
                    bordered
                    condensed
                    hover
                    id="roadDetails"
                    className="detailedInfoContent"
                    style={{ width: "320px" }}
                  >
                    <tbody className="tablebody">
                      {this.formatInfo()}
                      {itemsForeign}
                    </tbody>
                    {/* {this.roadPreview()} */}
                  </Table>
                </Collapse>
                {this.props.detailedInfoContentsForeignKey &&
                this.props.detailedInfoContentsForeignKey[this.props.count] &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .editable &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .available_permissions &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .available_permissions.length ? (
                  <Button
                    bsStyle="primary"
                    block
                    onClick={(e) => {
                      this.setState({
                        editAddItem: !this.state.editAddItem,
                      });
                    }}
                  >
                    Action
                  </Button>
                ) : (
                  ""
                )}
                {this.props.detailedInfoContentsForeignKey &&
                this.props.detailedInfoContentsForeignKey[this.props.count] &&
                this.props.detailedInfoContentsForeignKey[this.props.count]
                  .available_permissions ? (
                  <Collapse
                    in={this.state.editAddItem}
                    className="featureInfoPanel"
                  >
                    <div>
                      {this.props.detailedInfoContentsForeignKey[
                        this.props.count
                      ].available_permissions.indexOf("can_edit") >= 0 ? (
                        <Button
                          className="editAddButton"
                          onClick={(e) => {
                            window.open(
                              ADMIN_URL +
                                "map/login/" +
                                LocalStorageHandler.getToken() +
                                "/" +
                                layer_id.replace("_", "/").replace(".", "/") +
                                "/change/",
                              "_blank"
                            );
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </Collapse>
                ) : (
                  ""
                )}
                <div className="pdfBotton">
                  <ButtonGroup bsSize="xsmall">
                    {this.state.pdf_mgmt && (
                      <Button
                        bsStyle="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.displayPdf(this.state.pdf_mgmt_file, e);
                        }}
                      >
                        Managment Details
                      </Button>
                    )}
                    {this.state.pdf_doc && (
                      <Button
                        bsStyle="success"
                        onClick={(e) => {
                          e.preventDefault();
                          this.displayPdf(this.state.pdf_doc_file, e);
                        }}
                      >
                        Documents
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              </Panel.Body>
            )}
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default DetailedInfo;
