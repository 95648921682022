import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import {
  fetchDataByAttribute,
  getColumns,
  getLayers,
  fetchValueByAttribute,
} from "../data/action";
import ConditionSelector from "./ConditonSelector";
import { snackbarActions } from "../../../../snackbar/data/action";
import { connect } from "react-redux";
import getAttributeName from "../../../../../util/attributes";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

var temp = [];
class FilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBy: null,
      searchValue: null,
      options: [],
      option: [],
      isLoading: false,
      layerToSearch: null,
      attributes: {},
      layers: {},
      layer: "",
      layerCategory: "",
      data: {
        operator: "",
        attribute: "",
        autocomplete: "",
        model: "",
        value: [],
      },
      attributeName: "",
    };
  }

  componentDidMount() {
    this.props.getLayers();
  }
  //Auto completion search
  handleSearch = (query, index) => {
    this.setState({ isLoading: true });
    let res = this.props.data.layer.split(":");
    let table = res[1];
    //function for search data
    this.props.fetchDataByAttribute(
      this.props.data["column".concat(index)],
      query,
      table
    );
  };

  attributeSelect = (event, index) => {
    const displayName = event.target.value;
    const attributeInformation = this.props.data.layer.advancefilterinfo.find(
      (attribute) => attribute.display_name === displayName
    );
    this.setState({
      attributeName: attributeInformation.field,
    });
    this.props.handleInputChangeAttribute(
      event,
      index,
      attributeInformation.field,
      attributeInformation.model_name,
      attributeInformation.parent_model,
      attributeInformation.app_label
    );

    if (attributeInformation.auto_complete) {
      this.props.fetchValueByAttribute(
        attributeInformation.model_name,
        attributeInformation.field,
        attributeInformation.data_type,
        this.props.data.layer.category_field,
        this.props.data.layer.category_id,
        this.props.data.layer.app_label
      );
    }
  };

  handleSearchValueChange = (evt, index) => {
    //function for setting search value for filtering
    if (evt.length != 0) {
      this.props.handleInputChangedropdown(evt[0], index);
    }

    // this._typeahead._updateSelected([this.state.option]);
    //search value setting for auto completion

    this.setState(
      {
        searchValue: evt.length == 0 ? null : evt[0],
        option: evt.length == 0 ? [] : evt,
      },
      () => {
        temp = this.state.option;
        console.log("temp: ", temp);
        this.props.handleToUpdate(temp, index);
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.datas != this.props.datas) {
      this.setState(
        {
          isLoading: false,
          options: this.props.datas,
        },
        () => {}
      );
    }
  }
  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  attributeList = () => {
    return this.props.data.layer.advancefilterinfo
      .sort((a, b) => (a["display_name"] > b["display_name"] ? 1 : -1))
      .map((column, index) => {
        if (
          !(
            this.props.data.layer.layer_id == 2 &&
            column.model_name == "Establishment"
          ) &&
          !(
            this.props.data.layer.layer_id != 2 &&
            (column.model_name == "Member_Details" ||
              column.model_name == "Property_Other_Details" ||
              column.model_name == "Member_Diseases")
          )
        ) {
          return (
            <option key={index} value={column.display_name}>
              {column.display_name}
            </option>
          );
        }
      });
  };

  render() {
    const { index, layerColumns } = this.props;
    return (
      <div>
        {index > 0 && (
          <ConditionSelector
            data={this.props.data}
            handleInputChange={this.props.handleInputChange}
            index={index}
          />
        )}
        <FormGroup controlId="formInlineName">
          <Col xs={4} sm={4} md={4} lg={4}>
            <ControlLabel>Attribute</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              name={"column".concat(index)}
              value={this.props.data["column".concat(index)]}
              onChange={(e) => this.attributeSelect(e, index)}
              autoComplete="on"
              required
            >
              <option value="">SELECT</option>
              {this.attributeList()}
            </FormControl>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3}>
            <ControlLabel>Operation</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              name={"operation".concat(index)}
              value={this.props.data["operation".concat(index)]}
              onChange={this.props.handleInputChangeOperation}
              disabled={this.props.data["column".concat(index)] == ""}
              required
            >
              <option value="">SELECT</option>
              {this.props.data.layer.advancefilterinfo.map((column) => {
                if (
                  this.props.data["column".concat(index)] == column.display_name
                ) {
                  return Object.keys(column.operators.op).map((key, i) => {
                    return (
                      <option value={key}>{column.operators.op[key]}</option>
                    );
                  });
                }
              })}
            </FormControl>
          </Col>
          {/* {this.props.data["operation".concat(index)] != "=" && ( */}
          <Col xs={4} sm={5} md={5} lg={5}>
            <ControlLabel>Value</ControlLabel>
            {this.props.data.layer.advancefilterinfo.map((column) => {
              if (
                this.props.data["column".concat(index)] == column.display_name
              ) {
                console.log(
                  "length",
                  this.props.data["temp".concat(index)].length,
                  this.props.data["temp".concat(index)][0]
                );
                if (column.auto_complete != true) {
                  return (
                    <FormControl
                      type="input"
                      placeholder="ENTER"
                      name={"value".concat(index)}
                      value={this.props.data["value".concat(index)]}
                      disabled={
                        this.props.data["operation".concat(index)] == "="
                      }
                      onChange={(event) => {
                        this.handleSearchValueChange(event, index);
                      }}
                      required
                    />
                  );
                } else {
                  return (
                    <Typeahead
                      id="basic-typeahead-single"
                      disabled={
                        this.props.data["operation".concat(index)] == "="
                      }
                      labelKey="name"
                      name={"value".concat(index)}
                      onChange={(event) => {
                        this.handleSearchValueChange(event, index);
                      }}
                      options={this.state.options}
                      placeholder="SELECT"
                      defaultInputValue={
                        this.props.data["temp".concat(index)].length == 0
                          ? ""
                          : this.props.data["temp".concat(index)][0]
                      }
                    />
                  );
                }
              }
            })}
          </Col>
          {/* )} */}
        </FormGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    layerColumns: state.advancedFilter.layerColumns,
    // attributes: state.advancedFilter.attributes,
    datas: state.advancedFilter.data,
    layers: state.advancedFilter.layers,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchDataByAttribute: (searchBy, query, table) =>
      dispatch(fetchDataByAttribute(searchBy, query, table)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getColumns: () => dispatch(getColumns()),
    getLayers: () => dispatch(getLayers()),
    fetchValueByAttribute: (
      model,
      attribute,
      data_type,
      category_field,
      category_id,
      app_label
    ) =>
      dispatch(
        fetchValueByAttribute(
          model,
          attribute,
          data_type,
          category_field,
          category_id,
          app_label
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
