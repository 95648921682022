import {
  USER_FETCHING,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILED,
  USER_ADDIND,
  USER_ADD_SUCCESS,
  USER_ADD_FAILED,
  USER_FETCH_BY_ID_SUCCESS,
  USER_FETCH_BY_ID_FAILED,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED
} from "./constants";
import userService from "../../../../service/UserService";
import { loadingActions } from "../../../loader/data/action";
import { snackbarActions } from "../../../snackbar/data/action";
export const fetchUsers = (page, limit) => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    dispatch(request());
    userService.fetchUsers(page, limit).then(response => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar(response.response.data.error));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: USER_FETCHING };
    }
    function success(response) {
      return { type: USER_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: USER_FETCH_FAILED, error };
    }
  };
};

export const addOrUpdateUser = data => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    dispatch(request(data));
    let method = data.user_access_id > 0 ? "PUT" : "POST";

    userService.addOrUpdateUser(data, method).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar(response.response.data.message));
      }
      dispatch(loadingActions.loadingComplete());
    });
  };
  function request() {
    return { type: USER_ADDIND };
  }
  function success(response) {
    return { type: USER_ADD_SUCCESS, response };
  }
  function failure(error) {
    return { type: USER_ADD_FAILED, error };
  }
};

export const fetchUserByID = userId => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    userService.fetchUserByID(userId).then(response => {
      if (response) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar(response.response.data.error));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function success(response) {
      return { type: USER_FETCH_BY_ID_SUCCESS, response };
    }
    function failure(error) {
      return { type: USER_FETCH_BY_ID_FAILED, error };
    }
  };
};

export const deleteUser = userId => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    userService.deleteUser(userId).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar(response.response.data.error));
      }
      dispatch(loadingActions.loadingComplete());
    });
  };
  function success(response) {
    return { type: USER_DELETE_SUCCESS, response };
  }
  function failure(error) {
    return { type: USER_DELETE_FAILED, error };
  }
};
