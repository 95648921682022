import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Map from "ol/Map.js";
import XYZ from "ol/source/XYZ";
import View from "ol/View.js";
import { defaults as defaultControls } from "ol/control.js";
import { Group as LayerGroup } from "ol/layer.js";
import MousePosition from "ol/control/MousePosition.js";
import { createStringXY } from "ol/coordinate.js";
import ScaleLine from "ol/control/ScaleLine";
import OSM from "ol/source/OSM.js";
import TileLayer from "ol/layer/Tile.js";

import { Vector as VectorLayer } from "ol/layer.js";
import { transform, toLonLat, fromLonLat } from "ol/proj.js";
import Draw from "ol/interaction/Draw.js";
import generateLayer from "../helper/LayerLoader";
import getLayerTree from "../helper/LayerTreeLoader";
// import getBaseLayerData from "../helper/BaseLayerLoader";
import LegendWidget from "../widgets/legend/LegendWidget";
import Opacity from "../widgets/opacity/OpacityWidget";
import LayerTree from "../widgets/layer_tree/LayerTree";
import StyleLoader from "../widgets/styleloader/StyleLoader";
import BaseMap from "../widgets/base_map/BaseMap";
import SelectByPolygon from "../widgets/select_by_polygon/SelectByPolygon";
import DefaultExtend from "../widgets/default_extend/DefaultExtend";
import FeatureInfo from "../widgets/feature_info/";
import MeasureAreaLength from "../widgets/measure/MeasureAreaLength";
import { updateMapReference } from "./data/actions";
import ExportPdf from "../widgets/export_pdf/ExportPdf";
import AddAsset from "../widgets/add_asset/AddAsset";
import GoToXY from "../widgets/goto_xy/GoToXY";
import Settings from "../widgets/usersettings/UserSettings";
import mapDefaults from "../helper/mapDefaults";
import GetCount from "../widgets/get_count/GetCount";
import "./Map.css";
import CurrentLocation from "../widgets/current_location/CurrentLocation";
import { Icon, Style } from "ol/style.js";
// import { Checkbox } from "react-bootstrap";
// import { addOrUpdateProperty } from "../components";
import VectorSource from "ol/source/Vector.js";
import LocalStorageHandler from "../../../../util/storage";

import { Fill, Stroke, Circle } from "ol/style.js";
import pin from "../map_component/pin.png";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { reset } from "ol/transform";
import Toolbar from "../../map_header/navigation/toolbar/Toolbar";
import Buffer from "../widgets/buffer/Buffer";
import Hello from "../widgets/chat_bot/ChatBot";

// import { preventDefault } from "ol/events/event";

class OlMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLayerControl: false,
      layers: this.getLayersFromTree(),
      baseLayers: this.getBaseLayers(),
      clickEvent: null,
      vectersource: null,
      isChecked: false,
      addLayer: null,
      feature: false,
      vectorSource: null,
      showLayerControl: false,
      showLegendControl: false,
      showStyleControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: false,
    };
    this.mapComponent = null;
  }

  componentDidMount() {
    this.createOlMap();
    this.setState({
      vectorSource: new VectorSource(),
    });
    //console.log("enabled value", this.props.enabled);
  }

  addAssetStatus = () => {
    this.isChecked = !this.isChecked;
  };

  addAssetLayers = (evt) => {
    this.setState({
      addLayer: evt.target.value,
    });
  };
  handleMapRenderComplete = (evt) => {
    this.setState({ renderEvent: evt });
  };
  getBaseLayers() {
    const osm = new TileLayer({
      name: "OSM",
      visible: true,
      zIndex: 0,
      isBaseLayer: true,
      source: new OSM({
        attributions: ["<b><a href='https://kwa.kerala.gov.in'>KWA</a></b>"],
      }),
    });
    const Satellite = new TileLayer({
      name: "Satellite",
      isBaseLayer: true,
      visible: true,
      zIndex: 4,
      source: new XYZ({
        url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWptYWxhdG0iLCJhIjoiY2tjNzFvbWNjMHg2ajJ2bWdlNzlmamU0MSJ9.z_qLRhyXbaBnJV4edvis-g",
        crossOrigin: "anonymous",
      }),
    });
    // let baseLayerData = getLayerTree();
    let baseLayerData = LocalStorageHandler.getBaseLayers();
    let layersFromTree = [];
    let baseLyers = [];
    baseLyers.push(Satellite);
    baseLyers.push(osm);
    baseLayerData.length > 0 &&
      baseLayerData.map((layerItem) => {
        if (layerItem.layer_type == "base_layer") {
          let layer = generateLayer(
            layerItem.layer_type,
            layerItem.label,
            layerItem.layer_name,
            layerItem.type,
            layerItem.url,
            null,
            null,
            layerItem.visibility,
            null,
            layerItem.opacity,
            layerItem.index
          );
          baseLyers.push(layer);
        }
      });
    return baseLyers;
  }
  getLayersFromTree() {
    let layerTree = getLayerTree();
    let layersFromTree = [];
    let mapLayers = [];
    layerTree.length > 0 &&
      layerTree.map((treeItem) => {
        if (treeItem.hasOwnProperty("children")) {
          treeItem.children.forEach((layer) => {
            if (layer.layer_type == "layer") {
              layersFromTree.push(layer);
            }
          });
        }
      });

    layersFromTree.forEach((treeLayerItem) => {
      //console.log(treeLayerItem.value + " " + treeLayerItem.style);
      //console.log(treeLayerItem);
      let url = treeLayerItem.url;
      if (url != undefined) {
        let infoAttributes = {
          minimalInfoAttributes: treeLayerItem.minimalInfoAttributes,
          detailedInfoAttributes: treeLayerItem.detailedInfoAttributes,
        };
        let layer = generateLayer(
          treeLayerItem.layer_type,
          treeLayerItem.value,
          treeLayerItem.layer_name,
          treeLayerItem.type,
          treeLayerItem.url,
          treeLayerItem.style,
          treeLayerItem.cql_filter,
          treeLayerItem.enable,
          infoAttributes,
          1,
          treeLayerItem.zindex
        );
        mapLayers.push(layer);
      }
    });
    return mapLayers;
  }

  createOlMap = () => {
    const { layers, baseLayers } = this.state;
    let localBody = null;
    if (LocalStorageHandler.getLocalBody() !== null) {
      localBody = LocalStorageHandler.getLocalBody().local_body_name;
    }
    this.mapComponent = new Map({
      controls: defaultControls({
        collapsible: false,
      }).extend([
        new ScaleLine(),
        new MousePosition({
          undefinedHTML: "",
          coordinateFormat: createStringXY(4),
          projection: "EPSG:4326",
        }),
      ]),
      layers: [
        new LayerGroup({ layers: baseLayers }),
        new LayerGroup({ layers }),
      ],
      target: "map",
      view: new View({
        center: transform(
          mapDefaults.getDefaultCenter(),
          "EPSG:4326",
          "EPSG:3857"
        ),
        zoom: mapDefaults.getDefaultZoom(),
        minZoom: mapDefaults.getDefaultZoom(),
      }),
    });
    //console.log(localBody == "Kunnothparamba Panchayath");
    this.mapComponent.on("singleclick", (evt) =>
      this.isChecked
        ? localBody == "Kunnothparamba Panchayath"
          ? this.goToAddProperty(evt)
          : this.goToAddForm6Property(evt)
        : this.handleMapClick(evt)
    );

    // var vectorSource  = new VectorSource()
    // this.mapComponent.on("singleclick", (evt) =>
    //   this.isChecked ?
    //   (
    //   this.setToCurrentPosition(evt),
    //   vectorSource.clear())
    //   : null
    // );

    // this.mapComponent.on("singleclick", (evt) =>
    //   this.isChecked ? this.setToCurrentPosition(evt) : null
    // );

    this.mapComponent.on("pointermove", (evt) =>
      this.handleMapPointerMove(evt)
    );
    this.mapComponent.once("postcompose", (evt) => {
      this.handleMapRenderComplete(evt);
    });
    this.props.updateMapReference(this.mapComponent);
  };

  setToCurrentPosition = (event) => {
    // const { vectorSource } = this.state;
    // vectorSource.clear();
    //console.log(this.state.feature);
    var coords = toLonLat(event.coordinate);
    var lat = coords[1];
    var lon = coords[0];
    var y = Number(lat);
    var x = Number(lon);
    const { mapComponent } = this.props;
    //console.log(y + "___" + x);

    var iconFeature = new Feature({
      geometry: new Point(transform([x, y], "EPSG:4326", "EPSG:3857")),
    });
    var iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: pin,
        scale: 0.5,
      }),
    });
    iconFeature.setStyle(iconStyle);
    // if (this.state.feature == true) {
    //   vectorSource.clear();
    //   }
    var vectorSource = new VectorSource({
      features: [iconFeature],
    });
    var vectorLayer = new VectorLayer({
      source: vectorSource,
      wfsstyle: new Style({
        stroke: new Stroke({
          color: "rgba(255, 0, 0, 0.8)",
          width: 5,
        }),
      }),
    });
    mapComponent.addLayer(vectorLayer);
    // vectorSource.clear();
    mapComponent
      .getView()
      .setCenter(transform([x, y], "EPSG:4326", "EPSG:3857"));
    mapComponent.getView().setZoom(10);
    // this.setState({
    //   ...this.state,
    //   feature: true,
    // });
  };

  handleMapPointerMove = (evt) => {
    if (evt.dragging) return;
    try {
      var pixel = this.mapComponent.getEventPixel(evt.originalEvent);
      var hit = this.mapComponent.forEachLayerAtPixel(pixel, (layer) => {
        if (!layer.getProperties().isBaseLayer) return true;
      });
      this.mapComponent.getTargetElement().style.cursor = hit ? "pointer" : "";
    } catch (e) {
      console.log("Excepion", e);
    }
  };

  handleMapClick = (evt) => {
    let drawInteraction = this.mapComponent
      .getInteractions()
      .getArray()
      .filter((interaction) => {
        if (interaction instanceof Draw) return interaction;
      });
    if (drawInteraction.length > 0) return;
    if (!evt.dragging) this.setState({ clickEvent: evt });
  };

  goToAddProperty = (evt) => {
    var coords = toLonLat(evt.coordinate);
    var lat = coords[1];
    var lon = coords[0];
    if (this.state.addLayer) {
      this.props.history.push(
        "/" + this.state.addLayer + "/add?lat=" + lat + "&lon=" + lon
      );
    }
  };

  goToAddForm6Property = (evt) => {
    var coords = toLonLat(evt.coordinate);
    var lat = coords[1];
    var lon = coords[0];

    if (this.state.addLayer) {
      this.props.history.push("/form6_demo/add?lat=" + lat + "&lon=" + lon);
    }
  };

  componentWillUnmount() {
    this.mapComponent.un("singleclick", this.handleMapClick);
    this.mapComponent.un("pointermove", this.handleMapPointerMove);
    this.mapComponent.setTarget(undefined);
  }

  handleShowLayerControl = () => {
    this.setState({
      showLegendControl: false,
      showBaseMapControl: false,
      showStyleControl: false,
      showOpacityControl: false,
      showLayerControl: !this.state.showLayerControl,
    });
  };
  handleShowStyleControl = () => {
    this.setState({
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showStyleControl: !this.state.showStyleControl,
    });
  };
  handleShowLegentControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showLegendControl: !this.state.showLegendControl,
    });
  };
  handleShowBaseMapControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showOpacityControl: false,
      showBaseMapControl: !this.state.showBaseMapControl,
    });
  };
  handleShowOpacityControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: !this.state.showOpacityControl,
    });
  };
  handleShowSettingsControl = () => {
    this.setState({
      showLayerControl: false,
      showLayerControl: false,
      showLegendControl: false,
      showBaseMapControl: false,
      showOpacityControl: false,
      showSettingsControl: !this.state.showSettingsControl,
    });
  };

  render() {
    const { enabled } = this.props;
    return (
      <React.Fragment>
        <div id="map" className="map">
          <DefaultExtend />
          <GetCount />
          <AddAsset />
          <SelectByPolygon />
          <GoToXY />
          <Buffer />
          {/* chatBot */}
          <Hello />
          {/* <CurrentLocation vectersource={this.state.vectersource}/> */}
          <ExportPdf
            renderEvent={this.state.renderEvent}
            mapLayers={this.state.layers}
          />
          <MeasureAreaLength
            handleShowMeasurePanelControl={
              this.props.handleShowMeasurePanelControl
            }
          />
          <BaseMap
            showBaseMapControl={this.props.showBaseMapControl}
            baseLayers={this.state.baseLayers}
            handleShowBaseMapControl={this.props.handleShowBaseMapControl}
          />
          <Settings
            showSettingsControl={this.props.showSettingsControl}
            handleShowSettingsControl={this.props.handleShowSettingsControl}
          />
          <LayerTree
            showLayerControl={this.props.showLayerControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowLayerControl}
          />
          <Opacity
            showOpacityControl={this.props.showOpacityControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowOpacityControl}
          />
          <StyleLoader
            showStyleControl={this.props.showStyleControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowStyleControl}
          />
          <LegendWidget
            showLegendControl={this.props.showLegendControl}
            mapLayers={this.state.layers}
            handleShowControl={this.props.handleShowLegentControl}
          />
        </div>
        <FeatureInfo
          clickEvent={this.state.clickEvent}
          mapLayers={this.state.layers}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    layers: state.mapReducer.mapLayers,
    enabled: state.mapReducer.widgetEnable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateMapReference: (mapComponent) =>
      dispatch(updateMapReference(mapComponent)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OlMap));
