import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLayers } from "./data/action";
import { setPageHeader } from "../../containers/page_layout/data/action";
import { snackbarActions } from "../snackbar/data/action";
import LayerListTable from "./LayerListTable";
import TablePagination from "../pagination";
import Button from "../../containers/button";
import LBDropDown from "../../containers/localbody_dropdown/localbody_dropdown";
import { Checkbox } from "react-bootstrap";

class LayerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: [],
      page: 1,
      limit: 10,
      localbody_id: null,
      recordCount: 0
    };
  }
  componentDidMount() {
    this.props.setPageHeader(
      "Layer",
      "List",
      <Button
        text="Add Layer"
        className="pull-right"
        onClick={() => this.props.history.push("/layer/add/")}
        bsStyle="primary"
        page="/layer"
        type="add_permission"
      />
    );
    this.props.getLayers(
      this.state.page,
      this.state.limit,
      this.state.localbody_id
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.layers != this.props.layers) {
      this.setState({
        layers: this.props.layers,
        recordCount: this.props.recordCount
      });
    }
    if (prevState.page != this.state.page) {
      this.props.getLayers(
        this.state.page,
        this.state.limit,
        this.state.localbody_id
      );
    }
    if (prevState.localbody_id != this.state.localbody_id) {
      this.props.getLayers(
        this.state.page,
        this.state.limit,
        this.state.localbody_id
      );
    }
    if (prevProps.deleteSuccess != this.props.deleteSuccess) {
      this.props.showSnackbar("Deleted Successfully..!");
      this.props.getLayers(
        this.state.page,
        this.state.limit,
        this.state.localbody_id
      );
    }
  }
  onLocalBodyChange = id => {
    let localbody_id = id;
    if (id === "") {
      localbody_id = null;
    }
    if (!isNaN(localbody_id))
      this.setState({
        localbody_id,
        page: 1
      });
  };
  onPageChange = page => {
    if (!isNaN(page))
      this.setState({
        page: page
      });
  };
  render() {
    return (
      <React.Fragment>
        
        <LBDropDown onLocalBodyChange={this.onLocalBodyChange} />
        <LayerListTable layers={this.state.layers} />

        <TablePagination
          recordCount={this.state.recordCount}
          page={this.state.page}
          limit={this.state.limit}
          onClick={e => this.onPageChange(parseInt(e.target.text))}
          onNext={() => this.onPageChange(this.state.page + 1)}
          onPrev={() => this.onPageChange(this.state.page - 1)}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.layer.fetching,
    layers: state.layer.layers,
    recordCount: state.layer.recordCount,
    deleteSuccess: state.layer.deleteSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLayers: (page, limit, localbody_id) =>
      dispatch(getLayers(page, limit, localbody_id)),
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    showSnackbar: snackbarMessage =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LayerList)
);
