import {
  GET_LAYERS,
  GET_COLUMNS,
  FETCH_LAYER_COLUMS,
  TOGGLE_REPORT_MODEL,
  FETCHING_DATA,
  FETCH_DATA_FAILED,
  FETCH_DATA_SUCCESS,
  FETCHING_VALUE,
  FETCH_VALUE_FAILED,
  FETCH_VALUE_SUCCESS,
  ADVANCESEARCH_VALUE_FETCHING,
  ADVANCESEARCH_VALUE_FETCH_SUCCESS,
  ADVANCESEARCH_VALUE_FETCH_FAILED,
  REPORT_VALUE_FETCHING,
  REPORT_VALUE_FETCH_SUCCESS,
  REPORT_VALUE_FETCH_FAILED,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_FAILED,
  GET_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  FETCHING_SEARCHED_LAYER_SUCCESS,
} from "./constants";
import localStorageHandler from "../../../../../util/storage/";
import mapSearchService from "../../../../../service/MapSearchService";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import { loadingActions } from "../../../../../components/loader/data/action";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import axios from "axios";
import { or as orFilter, equalTo as equalToFilter } from "ol/format/filter";
import "ol/ol.css";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { GeoJSON, WFS } from "ol/format";
import OSM from "ol/source/OSM";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";

const GetSortOrder = (prop) => {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

export const getLayers = () => {
  return (dispatch) => {
    let layerTree = localStorageHandler.getLayerCategory();
    let layers = [];
    //console.log(layerTree);
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.map((layer) => {
          layers.push(layer);
          //console.log(layers);
        });
      }
    });
    layers.sort(GetSortOrder("label"));
    dispatch(setLayers(layers));
  };
  function setLayers(layers) {
    return { type: GET_LAYERS, layers };
  }
};

export const getColumns = () => {
  return (dispatch) => {
    let layerTree = localStorageHandler.getLayerCategory();
    let attributes = [];
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.map((layer) => {
          if (layer.hasOwnProperty("detailedInfoAttributes")) {
            // layer.detailedInfoAttributes.map(column => {
            attributes.push(layer);
            //console.log(layer);
          }
          // )};
        });
      }
    });
    dispatch(setColumns(attributes));
  };
  function setColumns(attributes) {
    return { type: GET_COLUMNS, attributes };
  }
};

export const fetchcolumsByLayer = (layer) => {
  return async (dispatch) => {
    dispatch(loadingActions.isloading());
    await axios
      .get(layer.url.concat("wfs"), {
        params: {
          version: "1.3.0",
          request: "describeFeatureType",
          outputFormat: "application/json",
          service: "WFS",
          typeName: layer.layer_name,
        },
      })
      .then((response) => {
        dispatch(loadingActions.loadingComplete());
        dispatch(success(response));
      });
  };
  function success(response) {
    return { type: FETCH_LAYER_COLUMS, response };
  }
};

export const togleReportModel = () => {
  return (dispatch) => {
    dispatch(toggleReportModel());
  };
  function toggleReportModel() {
    return { type: TOGGLE_REPORT_MODEL };
  }
};

export const fetchDataByAttribute = (searchBy, query, table) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService
      .fetchDataByAttribute(searchBy, query, table)
      .then((response) => {
        //console.log("RESPONSE FETCH DATA", response.data);
        //console.log(response.data.data);
        if (response.status === 200) {
          dispatch(success(response));
        } else {
          dispatch(failure(response));
          dispatch(snackbarActions.showSnackbar("Something went wrong"));
        }
        dispatch(loadingActions.loadingComplete());
      });
    function request() {
      return { type: FETCHING_DATA };
    }
    function success(response) {
      return { type: FETCH_DATA_SUCCESS, response };
    }
    function failure(error) {
      return { type: FETCH_DATA_FAILED, error };
    }
  };
};

export const fetchValueByAttribute = (
  model,
  attribute,
  data_type,
  category_field,
  category_id,
  app_label
) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService
      .fetchValueByAttribute(
        model,
        attribute,
        data_type,
        category_field,
        category_id,
        app_label
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch(success(response));
          dispatch(snackbarActions.showSnackbar("Please select the operation"));
        } else {
          dispatch(failure(response));
          dispatch(snackbarActions.showSnackbar("Something went wrong"));
        }
        dispatch(loadingActions.loadingComplete());
      });
    function request() {
      return { type: FETCHING_VALUE };
    }
    function success(response) {
      return { type: FETCH_VALUE_SUCCESS, response };
    }
    function failure(error) {
      return { type: FETCH_VALUE_FAILED, error };
    }
  };
};

export const fetchDataByFilter = (filterValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.fetchDataByFilter(filterValues).then((response) => {
      if (response.status === 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar("Something went wrong"));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: ADVANCESEARCH_VALUE_FETCHING };
    }
    function success(response) {
      return { type: ADVANCESEARCH_VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: ADVANCESEARCH_VALUE_FETCH_FAILED, error };
    }
  };
};

export const requestSearchedLayer = (
  layerToSearch,
  searchValue
  // parameters
) => {
  return async (dispatch) => {
    dispatch(loadingActions.isloading());
    if (layerToSearch.url)
      await axios
        .get(layerToSearch.url.concat("wfs"), {
          params: {
            version: "1.3.0",
            outputFormat: "application/json",
            request: "GetFeature",
            service: "WFS",
            srsName: "EPSG:3857",
            typeName: layerToSearch.layer_name,
            featureID: searchValue,
            // propertyName: parameters,
          },
        })
        .then((response) => {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        });
  };
  function success(response) {
    return { type: FETCHING_SEARCHED_LAYER_SUCCESS, response };
  }
};

export const fetchDataInReport = (filterValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.fetchDataInReport(filterValues).then((response) => {
      if (response.status === 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar("Something went wrong"));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: REPORT_VALUE_FETCHING };
    }
    function success(response) {
      return { type: REPORT_VALUE_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: REPORT_VALUE_FETCH_FAILED, error };
    }
  };
};

export const generateReport = (reportValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.generateReport(reportValues).then((response) => {
      if (response.status === 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar("Something went wrong"));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: GENERATE_REPORT };
    }
    function success(response) {
      return { type: GENERATE_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: GENERATE_REPORT_FAILED, error };
    }
  };
};

export const getReportLink = (reportValues) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.getReportLink(reportValues).then((response) => {
      if (response.status === 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar("Something went wrong"));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: GET_REPORT };
    }
    function success(response) {
      return { type: GET_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_REPORT_FAILED, error };
    }
  };
};
