import APIRequest from "../util/api/APIRequest";

const getUpdateReport = (data) => {
  console.log("data: ", data);
  return APIRequest({
    url: "map/get_updation_report/",
    method: "POST",
    data: data,
  });
};

const UpdateReportService = {
  getUpdateReport,
};
export default UpdateReportService;
