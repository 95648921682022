import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Button from "../../../containers/button";
import { deleteUser } from "./data/action";
import { Table } from "react-bootstrap";
import ConfirmBox from "../../confirmation_box/";

class UserListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onDeleteClick = user_access_id => {
    this.setState({ showDeleteConfirmation: true, deleteId: user_access_id });
  };
  confirmDelete = () => {
    if (this.state.deleteId > 0) {
      this.props.deleteUser(this.state.deleteId);
      this.handleClose();
    }
  };
  handleClose = () => {
    this.setState({ showDeleteConfirmation: false });
  };
  render() {
    return (
      <React.Fragment>
        <ConfirmBox
          show={this.state.showDeleteConfirmation}
          action={this.confirmDelete}
          onHide={this.handleClose}
        />
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Username</th>
              <th>Actions</th>
            </tr>
          </thead>
          {this.props.userList.data && (
            <tbody>
              {this.props.userList.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.userDetails.name}</td>
                    <td>{item.userDetails.email}</td>
                    <td>{item.userDetails.phone_no}</td>
                    <td>{item.user_name}</td>
                    <td>
                      <NavLink to={`/user/edit/${item.user_access_id}`}>
                        <Button
                          text="Edit"
                          bsStyle="success"
                          bsSize="xsmall"
                          page="/user"
                          type="update_permission"
                        />{" "}
                      </NavLink>
                      <Button
                        text="Delete"
                        onClick={() => this.onDeleteClick(item.user_access_id)}
                        bsStyle="danger"
                        bsSize="xsmall"
                        page="/user"
                        type="delete_permission"
                      />{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    deleteUser: user_access_id => dispatch(deleteUser(user_access_id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListTable);
