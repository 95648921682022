import {
  GET_LAYER_DROPDOWN,
  GET_LAYER_CATEGORY_DROPDOWN,
  GET_LOCAL_BODY_DROPDOWN,
  GET_ROLE_DROPDOWN,
  GET_MAIN_MENU_DROPDOWN
} from "./data/constants";
import dropdownTypes from "./dropdownTypes";

export const getActionByComboType = type => {
  switch (type) {
    case dropdownTypes.LAYER:
      return {
        action: GET_LAYER_DROPDOWN,
        url: "layer/combo"
      };
    case dropdownTypes.LAYER_CATEGORY:
      return {
        action: GET_LAYER_CATEGORY_DROPDOWN,
        url: "layercategory/combo"
      };
    case dropdownTypes.LOCAL_BODY:
      return {
        action: GET_LOCAL_BODY_DROPDOWN,
        url: "localbody/combo"
      };
    case dropdownTypes.ROLE:
      return {
        action: GET_ROLE_DROPDOWN,
        url: "role/combo"
      };
    case dropdownTypes.MAIN_MENU:
      return {
        action: GET_MAIN_MENU_DROPDOWN,
        url: "menu/combo"
      };
    default:
      return;
  }
};
