import {
  GET_UPDATE_REPORT_SUCCESS,
  GET_UPDATE_REPORT_FAILED,
} from "./constants";
import { loadingActions } from "../../loader/data/action";
import { snackbarActions } from "../../snackbar/data/action";
import UpdateReportService from "../../../service/UpdateReportService";

export const getUpdateReport = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    UpdateReportService.getUpdateReport(data).then((response) => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });

    function success(response) {
      return { type: GET_UPDATE_REPORT_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_UPDATE_REPORT_FAILED, error };
    }
  };
};
