import {
  GET_LAYER_CATEGORIES_SUCCESS,
  GET_LAYER_CATEGORIES_FAILED,
  FETCH_LAYER_CATEGORY_SUCCESS,
  FETCH_LAYER_CATEGORY_FAILED,
  ADD_LAYER_CATEGORY_SUCCESS,
  ADD_LAYER_CATEGORY_FAILED,
  DELETE_LAYER_CATEGORY_SUCCESS,
  DELETE_LAYER_CATEGORY_FAILED
} from "./constants";
const initialState = {
  layerCategories: [],
  recordCount: 0,
  saveSuccess: false,
  deleteSuccess: false,
  layerCategory: {}
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LAYER_CATEGORIES_SUCCESS:
      return {
        ...initialState,
        layerCategories: action.response.data,
        recordCount: action.response.data.recordCount
      };
    case GET_LAYER_CATEGORIES_FAILED:
      return {
        ...initialState
      };

    case ADD_LAYER_CATEGORY_SUCCESS:
      return {
        ...initialState,
        saveSuccess: true
      };
    case ADD_LAYER_CATEGORY_FAILED:
      return {
        ...initialState,
        saveSuccess: false
      };

    case FETCH_LAYER_CATEGORY_SUCCESS:
      return {
        ...initialState,
        layerCategory: action.response
      };
    case FETCH_LAYER_CATEGORY_FAILED:
      return {
        ...initialState
      };

    case DELETE_LAYER_CATEGORY_SUCCESS:
      return {
        ...initialState,
        deleteSuccess: true
      };
    case DELETE_LAYER_CATEGORY_FAILED:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
