import { FETCHINGDATA, FETCHDATA_FAILED, FETCHDATA_SUCCESS } from "./constants";
import mapSearchService from "../../../../../../service/MapSearchService";
import { loadingActions } from "../../../../../../components/loader/data/action";
import { snackbarActions } from "../../../../../../components/snackbar/data/action";

export const mapQueryPolygon = (layerlist, geometry) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    mapSearchService.mapQueryPolygon(layerlist, geometry).then((response) => {
      if (response.status === 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar("Something went wrong"));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: FETCHINGDATA };
    }
    function success(response) {
      return { type: FETCHDATA_SUCCESS, response };
    }
    function failure(error) {
      return { type: FETCHDATA_FAILED, error };
    }
  };
};
