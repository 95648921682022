import {
  GET_LAYERS_SUCCESS,
  GET_LAYERS_FAILED,
  FETCH_LAYER_SUCCESS,
  FETCH_LAYER_FAILED,
  ADD_LAYER_SUCCESS,
  ADD_LAYER_FAILED,
  DELETE_LAYER_SUCCESS,
  DELETE_LAYER_FAILED
} from "./constants";
import layerService from "../../../service/LayerService";
import layerCategoryService from "../../../service/LayerCategoryService";
import { loadingActions } from "../../loader/data/action";
import { snackbarActions } from "../../snackbar/data/action";

export const getLayers = (page, limit,localbody_id) => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    layerService.getLayers(page, limit,localbody_id).then(response => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });

    function success(response) {
      return { type: GET_LAYERS_SUCCESS, response };
    }
    function failure(error) {
      return { type: GET_LAYERS_FAILED, error };
    }
  };
};

export const addOrUpdateLayer = data => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    let method = data.layer_id > 0 ? "PUT" : "POST";

    layerService.addOrUpdateLayer(data, method).then(response => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });
  };

  function success(response) {
    return { type: ADD_LAYER_SUCCESS, response };
  }
  function failure(error) {
    return { type: ADD_LAYER_FAILED, error };
  }
};

export const fetchLayer = layer_id => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    layerService.fetchLayer(layer_id).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });
  };


  function success(response) {
    return { type: FETCH_LAYER_SUCCESS, response };
  }
  function failure(error) {
    return { type: FETCH_LAYER_FAILED, error };
  }
};

export const deleteLayer = layercategory_id => {
  return dispatch => {
    dispatch(loadingActions.isloading());
    layerService.deleteLayer(layercategory_id).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(failure(response));
        dispatch(
          snackbarActions.showSnackbar(
            response.response.data.message
              ? response.response.data.message
              : response.response.data.error
          )
        );
      }
      dispatch(loadingActions.loadingComplete());
    });
  };

  function success(response) {
    return { type: DELETE_LAYER_SUCCESS, response };
  }
  function failure(error) {
    return { type: DELETE_LAYER_FAILED, error };
  }
};
