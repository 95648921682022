import React, { Component } from "react";
import { Pagination } from "react-bootstrap";

class TablePagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageArray: [],
    };
  }

  render() {
    var totPages = Math.ceil(this.props.recordCount / this.props.limit);
    console.log("totPages: ", totPages);
    var currentPage = this.props.page;
    var pageArray = [];
    if (totPages > 1) {
      if (totPages <= 9) {
        var i = 1;
        while (i <= totPages) {
          pageArray.push(i);
          i++;
        }
      } else {
        if (currentPage <= 5)
          pageArray = [1, 2, 3, 4, 5, 6, 7, 8, "", totPages];
        else if (totPages - currentPage <= 4)
          pageArray = [
            1,
            "",
            totPages - 7,
            totPages - 6,
            totPages - 5,
            totPages - 4,
            totPages - 3,
            totPages - 2,
            totPages - 1,
            totPages,
          ];
        else
          pageArray = [
            1,
            "",
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            "",
            totPages,
          ];
      }
    }

    return (
      <Pagination style={{ justifyContent: "center", marginLeft: "35px" }}>
        {pageArray.map((item, ind) => {
          const toReturn = [];

          if (ind === 0) {
            toReturn.push(
              <Pagination.First
                key={"firstpage"}
                onClick={
                  this.props.page === 1
                    ? () => {}
                    : () => {
                        this.props.pageClicked(1);
                      }
                }
              />
            );

            toReturn.push(
              <Pagination.Prev
                key={"prevpage"}
                onClick={
                  this.props.page === 1
                    ? () => {}
                    : () => {
                        this.props.pageClicked(this.props.page - 1);
                      }
                }
              />
            );
          }

          if (item === "") toReturn.push(<Pagination.Ellipsis key={ind} />);
          else
            toReturn.push(
              <Pagination.Item
                key={ind}
                active={this.props.page === item ? true : false}
                onClick={
                  this.props.page === item
                    ? () => {}
                    : () => {
                        this.props.pageClicked(item);
                      }
                }
              >
                {item}
              </Pagination.Item>
            );

          if (ind === pageArray.length - 1) {
            toReturn.push(
              <Pagination.Next
                key={"nextpage"}
                onClick={
                  this.props.page === item
                    ? () => {}
                    : () => {
                        this.props.pageClicked(this.props.page + 1);
                      }
                }
              />
            );

            toReturn.push(
              <Pagination.Last
                key={"lastpage"}
                onClick={
                  this.props.page === item
                    ? () => {}
                    : () => {
                        this.props.pageClicked(item);
                      }
                }
              />
            );
          }

          return toReturn;
        })}
      </Pagination>
    );
  }
}

export default TablePagination;
