import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import closebtn from "../../map/widgets/iconimages/close_icon.svg";
import addfilter from "../../map/widgets/iconimages/add_filter.svg";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
} from "react-bootstrap";
import "./AdvancedFilter.css";
import { toggleAdvancedFilterWindow } from "../navigation/navbar_form/data/action";
import {
  updateSerachResultlayer,
  requestLayer,
} from "../navigation/navbar_form/data/action";
import { loadingActions } from "../../../../components/loader/data/action";
import { snackbarActions } from "../../../../components/snackbar/data/action";
import {
  getLayers,
  fetchcolumsByLayer,
  getColumns,
  requestSearchedLayer,
} from "./data/action";
import { fetchDataByFilter } from "../navigation/navbar_form/data/action";
import FilterForm from "./components/FilterForm";
import { GeoJSON } from "ol/format.js";
import { Vector as VectorLayer } from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import styleFunction from "../../map/helper/vectorStyle";
import ResultModel from "./components/ResultModel";
import localStorageHandler from "../../../../util/storage/";
import getLayerTree from "../../map/helper/LayerTreeLoader";
import mapDefaults from "../../map/helper/mapDefaults";
var temp;
class AdvancedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReprort: false,
      reportStatus: false,
      filterFormCount: [],
      result: [],
      index: 0,
      layers: {},
      layerCategorys: localStorageHandler.getLayerCategory(),
      data: {
        layer: "",
        layerCategory: "",
      },
      layer: null,
      temp: [],
      layerToBeSearched: {},
      attributeValue: null,
      operationFieldValue: "",
      valueField: "",
    };
  }
  handleToUpdate = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["temp".concat(index)]: value },
    });
  };

  handleShowReport = () => {
    this.setState({
      showReprort: !this.state.showReprort,
    });
  };

  handleInputChange = (event) => {
    // console.log(event.target);
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      operationFieldValue: event.target.value,
    });
  };

  handleInputChangeOperation = (event) => {
    this.props.showSnackbar("Please enter the Value");
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      operationFieldValue: event.target.value,
    });
  };

  handleInputChangedropdown = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["value".concat(index)]: value },
      valueField: value,
    });
  };

  componentDidMount() {
    this.props.getLayers();
  }

  addFilterForm = () => {
    this.setState(
      {
        filterFormCount: [...this.state.filterFormCount, 1],
        data: {
          ...this.state.data,
          ["operation".concat(this.state.index)]: "=",
          ["temp".concat(this.state.index)]: [],
        },
        index: this.state.index + 1,
      },
      () => {
        if (this.state.index >= 2) {
          this.setState({
            data: {
              ...this.state.data,
              ["condition".concat([this.state.index - 1])]: "and",
            },
          });
        }
      }
    );
  };

  getLayerByName(layer_name) {
    this.setState({
      data: { ...this.state.data, ["layer"]: layer_name },
    });
    let layer = this.props.layers.filter(
      (layer) => layer.layer_name == layer_name
    );
    if (layer.length > 0) {
      this.props.fetchcolumsByLayer(layer[0]);
    }
  }

  handleInputChangeAttribute = (
    event,
    index,
    field,
    model_name,
    parent_model
  ) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
        ["attributeField".concat(index)]: field,
        ["model".concat(index)]: model_name,
        ["parentModel".concat(index)]: parent_model,
      },
    });
  };

  handleSubmit = (e) => {
    const formData = new FormData(e.target.form);
    // event.preventDefault();
    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
    }
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    var data = {
      layer: "",
    };
    var form = e.target.form;

    Object.keys(form.elements).map((key) => {
      if (form.elements[key].name) {
        data[form.elements[key].name] = form.elements[key].value;
      }
    });
    // console.log(form);
    // console.log(this.state.data);
    let parentModel = this.state.data.layer.advancefilterinfo.filter(
      (attribute) => {
        // console.log(attribute.parent_model);
        return attribute.parent_model;
      }
    );
    let filter = {
      parent_model: parentModel[0].parent_model,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      app_label: this.state.data.layer.app_label,
    };
    this.props.fetchDataByFilter(filter);
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.feature_data != this.props.feature_data &&
      this.props.searchType === "advanceSearch"
    ) {
      if (this.props.feature_data.features.length > 0) {
        this.addSearchResultToMap();
        this.setState(
          {
            result: this.props.feature_data.features,
            layer: this.state.data.layer,
            reportStatus: true,
          },
          () => {
            this.handleShowReport();
          }
        );
      } else {
        this.props.mapComponent.removeLayer(prevProps.resultLayer);
        this.props.showSnackbar("No Features Found");
      }
    }
  }

  addSearchResultToMap() {
    const { mapComponent, feature_data } = this.props;
    const layerToSearch = this.state.data.layer;
    let infoAttributes = {
      minimalInfoAttributes: layerToSearch.minimalInfoAttributes,
      detailedInfoAttributes: layerToSearch.detailedInfoAttributes,
    };
    let vectorSource = new VectorSource();
    let resultLayer = new VectorLayer({
      name: layerToSearch.value,
      infoAttributes: infoAttributes,
      visible: true,
      source: vectorSource,
      zIndex: 1000,
      style: (feature, resolution) => styleFunction(feature, resolution),
    });
    // console.log(feature_data);

    let features = new GeoJSON().readFeatures(feature_data);
    vectorSource.addFeatures(features);
    this.props.updateSerachResultlayer(resultLayer);
    mapComponent.addLayer(resultLayer);
    // console.log(vectorSource.getExtent());
    mapComponent.getView().fit(vectorSource.getExtent());
    if (mapComponent.getView().getZoom() > 20)
      mapComponent.getView().setZoom(20);
  }
  getFilter = (data) => {
    let filter = "";
    let obj = [];
    Object.keys(data).map((key) => {
      if (key.includes("column")) {
        if (data["condition".concat(key.substr(-1))]) {
          filter = filter
            .concat(" ")
            .concat(data["condition".concat(key.substr(-1))])
            .concat(" ");
        }
        filter = filter
          .concat(data[key])
          .concat(data["operation".concat(key.substr(-1))])
          .concat("'")
          .concat(data["value".concat(key.substr(-1))])
          .concat("'");
        obj.push({
          // attribute: data[key],
          attribute: data["attributeField".concat(key.substr(-1))],
          op: data["operation".concat(key.substr(-1))],
          value: data["value".concat(key.substr(-1))],
          model_name: data["model".concat(key.substr(-1))],
          parent_model: data["parentModel".concat(key.substr(-1))],
          join_op:
            data["condition".concat(key.substr(-1))] == undefined
              ? null
              : data["condition".concat(key.substr(-1))],
        });
      }
    });
    return obj;
  };

  clearFilter = () => {
    let { mapComponent, resultLayer } = this.props;
    mapComponent.removeLayer(resultLayer);
    this.setState({
      data: {
        layer: "",
        layerCategory: "",
      },
      reportStatus: false,
      filterFormCount: [],
      index: 0,
    });
  };

  handleInputChangeLayer = (event) => {
    this.props.layers.map((layer) => {
      if (layer.layer_id == event.target.value) {
        this.setState({
          data: { ...this.state.data, layer: layer },
        });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showReprort && (
          <ResultModel
            show={this.state.showReprort}
            handleShowReport={this.handleShowReport}
            result={this.state.result}
            layer={this.state.layer}
          />
        )}

        <Modal show={this.props.show} className="advancedFilterModal">
          <Panel bsStyle="primary" className="advancedFilterPanel">
            <Panel.Heading>
              <Panel.Title componentClass="h3">Advanced Filter</Panel.Title>
              <ButtonToolbar className="pull-right button">
                <img
                  src={closebtn}
                  className="panelheadbtn"
                  onClick={this.props.hide}
                />
              </ButtonToolbar>
            </Panel.Heading>
            <Panel.Body className="panelBody">
              <Form horizontal onSubmit={this.onSubmit}>
                <FormGroup controlId="formHorizontalEmail">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layerCategory"
                      value={this.state.data.layerCategory}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="">Select Layer Category</option>
                      {this.state.layerCategorys
                        .sort((a, b) => (a["label"] > b["label"] ? 1 : -1))
                        .map((layerCategory) => {
                          return (
                            <option
                              key={layerCategory.value}
                              value={layerCategory.layer_category_id}
                            >
                              {layerCategory.label}
                            </option>
                          );
                        })}
                    </FormControl>
                  </Col>
                </FormGroup>
                <FormGroup controlId="formHorizontalEmail">
                  <Col xs={7} sm={8} md={8} lg={8}>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layer"
                      value={this.state.data.layer.layer_id}
                      disabled={this.state.data.layerCategory == ""}
                      onChange={this.handleInputChangeLayer}
                      required
                    >
                      <option value="">Select layer</option>
                      {this.props.layers.map((layer) => {
                        // if (layer.adv_fltr_status) {
                        if (
                          layer.layer_category_id ==
                          this.state.data.layerCategory
                        ) {
                          return (
                            <option key={layer.value} value={layer.layer_id}>
                              {layer.label}
                            </option>
                          );
                        }
                        // }
                      })}
                    </FormControl>
                  </Col>
                  <Col xs={5} sm={4} md={4} lg={4}>
                    <Button
                      inline="true"
                      className="addfilterbtn"
                      style={{ padding: "3px" }}
                      onClick={this.addFilterForm}
                      disabled={
                        this.state.filterFormCount.length === 8 ||
                        this.state.data.layer === ""
                      }
                    >
                      <i className="glyphicon glyphicon-plus-sign" /> Add Filter
                    </Button>
                  </Col>
                </FormGroup>
                <hr />
                {this.state.filterFormCount.map((element, index) => {
                  if (index < 8)
                    return (
                      <FilterForm
                        data={this.state.data}
                        handleInputChange={this.handleInputChange}
                        handleInputChangedropdown={
                          this.handleInputChangedropdown
                        }
                        handleInputChangeAttribute={
                          this.handleInputChangeAttribute
                        }
                        handleInputChangeOperation={
                          this.handleInputChangeOperation
                        }
                        handleToUpdate={this.handleToUpdate}
                        index={index}
                        key={index}
                        temp={temp}
                      />
                    );
                })}
                <br />
                <Button
                  type="submit"
                  bsStyle="success"
                  className="pull-right"
                  disabled={
                    this.state.filterFormCount.length === 0 ||
                    this.state.operationFieldValue == "" ||
                    this.state.operationFieldValue == "=" ||
                    this.state.valueField == ""
                  }
                  onClick={this.handleSubmit}
                >
                  Apply
                </Button>
                <Button
                  bsStyle="danger"
                  className="pull-left"
                  disabled={this.state.filterFormCount.length === 0}
                  onClick={this.clearFilter}
                >
                  Clear Filter
                </Button>
                <Button
                  bsStyle="danger"
                  className="pull-left report"
                  disabled={!this.state.reportStatus}
                  onClick={this.handleShowReport}
                >
                  Show Report
                </Button>
              </Form>
            </Panel.Body>
          </Panel>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedfilterWindow,
    layers: state.advancedFilter.layers,
    mapComponent: state.mapReducer.OlMap,
    resultLayer: state.mapSearch.resultLayer,
    attributes: state.advancedFilter.attributes,
    feature_data: state.mapSearch.feature_data,
    searchType: state.mapSearch.searchType,
    fetching: state.advancedFilter.fetching,
    data: state.advancedFilter.data,
    responseData: state.advancedFilter.responseData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    hide: () => dispatch(toggleAdvancedFilterWindow()),
    getLayers: () => dispatch(getLayers()),
    fetchcolumsByLayer: (layer) => dispatch(fetchcolumsByLayer(layer)),
    updateSerachResultlayer: (searchLayer) =>
      dispatch(updateSerachResultlayer(searchLayer)),
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getColumns: () => dispatch(getColumns()),
    fetchDataByFilter: (filterValues) =>
      dispatch(fetchDataByFilter(filterValues)),
    requestSearchedLayer: (layerToSearch, featureRequest) =>
      dispatch(requestSearchedLayer(layerToSearch, featureRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilter);
