import React, { Component } from "react";
import { Button, PageHeader, Panel } from "react-bootstrap";
import { connect } from "react-redux";

class PageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <PageHeader style={{ margin: "0px 0 20px", color: "var(--blue)" }}>
          <strong>{this.props.title}</strong>{" "}
          <small>{this.props.subTitle}</small>
          {this.props.action}
        </PageHeader>
        <Panel>
          <Panel.Body style={{ height: "80vh", overflowY: "auto" }}>
            {this.props.children}
          </Panel.Body>
        </Panel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    title: state.pageHeader.title,
    subTitle: state.pageHeader.subTitle,
    action: state.pageHeader.action,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
