import React, { Component } from "react";
import localStorageHandler from "../storage/";
export default (ChildComponent) =>
  class PermissionHandler extends Component {
    isVisible() {
      const userMenu = localStorageHandler.getMenu();

      let visibleFlag = false;
      if (userMenu != null) {
        let currentItem = userMenu.filter(
          (menu) => menu.menu_url == this.props.page
        );
        //console.log(currentItem.length);
        if (currentItem.length > 0) {
          // //console.log(currentItem[0]);
          visibleFlag = currentItem[0].permission[0][this.props.type];
        }
      }
      return visibleFlag;
    }

    render() {
      const { page, type, ...other } = this.props;
      return this.isVisible() || !page ? <ChildComponent {...other} /> : null;
    }
  };
