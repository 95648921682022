import React, { Component } from "react";
import { NavItem, Image, OverlayTrigger, Tooltip } from "react-bootstrap";

class MenuItemWrapper extends Component {
  render() {
    return (
      <NavItem eventKey={2}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="menu">{this.props.tooltipText}</Tooltip>}
        >
          <button
            className="advanceFilter"
            onClick={this.handleshowBufferPanelControl}
          >
            <Image
              src={this.props.img}
              onClick={this.props.handleClick}
              className="navIcons iconStyle"
            />
          </button>
        </OverlayTrigger>
      </NavItem>
    );
  }
}

export default MenuItemWrapper;
