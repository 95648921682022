import localStorageHandler from "../../../../util/storage";

export const getLayerToSearch = () => {
  return localStorageHandler.getLocalBody().layer_to_search;
};

export const getDefaultZoom = () => {
  return localStorageHandler.getLocalBody().default_zoom;
};

export const getDefaultCenter = () => {
  return localStorageHandler
    .getLocalBody()
    .default_centre.split(",")
    .map(Number);
};

export const getInitialZoom = () => {
  return localStorageHandler.getLocalBody().initial_zoom;
};

export const getInitialCenter = () => {
  return localStorageHandler
    .getLocalBody()
    .initial_centre.split(",")
    .map(Number);
};

export const searchFields = () => {
  return localStorageHandler
    .getLocalBody()
    .searchAttributes.map((item, index) => {
      return item.display_name;
    });
};

export const getsearchAttributes = searchBy => {
  let searchAttributes = localStorageHandler.getLocalBody().searchAttributes;
  let searchItem = searchAttributes.filter(item => {
    return item.display_name == searchBy;
  });
  return searchItem[0].column_name;
};


const mapDefaults = {
  getLayerToSearch,
  getDefaultZoom,
  getDefaultCenter,
  // getInitialCenter,
  // getInitialZoom,
  searchFields,
  getsearchAttributes
};
export default mapDefaults;
