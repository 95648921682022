import React, { Component } from "react";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
import "./ResultModel.css";
import getAttributeName from "../../../../../util/attributes/";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import closebtn from "../../../map/widgets/iconimages/close_icon.svg";

class ResultModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelName: "Excel",
    };
  }
  componentDidMount() {
    this.setState({
      excelName: this.props.layer.label,
    });
  }
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterProperties(properties) {
    let filterdedProps = {};
    let detailedInfoAttributes = [];
    if (properties != null) {
      if (this.props.layer != null) {
        detailedInfoAttributes = this.props.layer.detailedInfoAttributes;
      }
      Object.keys(properties).filter((key) => {
        detailedInfoAttributes.forEach((atribute) => {
          if (key === atribute.field) {
            filterdedProps[key] = properties[key];
          }
        });
      });
    }
    return filterdedProps;
  }

  handleResponseHeader() {
    let filterdedProps = {};
    let properties = {};
    this.props.result.map((res, i) => {
      properties = res.properties;
    });
    console.log(properties);
    filterdedProps = this.filterProperties(properties);
    if (filterdedProps != null) {
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(filterdedProps).map((key) => {
            if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponse() {
    let properties = {};
    let filterdedProps = {};
    return this.props.result.map((res, i) => {
      properties = res.properties;
      filterdedProps = this.filterProperties(properties);
      if (filterdedProps != null) {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            {Object.keys(filterdedProps).map((key) => {
              if (!key.includes("photo") && !key.includes("p_d_f_")) {
                return <td key={key}>{filterdedProps[key]}</td>;
              }
            })}
          </tr>
        );
      }
    });
  }

  render() {
    const rowItems = this.handleResponse();
    const rowHead = this.handleResponseHeader();
    return (
      <Modal
        className="pdfModelResult"
        show={this.props.show}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="pdfModelResultHeader">
          <Modal.Title id="contained-modal-title-lg">
            SEARCH REPORT
            <ButtonToolbar className="pull-right button">
              {/* <Button bsStyle="primary" onClick={this.props.handleShowReport}>
                <i className="fa fa-times" />
              </Button> */}
              <img
                src={closebtn}
                className="pdfheadbtn"
                onClick={this.props.handleShowReport}
              />
            </ButtonToolbar>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pdfModelResultBody">
          <div className="reportContainerModel">
            <Table
              striped
              bordered
              condensed
              hover
              className="detailedInfoContent"
              id="table-to-xls"
            >
              <thead>{rowHead}</thead>
              <tbody>{rowItems}</tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className="pdfModelResultFooter">
          <Row>
            <Col xs={12} sm={6} md={4} lg={4}>
              <h5 style={{ padding: "0px", marginTop: "18px" }}>
                Searched Count:
                {this.props.result.length ? this.props.result.length : null}
                <Badge className="countBadge" />
              </h5>
            </Col>
            <Col xs={12} sm={6} md={8} lg={8}>
              <label>Excel Name</label>
              <input
                type="text"
                placeholder="Label"
                value={this.state.excelName}
                name="excelName"
                onChange={this.handleInputChange}
                required
                className="excelName"
              />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-success"
                table="table-to-xls"
                filename={this.state.excelName}
                sheet="tablexls"
                buttonText="Download as XLS"
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ResultModel;
