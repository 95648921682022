import React, { Component } from "react";
import "./ExportPdf.css";
import ExportPdfPanel from "./ExportPdfPanel";
import { connect } from "react-redux";
import exportpdf from "../iconimages/print_map.svg";
import { updateWidgetEnable } from "../../map_component/data/actions";
import Tools from "../../../../../containers/tools/Tools";
class ExportPdf extends Component {
  handleShowExportPanelControl = () => {
    this.props.updateWidgetEnable("Print Map", this.props.showWidget);
  };
  render() {
    return (
      <React.Fragment>
        <Tools
          tooltip="Print Map"
          styles="extendButtonExport"
          functions={this.handleShowExportPanelControl}
          icon={exportpdf}
          visibleFlag={true}
        />
        {this.props.showWidget === "Print Map" && (
          <ExportPdfPanel
            mapLayers={this.props.mapLayers}
            handleShowExportPanelControl={this.handleShowExportPanelControl}
            renderEvent={this.props.renderEvent}
          />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget, preWidget) =>
      dispatch(updateWidgetEnable(showWidget, preWidget)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportPdf);
