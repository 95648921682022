import {
  ACCESSIBLE_LAYER_REQUEST,
  ACCESSIBLE_LAYER_FAILURE,
  ACCESSIBLE_LAYER_SUCCESS,
} from "./constants";
const initialState = {
  fetching: false,
  layers_list: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACCESSIBLE_LAYER_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ACCESSIBLE_LAYER_SUCCESS:
      return {
        ...state,
        fetching: false,
        layers_list: action.response.data ? action.response.data.layers : [],
      };
    case ACCESSIBLE_LAYER_FAILURE:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
}
