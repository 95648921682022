import React, { Component } from "react";
import axios from "axios";
import MinimalInfo from "./MinimalInfo";
import DetailedInfo from "./DetailedInfo";
import VectorLayer from "ol/layer/Vector.js";
import VectorSource from "ol/source/Vector.js";
import GeoJSON from "ol/format/GeoJSON.js";
import { connect } from "react-redux";
import { loadingActions } from "../../../../../components/loader/data/action";
import styleFunction from "../../helper/vectorStyle";
import LayerInfoService from "../../../../../service/LayerInfoService";
import getLayerTree from "../../../map/helper/LayerTreeLoader";

class FeatureInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDetailedInfoVisible: false,
      detailedInfoContent: null,
      isMinimalInfoVisible: false,
      minimalInfoContent: false,
      clickEvent: null,
      highlightOverlay: null,
      clickedLayer: null,
      detailedInfoContents: null,
      detailedInfoContentsForeignKey: null,
      minimalInfoContentLayerCounts: null,
      minimalInfoContents: null,
      // status: [],
      count: 0,
      total_count: 0,
      selectedLayer: null,
    };
  }
  componentDidMount() {
    this.setState({
      highlightOverlay: new VectorLayer({
        style: (feature, resolution) => styleFunction(feature, resolution),
        source: new VectorSource(),
        zIndex: 1000,
      }),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clickEvent !== this.props.clickEvent) {
      this.handleMapClick(this.props.clickEvent);
    }
  }
  onCountIncrement = () => {
    this.setState({
      count: this.state.count + 1,
    });
    if (this.state.isDetailedInfoVisible)
      document.getElementById("featureInfoPanelBody").scrollTop = 0;
  };
  onCountDecrement = () => {
    this.setState({
      count: this.state.count - 1,
    });
    if (this.state.isDetailedInfoVisible)
      document.getElementById("featureInfoPanelBody").scrollTop = 0;
  };

  handleMapClick = (evt) => {
    // //console.log(evt.coordinate)
    // //console.log(evt.pixel)
    // let coords = ol.proj.transform([evt.pixel], 'EPSG:3857', 'EPSG:4326')
    // //console.log(coords)
    // console.log("this.props.mapLayers", this.props.mapLayers);

    const { mapComponent } = this.props;
    if (evt.dragging) {
      return;
    }
    this.setState({
      count: 0,
    });
    try {
      let pixel = mapComponent.getEventPixel(evt.originalEvent);
      mapComponent.forEachLayerAtPixel(pixel, (layer) => {
        if (layer.type === "VECTOR") return;

        let viewResolution = /** @type {number} */ (
          mapComponent.getView().getResolution()
        );
        if (layer.getProperties().layerType !== "base_layer") {
          if (!layer.getProperties().isBaseLayer) {
            let url = layer
              .getSource()
              .getGetFeatureInfoUrl(
                evt.coordinate,
                viewResolution,
                "EPSG:3857",
                {
                  INFO_FORMAT: "application/json",
                  FEATURE_COUNT: 5,
                }
              );
            if (url) {
              this.requestFetureInfo(url, evt, layer);
            }
          }
        }
      });
      mapComponent.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        if (layer === null) return;
        if (layer.getProperties().name === undefined) return;
        let properties = feature;
        //console.log("feature: ", feature);
        this.handleResponse(properties, evt, layer, "vector");
      });
    } catch (e) {
      //console.log("Exception", e);
    }
  };

  async requestFetureInfo(url, evt, layer) {
    this.props.isLoading();
    await axios({
      url: url,
      method: "GET",
    }).then((response) => {
      //console.log("RESPONSE MINI",response)
      if (response.data.features.length > 0) {
        let properties_tot = {};
        response.data.features.map((feature, i) => {
          properties_tot[i] = feature.properties;
          this.setState({
            total_count: i,
          });
        });
        let properties = response.data.features[0].properties;
        this.handleResponse(response.data.features, evt, layer, "tile");
        this.highlightSelectedLayer(response);
      }
    });
  }

  handleResponse = async (features, evt, layer, type) => {
    let layerTree = getLayerTree();
    let is_boundary = false;
    let spatialQueryData = [];
    let minimalInfoAttributes =
      layer.getProperties().infoAttributes.minimalInfoAttributes;
    let detailedInfoAttributes =
      layer.getProperties().infoAttributes.detailedInfoAttributes;
    let minimalInfoContents = {};
    let minimalInfoContentLayerCounts = [];
    let detailedInfoContents = {};
    let detailedInfoContentsForeignKey = {};
    //Checking whether selected layer is boundary or not
    layerTree.map((item) => {
      if (item.hasOwnProperty("children") && item.value === "Boundary") {
        item.children.map((l) => {
          if (l.value === layer.getProperties().name) {
            is_boundary = true;
            return;
          }
        });
      }
    });
    //console.log("is_boundary: ", is_boundary);
    if (type == "tile") {
      features.map((feature, i) => {
        //console.log("feature: ", feature);
        let minimalInfoContent = {};
        let detailedInfoContent = {};
        let res = [];
        let detailedInfoContentForeignKey = {};
        //itrating data get from layer
        Object.keys(feature.properties).filter((key) => {
          minimalInfoAttributes.forEach((atribute) => {
            if (key === atribute.field) {
              //adding value in to attribute list
              atribute["value"] = feature.properties[key];
              //console.log(feature.properties);
              //add that in to minimal info content
              if (atribute.data_type !== "ForeignKey") {
                minimalInfoContent[key] = Object.assign({}, atribute);
              }
            }
          });
          detailedInfoAttributes.forEach((atribute) => {
            if (key === atribute.field) {
              atribute["value"] = feature.properties[key];
              //add that in to minimal info content
              if (atribute.data_type !== "ForeignKey") {
                detailedInfoContent[key] = Object.assign({}, atribute);
              } else {
                detailedInfoContentForeignKey[key] = Object.assign(
                  {},
                  atribute
                );
              }
            }
          });
        });
        if (is_boundary) {
          this.props.mapLayers.forEach((element) => {
            if (element.getProperties().visible) {
              var obj = {};
              // var layer = element.values_.name;
              layerTree.map((item) => {
                if (
                  item.hasOwnProperty("children") &&
                  item.value !== "Boundary"
                ) {
                  item.children.map((layer) => {
                    if (layer.value == element.values_.name) {
                      obj["model_name"] = layer.model_name;
                      obj["field"] = layer.category_field;
                      obj["field_value"] = layer.category_id;
                      obj["layer_name"] = element.values_.name;
                      obj["layer_label"] = item.label + " - " + layer.label;
                      obj["service_name"] =
                        element.values_.source.params_.LAYERS;
                      res.push(obj);
                      return;
                    }
                  });
                }
              });
            }
          });
        }
        if (res.length > 0)
          minimalInfoContentLayerCounts[i] = {
            layer: res,
            geometry: feature.geometry,
          };

        minimalInfoContents[i] = minimalInfoContent;
        detailedInfoContents[i] = detailedInfoContent;
        detailedInfoContentsForeignKey[i] = {
          foreignKeys: detailedInfoContentForeignKey,
          id: feature.id,
        };
      });
    } else {
      let minimalInfoContent = {};
      let detailedInfoContent = {};
      let detailedInfoContentForeignKey = {};
      let i = 0;
      var vectorFeatures = features.getProperties();
      Object.keys(vectorFeatures).filter((key) => {
        minimalInfoAttributes.forEach((atribute) => {
          if (key === atribute.field) {
            //adding value in to attribute list
            atribute["value"] = vectorFeatures[key];
            //add that in to minimal info content
            if (atribute.data_type !== "ForeignKey")
              minimalInfoContent[key] = Object.assign({}, atribute);
          }
        });
        detailedInfoAttributes.forEach((atribute) => {
          if (key === atribute.field) {
            // console.log("atribute: ", atribute);
            atribute["value"] = vectorFeatures[key];
            //add that in to minimal info content
            if (atribute.data_type !== "ForeignKey") {
              detailedInfoContent[key] = Object.assign({}, atribute);
            } else {
              detailedInfoContentForeignKey[key] = Object.assign({}, atribute);
            }
          }
        });
      });
      let FeatureId = "";
      if (features.id_ == undefined) {
        FeatureId = this.props.feature_table + "." + vectorFeatures["pk"];
        console.log("this.props.feature_table: ", this.props.feature_table);
      } else {
        FeatureId = features.id_;
      }
      minimalInfoContents[i] = minimalInfoContent;
      detailedInfoContents[i] = detailedInfoContent;
      detailedInfoContentsForeignKey[i] = {
        foreignKeys: detailedInfoContentForeignKey,
        id: FeatureId,
      };
    }
    var result = await LayerInfoService.foreignKeyInfo(
      detailedInfoContentsForeignKey
    );

    if (minimalInfoContentLayerCounts.length > 0) {
      spatialQueryData = await LayerInfoService.spatialQueryData(
        minimalInfoContentLayerCounts
      );
    }
    this.setState({
      detailedInfoContents: detailedInfoContents,
      detailedInfoContentsForeignKey: result.data,
      minimalInfoContents: minimalInfoContents,
      minimalInfoContentLayerCounts: spatialQueryData.data,
      isMinimalInfoVisible: true,
      clickEvent: evt,
      selectedLayer: layer,
    });
    //console.log('detailedInfoContents: ', detailedInfoContents);
    this.props.loadingComplete();
  };

  highlightSelectedLayer = (response) => {
    const { highlightOverlay } = this.state;
    const { mapComponent } = this.props;
    highlightOverlay.getSource().clear();
    highlightOverlay.setMap(mapComponent);
    highlightOverlay
      .getSource()
      .addFeatures(new GeoJSON().readFeatures(response.data));
  };

  onDetailedInfoClose = () => {
    this.setState(
      {
        isDetailedInfoVisible: false,
      },
      () => {
        if (
          !this.state.isMinimalInfoVisible &&
          !this.state.isDetailedInfoVisible
        )
          this.state.highlightOverlay.getSource().clear();
      }
    );
  };

  onMinimalInfoClose = (e, popupOverlay) => {
    this.setState(
      {
        isMinimalInfoVisible: false,
      },
      () => {
        if (
          !this.state.isMinimalInfoVisible &&
          !this.state.isDetailedInfoVisible
        )
          this.state.highlightOverlay.getSource().clear();
      }
    );
  };

  showDetailedInfo = () => {
    this.setState({
      isDetailedInfoVisible: true,
    });
  };
  render() {
    return (
      this.state.minimalInfoContent != null && (
        <React.Fragment>
          {this.state.isDetailedInfoVisible && (
            <DetailedInfo
              count={this.state.count}
              total_count={this.state.total_count}
              onCountIncrement={this.onCountIncrement}
              onCountDecrement={this.onCountDecrement}
              isDetailedInfoVisible={this.state.isDetailedInfoVisible}
              onDetailedInfoClose={this.onDetailedInfoClose}
              detailedInfoContents={this.state.detailedInfoContents}
              detailedInfoContentsForeignKey={
                this.state.detailedInfoContentsForeignKey
              }
              selectedLayer={this.state.selectedLayer}
            />
          )}
          <MinimalInfo
            count={this.state.count}
            // status={this.state.status}
            total_count={this.state.total_count}
            onCountIncrement={this.onCountIncrement}
            onCountDecrement={this.onCountDecrement}
            isMinimalInfoVisible={this.state.isMinimalInfoVisible}
            showDetailedInfo={this.showDetailedInfo}
            minimalInfoContents={this.state.minimalInfoContents}
            detailedInfoContents={this.state.detailedInfoContents}
            minimalInfoContentLayerCounts={
              this.state.minimalInfoContentLayerCounts
            }
            onMinimalInfoClose={this.onMinimalInfoClose}
            clickEvent={this.state.clickEvent}
            clickedLayer={this.state.highlightOverlay}
            mapComponent={this.props.mapComponent}
          />
        </React.Fragment>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    feature_table: state.mapSearch.feature_table,
    // response_table: state.advancedFilter.response_table,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureInfo);
