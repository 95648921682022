import {
  UPDATE_MAP_REFERENCE,
  SET_LAYERS_FROM_TREE,
  UPDATE_LEGEND_URLS,
  SET_WIDGET_ENABLE_DISABLE,
} from "./constants";

export const updateMapReference = (OlMap) => {
  return (dispatch) => {
    dispatch(updateMapReference(OlMap));
  };
  function updateMapReference(OlMap) {
    return { type: UPDATE_MAP_REFERENCE, OlMap };
  }
};

export const updateLegendUrls = (urls) => {
  return (dispatch) => {
    dispatch(updateLegendUrls(urls));
  };
  function updateLegendUrls(urls) {
    return { type: UPDATE_LEGEND_URLS, urls };
  }
};

export const updateWidgetEnable = (showWidget, preWidget) => {
  console.log("showWidget, preWidget: ", showWidget, preWidget);
  if (showWidget === preWidget)
    return (dispatch) => {
      dispatch(updateWidgetEnable(""));
    };
  else
    return (dispatch) => {
      dispatch(updateWidgetEnable(showWidget));
    };
  function updateWidgetEnable(showWidget) {
    return { type: SET_WIDGET_ENABLE_DISABLE, showWidget };
  }
};

/* export const getLayersFromTree = () => {
  return dispatch => {
    let layerTree = getLayerTree();
    let layersFromTree = [];
    let mapLayers = [];
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.forEach(layer => {
          layersFromTree.push(layer);
        });
      }
    });

    layersFromTree.forEach(treeLayerItem => {
      let url = treeLayerItem.url;
      if (url != undefined) {
        let infoAttributes = {
          minimalInfoAttributes: treeLayerItem.minimalInfoAttributes,
          detailedInfoAttributes: treeLayerItem.detailedInfoAttributes
        };
        let layer = generateLayer(
          treeLayerItem.value,
          treeLayerItem.layer_name,
          treeLayerItem.type,
          treeLayerItem.url,
          infoAttributes
        );
        mapLayers.push(layer);
      }
    });
    return dispatch(setLayersFromTree(mapLayers));
  };
  function setLayersFromTree(mapLayers) {
    return { type: SET_LAYERS_FROM_TREE, mapLayers };
  }
};
 */
