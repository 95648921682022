import APIRequest from "../util/api/APIRequest";

const login = (userDeatails) => {
  return APIRequest({
    url: "map/token/",
    method: "POST",
    data: userDeatails,
  });
};

const logout = () => {
  return APIRequest({
    url: "map/logout",
    method: "GET",
  });
};
const getTableauTrustToken = () => {
  return APIRequest({
    url: "map/gettableautrusttoken/",
    method: "GET",
  });
};
const AuthenticationService = {
  login,
  logout,
  getTableauTrustToken,
};
export default AuthenticationService;
