import {
  GET_DROPDOWN_DATA_FAILURE,
  GET_LAYER_DROPDOWN,
  GET_LAYER_CATEGORY_DROPDOWN,
  GET_ROLE_DROPDOWN,
  GET_MAIN_MENU_DROPDOWN,
  GET_LOCAL_BODY_DROPDOWN
} from "./constants";

const initialState = {
  layerCategories: [],
  layers: [],
  roles: [],
  mainMenus: [],
  localBodies: [],
  recordCount: 0
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LAYER_CATEGORY_DROPDOWN:
      return {
        ...state,
        layerCategories: action.response.data.data,
        recordCount: action.response.data.recordCount
      };
    case GET_LAYER_DROPDOWN:
      return {
        ...state,
        layers: action.response.data.data,
        recordCount: action.response.data.recordCount
      };
    case GET_MAIN_MENU_DROPDOWN:
      return {
        ...state,
        mainMenus: action.response.data.data,
        recordCount: action.response.data.recordCount
      };
    case GET_LOCAL_BODY_DROPDOWN:
      return {
        ...state,
        localBodies: action.response.data.data,
        recordCount: action.response.data.recordCount
      };
    case GET_ROLE_DROPDOWN:
      return {
        ...state,
        roles: action.response.data.data,
        recordCount: action.response.data.recordCount
      };
    case GET_DROPDOWN_DATA_FAILURE:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
