import {
  USER_FETCHING,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILED,
  USER_ADD_SUCCESS,
  USER_ADD_FAILED,
  USER_FETCH_BY_ID_SUCCESS,
  USER_FETCH_BY_ID_FAILED,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILED
} from "./constants";
const initialState = {
  fetching: false,
  userList: [],
  recordCount: 0,
  saveSuccess: false,
  deleteSuccess: false,
  user: {}
};
export default function(state = initialState, action) {
  switch (action.type) {
    case USER_FETCHING:
      return {
        ...initialState,
        fetching: true
      };
    case USER_FETCH_SUCCESS:
      return {
        ...initialState,
        fetching: false,
        userList: action.response.data,
        recordCount: action.response.data.recordCount
      };
    case USER_FETCH_FAILED:
      return {
        ...initialState
      };
    case USER_ADD_SUCCESS:
      return {
        ...initialState,
        saveSuccess: true
      };
    case USER_ADD_FAILED:
      return {
        ...initialState,
        saveSuccess: false
      };
    case USER_FETCH_BY_ID_SUCCESS:
      return {
        ...initialState,
        user: action.response
      };
    case USER_FETCH_BY_ID_FAILED:
      return {
        ...initialState
      };
    case USER_DELETE_SUCCESS:
      return {
        ...initialState,
        deleteSuccess: true
      };
    case USER_DELETE_FAILED:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
