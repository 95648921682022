import React, { Component } from "react";
import "./BaseMap.css";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import { FormGroup } from "react-bootstrap";
class BaseMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBaseMapControl: false,
      layers: [],
    };
  }
  componentDidUpdate(prevProps, prevState) {
    var data = [];
    if (prevProps.showBaseMapControl !== this.props.showBaseMapControl) {
      const { baseLayers } = this.props;
      for (let i = 0; i < baseLayers.length; i++) {
        let layerTblName = baseLayers[i].getProperties();
        let layerName = baseLayers[i].getProperties().name;
        let visibility = baseLayers[i].getVisible();
        let opacity = baseLayers[i].getProperties().opacity * 10;
        var element = {
          layerName: layerName,
          visibility: visibility,
          opacity: opacity,
        };
        data.push(element);
      }
      this.setState({
        showBaseMapControl: this.props.showBaseMapControl,
        layers: data,
      });
    }
  }
  handleChange = (e) => {
    var data = [];
    const { baseLayers } = this.props;
    for (let i = 0; i < baseLayers.length; i++) {
      let layer = baseLayers[i].getProperties().name;
      if (e.target.value === layer) {
        baseLayers[i].setVisible(!baseLayers[i].getVisible());
      }
      let visibility = baseLayers[i].getVisible();
      let opacity = baseLayers[i].getProperties().opacity * 10;

      var element = {
        layerName: layer,
        visibility: visibility,
        opacity: opacity,
      };
      data.push(element);
    }
    this.setState({
      layers: data,
    });
  };
  setOpacity = (e) => {
    var data = [];
    const { baseLayers } = this.props;
    let val = e.target.value / 10;
    for (let i = 0; i < baseLayers.length; i++) {
      let layer = baseLayers[i].getProperties().name;
      if (e.target.name == layer) {
        baseLayers[i].setOpacity(val);
      }
      let visibility = baseLayers[i].getVisible();
      let opacity = baseLayers[i].getProperties().opacity * 10;
      var element = {
        layerName: layer,
        visibility: visibility,
        opacity: opacity,
      };
      data.push(element);
    }
    this.setState({
      layers: data,
    });
  };
  render() {
    return (
      <OverlayPanel
        showControl={this.props.showBaseMapControl}
        handleShowControl={this.props.handleShowBaseMapControl}
        titleName="Base Map"
      >
        <form>
          <FormGroup>
            {this.state.layers.map((layer, index) => (
              <React.Fragment key={index}>
                <label className="containerBaseMap" key={index}>
                  {layer.layerName}
                  <input
                    type="checkbox"
                    checked={layer.visibility}
                    value={layer.layerName}
                    key={index}
                    data-letter={layer.layerName}
                    onChange={this.handleChange}
                  />
                  <span style={{ marginLeft: "-4px" }} className="checkmark" />
                </label>
                {layer.visibility && (
                  <div class="range">
                    <input
                      type="range"
                      className="range"
                      value={layer.opacity}
                      name={layer.layerName}
                      min="0"
                      max="10"
                      onChange={this.setOpacity}
                    ></input>
                  </div>
                )}
                <br />
              </React.Fragment>
            ))}
          </FormGroup>
        </form>
      </OverlayPanel>
    );
  }
}

export default BaseMap;
