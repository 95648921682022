import React, { Component } from "react";
import {
  Collapse,
  Row,
  Col,
  Grid,
  Panel,
  Button,
  ButtonToolbar
} from "react-bootstrap";
import "./OverlayPanel.css";
import closebtn from "../iconimages/close_icon.svg";

class OverlayPanel extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Collapse in={this.props.showControl} className="overlayPanel">
          <Grid>
            <Row className="show-grid">
              <Col>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title componentClass="h3">
                      {this.props.titleName}
                    </Panel.Title>

                    <ButtonToolbar className="pull-right button">
                      {this.props.layerCheck && (
                        <Button
                          className="uncheck_all"
                        
                          onClick={this.props.handleUnCheckLayer}
                        >
                          UNCHECK ALL
                        </Button>
                      )}
                      {/* <Button
                        className="cancel"
                        onClick={this.props.handleShowControl}
                      >
                        <i className="fa fa-times" />
                      </Button> */}
                       <img
                        src={closebtn}
                        className="panelheadbtn"
                        onClick={this.props.handleShowControl}
                      />
                    </ButtonToolbar>
                  </Panel.Heading>
                  <Panel.Body className="overlayPanelBody">
                    {this.props.children}
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

export default OverlayPanel;