import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { snackbarActions } from "../../snackbar/data/action";
import { setPageHeader } from "../../../containers/page_layout/data/action";
import { changePassword } from "./data/action";
import {
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        currentpsw: "",
        newpsw: "",
        confirmpsw: "",
      },
      newpswerror: "",
      confirmpswerror: "",
    };
  }
  componentDidMount() {
    this.props.setPageHeader("Change Password", "", "");
  }
  componentDidUpdate(prevProps) {
    if (prevProps.apiStatus !== this.props.apiStatus) {
      if (this.props.message == "Success") {
        this.props.showSnackbar("Change Password Successfully..!");
        setTimeout(() => {
          this.props.history.push("/map");
        }, 1);
      } else {
        this.props.showSnackbar(this.props.message);
      }
    }
  }
  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };
  validateForm = (event) => {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$/;
    const isValid = reg.test(this.state.data.newpsw);
    if (!reg.test(this.state.data.newpsw)) {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "Password should be strong",
        confirmpswerror: "",
      });
      return false;
    } else if (this.state.data.newpsw != this.state.data.confirmpsw) {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "",
        confirmpswerror: "Password miss match",
      });
      return false;
    } else {
      this.setState({
        data: { ...this.state.data },
        newpswerror: "",
        confirmpswerror: "",
      });
      return true;
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let args = {
        currentPassword: this.state.data.currentpsw,
        confirmPassword: this.state.data.confirmpsw,
      };
      this.props.changePassword(args);
    }
  };
  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="name">
            <ControlLabel>Current Password</ControlLabel>
            <FormControl
              type="password"
              placeholder="Current Password"
              value={this.state.data.currentpsw}
              name="currentpsw"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="address">
            <ControlLabel>New Password</ControlLabel>
            <FormControl
              type="password"
              placeholder="New Password"
              value={this.state.data.newpsw}
              name="newpsw"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
          <div>
            <p className="errorClass">{this.state.newpswerror}</p>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="email">
            <ControlLabel>Confirm Password</ControlLabel>
            <FormControl
              type="password"
              placeholder="Confirm Password"
              value={this.state.data.confirmpsw}
              name="confirmpsw"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
          <div>
            <p className="errorClass">{this.state.confirmpswerror}</p>
          </div>
        </Col>
        <Col xs={12} sm={12} md={21} lg={12}>
          <hr className="hr-primary" />
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup>
            <Button type="submit" bsStyle="success">
              Save
            </Button>{" "}
            <Button bsStyle="warning" onClick={this.handleCancel}>
              Cancel
            </Button>
          </FormGroup>
        </Col>
      </Form>
    );
  }
}
function mapStateToProps(state) {
  return {
    message: state.changePasswordReducer.message,
    apiStatus: state.changePasswordReducer.apiStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    changePassword: (data) => dispatch(changePassword(data)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePassword)
);
