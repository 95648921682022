import {
  FETCHING,
  FETCH_FAILED,
  FETCH_SUCCESS,
  VALUE_FETCHING,
  VALUE_FETCH_FAILED,
  VALUE_FETCH_SUCCESS,
  FETCHING_LAYER_SUCCESS,
  UPDATE_SEARCH_LAYER,
  TOGGLE_ADVANCED_FILTER_WINDOW,
} from "./constants";
const initialState = {
  fetching: false,
  data: null,
  feature_data: null,
  // feature_value: null,
  layerData: null,
  resultLayer: null,
  showAdvancedfilterWindow: false,
  feature_table: "",
  searchType: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response.data,
      };
    case VALUE_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case VALUE_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case VALUE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        feature_data: action.response.data.data,
        feature_table: action.response.data.table,
        searchType: action.searchType,
      };
    case FETCH_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case FETCHING_LAYER_SUCCESS:
      return {
        ...state,
        layerData: action.response.data,
      };
    case UPDATE_SEARCH_LAYER:
      return {
        ...state,
        resultLayer: action.resultLayer,
      };
    case TOGGLE_ADVANCED_FILTER_WINDOW:
      return {
        ...state,
        showAdvancedfilterWindow: !state.showAdvancedfilterWindow,
      };
    default:
      return state;
  }
}
