import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class Tools extends Component {
  render() {
    const { tooltip, styles, functions, icon, visibleFlag } = this.props;
    return (
      <React.Fragment>
        {visibleFlag && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={tooltip}>{tooltip}</Tooltip>}
          >
            <button className={styles} onClick={functions}>
              <img className="iconStyle" src={icon} />
            </button>
          </OverlayTrigger>
        )}
      </React.Fragment>
    );
  }
}
export default Tools;
