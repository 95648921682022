import {
  GET_LAYERS,
  GET_COLUMNS,
  FETCH_LAYER_COLUMS,
  TOGGLE_REPORT_MODEL,
  FETCHING_DATA,
  FETCH_DATA_FAILED,
  FETCH_DATA_SUCCESS,
  FETCHING_VALUE,
  FETCH_VALUE_FAILED,
  FETCH_VALUE_SUCCESS,
  ADVANCESEARCH_VALUE_FETCHING,
  ADVANCESEARCH_VALUE_FETCH_SUCCESS,
  ADVANCESEARCH_VALUE_FETCH_FAILED,
  REPORT_VALUE_FETCHING,
  REPORT_VALUE_FETCH_SUCCESS,
  REPORT_VALUE_FETCH_FAILED,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_FAILED,
  GET_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILED,
  FETCHING_SEARCHED_LAYER_SUCCESS,
} from "./constants";

const initialState = {
  layers: [],
  attributes: [],
  layerColumns: [],
  showReport: false,
  fetching: false,
  data: null,
  response_id: null,
  respose_table: null,
  responseData: null,
  tableData: null,
  recordCount: 0,
  report_result: null,
  reportLink: null,
  // feature_data:null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LAYERS:
      return {
        ...state,
        layers: action.layers,
      };
    case GET_COLUMNS:
      return {
        ...state,
        attributes: action.attributes,
      };
    case FETCH_LAYER_COLUMS:
      return {
        ...state,
        layerColumns: action.response.data,
      };
    case TOGGLE_REPORT_MODEL:
      return {
        showReport: !state.showReport,
      };
    case FETCHING_DATA:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response.data.data,
      };
    case FETCH_DATA_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case FETCHING_VALUE:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_VALUE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.response.data,
      };
    case FETCH_VALUE_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case ADVANCESEARCH_VALUE_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case ADVANCESEARCH_VALUE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        // api response for python - id's of features
        response_id: action.response.data.data,
        respose_table: action.response.data.table,
      };
    case ADVANCESEARCH_VALUE_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case REPORT_VALUE_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case REPORT_VALUE_FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        tableData: action.response.data ? action.response.data.data : {},
        recordCount: action.response.data ? action.response.data.count : {},
      };
    case REPORT_VALUE_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case GENERATE_REPORT:
      return {
        ...state,
        fetching: true,
      };
    case GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        report_result: action.response.data.message,
      };
    case GENERATE_REPORT_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case GET_REPORT:
      return {
        ...state,
        fetching: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        reportLink: action.response.data.url,
      };
    case GET_REPORT_FAILED:
      return {
        ...state,
        fetching: false,
      };
    case FETCHING_SEARCHED_LAYER_SUCCESS:
      return {
        ...state,
        responseData: action.response.data,
      };
    default:
      return state;
  }
}
