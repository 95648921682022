import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button
} from "react-bootstrap";
import { setPageHeader } from "../../../containers/page_layout/data/action";
import { snackbarActions } from "../../snackbar/data/action";
import { addOrUpdateUser, fetchUserByID } from "./data/action";
import { getDropdownData } from "../../../containers/dropdown/data/action";
import dropdownTypes from "../../../containers/dropdown/dropdownTypes";
import "./AddEditUser.css";

class AddEdituser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        role_id: "",
        name: "",
        address: "",
        email: "",
        phone_no: "",
        local_body_id: "",
        user_name: "",
        password: "",
        active: true
      },
        passworderror:"",
        update: false,
    };
  }
  componentDidMount() {
    this.props.getDropdownData(dropdownTypes.ROLE);
    this.props.getDropdownData(dropdownTypes.LOCAL_BODY);

    if (this.props.match.params.user_access_id) {
      this.props.setPageHeader("User", "Edit", "");
      this.props.fetchUserByID(this.props.match.params.user_access_id);
    } else this.props.setPageHeader("User", "Add", "");
  }
  componentDidUpdate(prevProps) {
    if (prevProps.saveSuccess !== this.props.saveSuccess) {
      this.props.showSnackbar("Saved Successfully..!");
      this.props.history.push("/user");
    }
    if (prevProps.user !== this.props.user) {
      if (!this.state.update) {
        const { user } = this.props;
        this.setState({
          update: true,
          data: {
            user_access_id: user.data.user_access_id,
            user_name: user.data.user_name,
            password: user.data.password,
            active: user.data.active,
            user_details_id: user.data.userDetails.user_details_id,
            name: user.data.userDetails.name,
            address: user.data.userDetails.address,
            email: user.data.userDetails.email,
            phone_no: user.data.userDetails.phone_no,
            role_id: user.data.role.role_id,
            local_body_id: user.data.localBody.local_body_id
          }
        });
      }
    }
  }
  validateForm = ()=>{
    const reg=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$/;
    const isValid= reg.test(this.state.data.password);
    if(!reg.test(this.state.data.password ) && !this.state.update) {
      this.setState({
          data:{...this.state.data},
          passworderror:"Password should be strong"
        
      });
      return false;
    }else{
      this.setState({
        data:{...this.state.data},
        passworderror:""
      });
      return true;
    }
  }
  onSubmit = e => {
    e.preventDefault();
    if(this.validateForm()){
      let args = {
        role: {
          role_id: this.state.data.role_id
        },
        userDetails: {
          user_details_id: this.state.data.user_details_id,
          name: this.state.data.name,
          address: this.state.data.address,
          email: this.state.data.email,
          phone_no: this.state.data.phone_no
        },
        localBody: {
          status: this.state.data.status,
          local_body_id: this.state.data.local_body_id,
          local_body_name: this.state.data.local_body_name
        },
        user_access_id: this.state.data.user_access_id,
        user_name: this.state.data.user_name,
        password: this.state.data.password,
        active: this.state.data.active
      };
     this.props.addOrUpdateUser(args);
    }
   
  };
  handleInputChange = event => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value }
    });
  };
  handleCancel = () => {
    this.props.history.push("/user");
  };

  // VALIDATION
  checkEmail() {
    return this.state.data.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ?
    true : 'error';
  }
  checkPhoneNumber() {
    return this.state.data.phone_no.match(/^\d{10}$/) ? true : 'error';
  }
  checkUserName() {
    return this.state.data.user_name.length > 3 ? true : 'error';
  }

  //********
  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="name">
            <ControlLabel>Name</ControlLabel>
            <FormControl
              type="input"
              placeholder="Name"
              value={this.state.data.name}
              name="name"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="address">
            <ControlLabel>Address</ControlLabel>
            <FormControl
              type="input"
              placeholder="Address"
              value={this.state.data.address}
              name="address"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="email" validationState={this.checkEmail()}>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              type="email"
              placeholder="Email"
              value={this.state.data.email}
              name="email"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="phone_no" validationState={this.checkPhoneNumber()}>
            <ControlLabel>Phone No</ControlLabel>
            <FormControl
              type="input"
              placeholder="Phone No"
              value={this.state.data.phone_no}
              name="phone_no"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="user_name" validationState={this.checkUserName()}>
            <ControlLabel>User Name</ControlLabel>
            <FormControl
              type="input"
              placeholder="UserName"
              value={this.state.data.user_name}
              name="user_name"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>{" "}
        </Col>
        {!this.props.match.params.user_access_id&&
        <Col xs={12} sm={4} md={4} lg={3}>
        <FormGroup controlId="password">
          <ControlLabel>Paasword</ControlLabel>
          <FormControl
            type="password"
            placeholder="Password"
            value={this.state.data.password}
            name="password"
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>{" "}
        <div ><p className="errorClass">{this.state.passworderror}</p></div>
      </Col>
    }
        
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="role_id">
            <ControlLabel>Role</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              value={this.state.data.role_id}
              defaultValue={this.state.data.role_id}
              name="role_id"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              {this.props.roles.map(role => {
                return (
                  <option value={role.id} key={role.id}>
                    {role.value}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="local_body_id">
            <ControlLabel>Local Body </ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              value={this.state.data.local_body_id}
              defaultValue={this.state.data.local_body_id}
              name="local_body_id"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              {this.props.localBodies.map(localBody => {
                return (
                  <option value={localBody.id} key={localBody.id}>
                    {localBody.value}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup controlId="active">
            <ControlLabel>Active Status</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              value={this.state.data.active}
              defaultValue={this.state.data.active}
              name="active"
              onChange={this.handleInputChange}
              required
            >
              <option value="false">False</option>
              <option selected value="true">
                True
              </option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={21} lg={12}>
          <hr className="hr-primary" />
        </Col>
        <Col xs={12} sm={4} md={4} lg={3}>
          <FormGroup>
            <Button type="submit" bsStyle="success">
              Save
            </Button>{" "}
            <Button bsStyle="warning" onClick={this.handleCancel}>
              Cancel
            </Button>
          </FormGroup>
        </Col>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    saveSuccess: state.userReducer.saveSuccess,
    roles: state.dropdown.roles,
    localBodies: state.dropdown.localBodies
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    addOrUpdateUser: data => dispatch(addOrUpdateUser(data)),
    fetchUserByID: userId => dispatch(fetchUserByID(userId)),
    showSnackbar: snackbarMessage =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getDropdownData: type => dispatch(getDropdownData(type))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddEdituser)
);
