import { SET_PAGE_HEADER } from "./constants";
const initialState = {
  title: "",
  subTitle: "",
  action: ""
};
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_HEADER:
      return {
        ...state,
        title: action.headers.title,
        subTitle: action.headers.subTitle,
        action: action.headers.action
      };
    default:
      return state;
  }
}
