export const GET_LAYERS = "GET_LAYERS";

export const GET_COLUMNS = "GET_COLUMNS";

export const FETCH_LAYER_COLUMS = "FETCH_LAYER_COLUMS";

export const FETCH_FILTER_LAYER = "FETCH_FILTER_LAYER";
export const FETCH_FILTER_LAYER_SUCCESS = "FETCH_FILTER_LAYER_SUCCESS";
export const TOGGLE_REPORT_MODEL = "TOGGLE_REPORT_MODEL ";
export const FETCHING_DATA = "FETCHING";
export const FETCH_DATA_SUCCESS = "FETCH_SUCCESS";
export const FETCH_DATA_FAILED = "FETCH_FAILED";

export const FETCHING_VALUE = "FETCHING_VALUE";
export const FETCH_VALUE_SUCCESS = "FETCH_VALUE_SUCCESS";
export const FETCH_VALUE_FAILED = "FETCH_VALUE_FAILED";

export const ADVANCESEARCH_VALUE_FETCHING = "ADVANCESEARCH_VALUE_FETCHING";
export const ADVANCESEARCH_VALUE_FETCH_SUCCESS = "ADVANCESEARCH_VALUE_FETCH_SUCCESS";
export const ADVANCESEARCH_VALUE_FETCH_FAILED = "ADVANCESEARCH_VALUE_FETCH_FAILED";

export const REPORT_VALUE_FETCHING = "REPORT_VALUE_FETCHING";
export const REPORT_VALUE_FETCH_SUCCESS = "REPORT_VALUE_FETCH_SUCCESS";
export const REPORT_VALUE_FETCH_FAILED = "REPORT_VALUE_FETCH_FAILED";

export const GENERATE_REPORT = "GENERATE_REPORT";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_FAILED = "GENERATE_REPORT_FAILED";

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILED = "GET_REPORT_FAILED";


export const FETCHING_SEARCHED_LAYER_SUCCESS = "FETCHING_SEARCHED_LAYER_SUCCESS";



  
