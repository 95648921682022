import React, { Component } from "react";
import localStorageHandler from "../../util/storage/";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./index.css";
import { doLogout } from "../user/login/data/actions";
import MenuItems from "./MenuItems";
import PageLayout from "../../containers/page_layout";
import SnackBar from "../snackbar";
import Loader from "../loader/Loader";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNavigation: true,
      userMenu: null,
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) this.props.history.push("/login");
    this.setState({
      userMenu: this.getMenu(),
    });
  }

  componentDidUpdate() {
    if (!this.props.isAuthenticated) this.props.history.push("/login");
  }
  //Side panael navigation management
  handleNavigation = () => {
    this.setState({ showNavigation: !this.state.showNavigation });
  };
  //redirection management
  handleRedirection = () => {
    this.props.history.push("/map");
  };
  getMenu = () => {
    const menu = localStorageHandler.getMenu();
    if (menu != null) {
      const userMenu = menu.map((menuItem) => {
        if (!menuItem.is_submenu) {
          let subMenus = menu.filter((subMenuItem) => {
            if (subMenuItem.permission.length > 0)
              if (subMenuItem.permission[0].view_permission)
                return subMenuItem.parent_id == menuItem.menu_id;
          });
          menuItem["sub_menus"] = subMenus;
          return menuItem;
        } else {
          return;
        }
      });
      let filteredMenu = userMenu.filter((userMenuItem) => {
        return userMenuItem;
      });
      return filteredMenu;
    }
  };

  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-inverse">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target="#myNavbar"
              >
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <div className="navbar-brand">
                <span
                  id="menu-toggle"
                  href="#"
                  className="glyphicon glyphicon-align-justify btn-menu toggle"
                  onClick={this.handleNavigation}
                />
                {localStorageHandler.getLocalBody()
                  ? localStorageHandler.getLocalBody().local_body_name ==
                    "Corporation Of Thrissur"
                    ? "WET Thrissur"
                    : "KWA ADMIN"
                  : "KWA"}

                {/* {localStorageHandler.getLocalBody().local_body_name ==
                "Corporation Of Thrissur"
                  ? "WET Thrissur"
                  : "IPMS ADMIN"} */}
              </div>
              <ul className="nav navbar-nav">
                <li className="maps">
                  <span id="maps" onClick={this.handleRedirection}>
                    <span className="glyphicon glyphicon-globe" />{" "}
                    <span>Back To Map</span>
                  </span>
                </li>
              </ul>
            </div>

            <div className="collapse navbar-collapse" id="myNavbar">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="#">
                    <span className="glyphicon glyphicon-user" />{" "}
                    {localStorageHandler.getUserDetails()
                      ? localStorageHandler.getUserDetails().name
                      : ""}
                  </a>
                </li>
                <li>
                  <a href="#" onClick={this.props.doLogout}>
                    <span className="glyphicon glyphicon-log-out" /> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div
          className={
            this.state.showNavigation
              ? "nav-side-menu"
              : "nav-side-menu collapsed"
          }
        >
          <div className="menu-list">
            {this.state.userMenu != null && (
              <MenuItems userMenu={this.state.userMenu} />
            )}
          </div>
        </div>
        <Loader />
        <div className="main-content">
          <PageLayout>{this.props.children}</PageLayout>
        </div>
        <SnackBar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.loginReducer.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(doLogout()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
