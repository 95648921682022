import APIRequest from "../util/api/APIRequest";
const getLayers = (page, limit,localbody_id) => {
  return APIRequest({
    url: "layer/",
    method: "GET",
    params: {
      page,
      limit,
      localbody_id
    }
  });
};

const addOrUpdateLayer = (data, method) => {
  return APIRequest({
    url: "layer/",
    method: method, //POST or PUT
    data: data
  });
};

const fetchLayer = layer_id => {
  return APIRequest({
    url: `layer/${layer_id}`,
    method: "GET"
  });
};

const deleteLayer = layer_id => {
  return APIRequest({
    url: `layer/${layer_id}`,
    method: "DELETE"
  });
};

const accessibleLayers = url => {
  return APIRequest({
    url: `map/getuseraddpermissonedlayers/`,
    method: "POST",
  });
};

const LayerService = {
  getLayers,
  addOrUpdateLayer,
  fetchLayer,
  deleteLayer,
  accessibleLayers
};
export default LayerService;
