import React, { Component } from "react";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Table,
  Badge,
  Accordion,
  Card,
  PanelGroup,
  Tabs,
  Tab,
  Jumbotron,
} from "react-bootstrap";
import "./ReportModel.css";
import getAttributeName from "../../../../../../util/attributes";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import closebtn from "../../iconimages/close_icon.svg";
import localStorageHandler from "../../../../../../util/storage";
import getLayers from "../../../../map_header/advanced_filter/data/action";

class TableModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelName: "Excel",
      layers: [],
      open: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSelect = (activeKey) => {
    this.setState({ activeKey });
  };
  componentDidMount = () => {
    this.getLayerNames();
  };

  getLayerNames = () => {
    let layer = this.props.layer;
    console.log("this.props.layer: ", this.props.layer);
    let layerTree = localStorageHandler.getLayerCategory();
    let layers = [];
    var layerMatch = {};
    layerTree.map((treeItem, index) => {
      if (treeItem.hasOwnProperty("children")) {
        treeItem.children.map((layer) => {
          if (layer.value == this.props.layer) {
            layerMatch = layer;
            this.setState({ excelName: treeItem.label + " - " + layer.label });
          }
        });
      }
    });
    this.setState(
      {
        layerMatch: layerMatch,
      },
      () => {
        console.log(this.state.layerMatch);
      }
    );
  };

  filterProperties(properties) {
    let filterdedProps = {};
    let detailedInfoAttributes = null;
    if (properties != null) {
      if (this.state.layerMatch != null) {
        console.log("DETAILINFO", this.state.layerMatch.detailedInfoAttributes);
        detailedInfoAttributes = this.state.layerMatch.detailedInfoAttributes;
      }
      if (detailedInfoAttributes)
        Object.keys(properties).filter((key) => {
          detailedInfoAttributes.forEach((atribute) => {
            if (key === atribute.field) {
              filterdedProps[key] = properties[key];
            }
          });
        });
    }
    return filterdedProps;
  }
  handleResponseHeader() {
    let filterdedProps = {};
    let properties = {};
    console.log("RESULT", this.props.result);
    this.props.result.map((res, i) => {
      properties = res.properties;
    });
    console.log(properties);
    filterdedProps = this.filterProperties(properties);
    console.log("filterdedProps", filterdedProps);
    if (filterdedProps != null) {
      return (
        <tr key={-1}>
          {Object.keys(filterdedProps).map((key) => {
            if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponse() {
    let properties = {};
    let filterdedProps = {};
    return this.props.result.map((res, i) => {
      properties = res.properties;
      filterdedProps = this.filterProperties(properties);
      if (filterdedProps != null) {
        return (
          <tr key={i}>
            {Object.keys(filterdedProps).map((key) => {
              if (!key.includes("photo") && !key.includes("p_d_f_")) {
                return <td key={key}>{filterdedProps[key]}</td>;
              }
            })}
          </tr>
        );
      }
    });
  }

  render() {
    const rowItems = this.handleResponse();
    const rowHead = this.handleResponseHeader();
    return (
      <div className="bufferReportContainerModel">
        <Jumbotron className="jumbo">
          <Row>
            <Col xs={12} sm={3} md={3} lg={3}>
              <h5 className="excelNam">{"Layer : " + this.state.excelName}</h5>
            </Col>
            <Col xs={12} sm={2} md={2} lg={2}>
              <h5 className="excelNam"> {"Count : " + this.props.count}</h5>
            </Col>
            <Col xs={12} sm={7} md={7} lg={7}>
              <label>Excel Name</label>
              <input
                type="text"
                placeholder="Label"
                value={this.state.excelName}
                // value={this.props.layer}
                name="excelName"
                onChange={this.handleInputChange}
                required
                className="excelName"
              />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-success"
                // table="table-to-xls"
                table={"table" + this.state.excelName}
                filename={this.state.excelName}
                sheet="tablexls"
                buttonText="Download as XLS"
              />
            </Col>
          </Row>
        </Jumbotron>
        <Table
          striped
          bordered
          condensed
          hover
          className="detailedInfoContent"
          id={"table" + this.state.excelName}
        >
          <thead>{rowHead}</thead>
          <tbody>{rowItems}</tbody>
        </Table>
      </div>
    );
  }
}
export default TableModel;
