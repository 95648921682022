import React, { Component } from "react";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
import "./ReportModel.css";
import getAttributeName from "../../../../../../util/attributes";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import closebtn from "../../iconimages/close_icon.svg";
import TableModel from "./TableModel";

class ResultModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelName: "Excel",
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Modal
        className="pdfModelResult"
        show={this.props.show}
        bsSize="large"
        aria-labelledby="contained-modal-title-lg"
      >
        <Modal.Header className="pdfModelResultHeader">
          <Modal.Title id="contained-modal-title-lg">
            REPORT
            <ButtonToolbar className="pull-right button">
              <img
                src={closebtn}
                className="pdfheadbtn"
                onClick={this.props.handleShowReport}
              />
            </ButtonToolbar>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ overflowY: "scroll", maxHeight: "473px" }}
        >
          {this.props.result.map((item, index) => {
            return (
              <TableModel
                result={item.result.features}
                layer={item.layer}
                count={item.count}
                index={index}
              />
            );
          })}
        </Modal.Body>
      </Modal>
    );
  }
}
export default ResultModel;
