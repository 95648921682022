import React, { Component } from "react";
import OverlayPanel from "../overlay_panel/OverlayPanel";
import { connect } from "react-redux";
import "./StyleLoader.css";
import { FormGroup, Radio } from "react-bootstrap";
class StyleLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleLayer: []
    };
  }
  componentDidUpdate(prevProps, prevState) {
    let data = [];

    if (prevProps.showStyleControl !== this.props.showStyleControl) {
      const { mapLayers } = this.props;

      for (let i = 0; i < mapLayers.length; i++) {
        if (mapLayers[i].getSource().tileGrid != null) continue;
        if (mapLayers[i].getVisible() && mapLayers[i].type == "TILE") {
          let layer = mapLayers[i].getSource().getParams().LAYERS;

          let layerName = mapLayers[i].getProperties().name;
          let styles = mapLayers[i].getProperties().styles;
          var element = {
            layerName: layerName,
            style: styles,
            maplayer: i
          };
          data.push(element);
        }
      }
      this.setState({
        visibleLayer: data,
        showStyleControl: this.props.showStyleControl
      });
    }
  }
  handleStyle = changeEvent => {
    const { mapLayers } = this.props;
    mapLayers[changeEvent.target.dataset.letter]
      .getSource()
      .updateParams({ STYLES: changeEvent.target.value });
  };
  render() {
    return (
      <OverlayPanel
        showControl={this.props.showStyleControl}
        handleShowControl={this.props.handleShowControl}
        titleName="Categories"
      >
        {this.state.visibleLayer.length === 0 && (
          <h5>There are no Layers selected</h5>
        )}
        {this.state.visibleLayer.map((layer, index) => (
          <React.Fragment key={index}>
            <h5>
              <b>{layer.layerName.replace("Ponnani ", "")}</b>
            </h5>
            <FormGroup>
              <label className="radio inline">
                <input
                  type="radio"
                  name={layer.layerName}
                  value=""
                  data-letter={layer.maplayer}
                  onChange={this.handleStyle}
                />

                <span>Default </span>
              </label>

              {layer.style.map((style, index) => (
                <label className="radio inline" key={index}>
                  <input
                    type="radio"
                    name={layer.layerName}
                    value={style}
                    data-letter={layer.maplayer}
                    onChange={this.handleStyle}
                  />

                  <span>{style.replace("Ponnani ", "")} </span>
                </label>
              ))}
            </FormGroup>
          </React.Fragment>
        ))}
      </OverlayPanel>
    );
  }
}
function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyleLoader);
