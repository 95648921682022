import APIRequest from "../util/api/APIRequest";

const getLayerCategories = (page, limit, localbody_id) => {
  return APIRequest({
    url: "layercategory/",
    method: "GET",
    params: {
      page: page,
      limit: limit,
      localbody_id
    }
  });
};

// const getLayerCategories = (page, limit) => {
//   return APIRequest({
//     url: "layercategory/",
//     method: "GET",
//     params: {
//       page: page,
//       limit: limit
//     }
//   });
// };

const addOrUpdateLayerCategory = (data, method) => {
  return APIRequest({
    url: "layercategory/",
    method: method, //POST or PUT
    data: data
  });
};

const fetchLayerCategory = layercatrgory_id => {
  return APIRequest({
    url: `layercategory/${layercatrgory_id}`,
    method: "GET"
  });
};

const deleteLayerCategory = layercatrgory_id => {
  return APIRequest({
    url: `layercategory/${layercatrgory_id}`,
    method: "DELETE"
  });
};

const LayerCategoryService = {
  getLayerCategories,
  addOrUpdateLayerCategory,
  fetchLayerCategory,
  deleteLayerCategory
};
export default LayerCategoryService;
