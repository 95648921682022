import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Button from "../../containers/button";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { deleteLayer } from "./data/action";
import ConfirmBox from "../confirmation_box/";

class LayerListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmation: false,
      deleteId: 0,
    };
  }

  onDeleteClick = (layer_id) => {
    this.setState({ showDeleteConfirmation: true, deleteId: layer_id });
  };
  confirmDelete = () => {
    if (this.state.deleteId > 0) {
      this.props.deleteLayer(this.state.deleteId);
      this.handleClose();
    }
  };
  handleClose = () => {
    this.setState({ showDeleteConfirmation: false });
  };
  render() {
    return (
      <React.Fragment>
        <ConfirmBox
          show={this.state.showDeleteConfirmation}
          action={this.confirmDelete}
          onHide={this.handleClose}
        />
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Label</th>
              <th>Layer</th>
              <th>URL</th>
              <th className="col-md-2">Action</th>
            </tr>
          </thead>
          {this.props.layers.data && (
            <tbody>
              {this.props.layers.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.layer_name}</td>
                    <td>{item.url}</td>
                    <td>
                      <NavLink to={`/layer/edit/${item.layer_id}`}>
                        <Button
                          text="Edit"
                          bsStyle="success"
                          bsSize="xsmall"
                          page="/layer"
                          type="update_permission"
                        />{" "}
                      </NavLink>
                      <Button
                        text="Delete"
                        onClick={() => this.onDeleteClick(item.layer_id)}
                        bsStyle="danger"
                        bsSize="xsmall"
                        page="/layer"
                        type="delete_permission"
                      />{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    deleteLayer: (layer_id) => dispatch(deleteLayer(layer_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerListTable);
