import React, { Component } from "react";
import { connect } from "react-redux";
import "./AddAsset.css";
import AddAssetPanel from "./AddAssetPanel";
import addassetred from "../iconimages/add_asset.svg";
import { updateWidgetEnable } from "../../map_component/data/actions";
import Tools from "../../../../../containers/tools/Tools";
const widgets = "Add New Asset";
class AddAsset extends Component {
  handleShowPanelControl = () => {
    this.props.updateWidgetEnable(widgets, this.props.showWidget);
  };
  render() {
    return (
      <React.Fragment>
        <Tools
          tooltip={widgets}
          styles="addAsset"
          functions={this.handleShowPanelControl}
          icon={addassetred}
          visibleFlag={true}
        />
        {this.props.showWidget === widgets && <AddAssetPanel />}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget, preWidget) =>
      dispatch(updateWidgetEnable(showWidget, preWidget)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAsset);
