import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { setPageHeader } from "../../containers/page_layout/data/action";
import { addOrUpdateLayer, fetchLayer } from "./data/action";
import { snackbarActions } from "../snackbar/data/action";
import { getDropdownData } from "../../containers/dropdown/data/action";
import dropdownTypes from "../../containers/dropdown/dropdownTypes";
import { toStringHDMS } from "ol/coordinate";

class AddEditLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        value: "",
        label: "",
        url: "",
        layer_name: "",
        type: "",
        layer_category_id: "",
        local_body_id: "",
        layer_type: "",
        layer_order: "",
        is_searchable: "",
        adv_fltr_status: "",
        minimalInfoAttributes: [],
        detailedInfoAttributes: [],
        style: "",
        conditionName: null,
        conditionValue: null,
        // visibility: "",
      },
      selectedMinimalInfo: [],
      selectedDetailedInfo: [],
    };
  }
  componentDidMount() {
    this.props.getDropdownData(dropdownTypes.LAYER_CATEGORY);
    this.props.getDropdownData(dropdownTypes.LOCAL_BODY);
    if (this.props.match.params.layer_id) {
      this.props.setPageHeader("Layer", "Edit", "");
      this.props.fetchLayer(this.props.match.params.layer_id);
    } else this.props.setPageHeader("Layer", "Add", "");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saveSuccess != this.props.saveSuccess) {
      this.props.showSnackbar("Saved Successfully..!");
      this.props.history.push("/layer");
    }
    if (prevProps.layer != this.props.layer) {
      this.setState({
        data: {
          ...this.props.layer,
        },
      });
    }
  }
  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { data } = this.state;

    let minimalInfo = [];
    let detailedInfo = [];
    let params = data;

    if (typeof data.minimalInfoAttributes == "string") {
      data.minimalInfoAttributes.split(",").map((element) => {
        if (element != "") minimalInfo.push(element);
      });
      params["minimalInfoAttributes"] = minimalInfo;
    } else {
      data.minimalInfoAttributes.map((element) => {
        minimalInfo.push(element);
      });
      params["minimalInfoAttributes"] = minimalInfo;
    }

    if (typeof data.detailedInfoAttributes == "string") {
      data.detailedInfoAttributes.split(",").map((element) => {
        if (element != "") detailedInfo.push(element);
      });
      params["detailedInfoAttributes"] = detailedInfo;
    } else {
      data.detailedInfoAttributes.map((element) => {
        detailedInfo.push(element);
      });
      params["detailedInfoAttributes"] = detailedInfo;
    }

    this.props.addOrUpdateLayer(params);
  };
  handleCancel = () => {
    this.props.history.push("/layer");
  };
  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Label</ControlLabel>
            <FormControl
              type="input"
              placeholder="Label"
              value={this.state.data.label}
              name="label"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Value</ControlLabel>
            <FormControl
              type="input"
              placeholder="Value"
              value={this.state.data.value}
              name="value"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>URL</ControlLabel>
            <FormControl
              type="input"
              placeholder="URL"
              value={this.state.data.url}
              name="url"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <ControlLabel>Layer Category</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="Layer Category"
              value={this.state.data.layer_category_id}
              name="layer_category_id"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              {this.props.layerCategories.map((layerCategory) => {
                return (
                  <option value={layerCategory.id} key={layerCategory.id}>
                    {layerCategory.value}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <ControlLabel>Local Body</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="Local Body"
              value={this.state.data.local_body_id}
              name="local_body_id"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              {this.props.localBodies.map((localBody) => {
                return (
                  <option value={localBody.id} key={localBody.id}>
                    {localBody.value}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <ControlLabel>Type</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="Layer type"
              value={this.state.data.type}
              name="type"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              <option value="TILE">TILE</option>
              <option value="VECTOR">VECTOR</option>
              <option value="wmts">WMTS</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <ControlLabel>View Type</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="View type"
              value={this.state.data.layer_type}
              name="layer_type"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              <option value="base_layer">BASE LAYER</option>
              <option value="layer">LAYER</option>
              <option value="search_layer">SEARCHABLE LAYER</option>
            </FormControl>
          </FormGroup>
        </Col>

        <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <ControlLabel>Layer Order</ControlLabel>
            <FormControl
              type="input"
              placeholder="Layer Order (displayed in Geoserver)"
              value={this.state.data.layer_order}
              name="layer_order"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <ControlLabel>Searchable</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="View type"
              value={this.state.data.is_searchable}
              name="is_searchable"
              onChange={this.handleInputChange}
              required
            >
              <option value="">Select</option>
              <option value="false">FALSE</option>
              <option value="true">TRUE</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Layer Name</ControlLabel>
            <FormControl
              type="input"
              placeholder="Layer Name (displayed in Geoserver)"
              value={this.state.data.layer_name}
              name="layer_name"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Advance filter status</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="View type"
              value={this.state.data.adv_fltr_status}
              name="adv_fltr_status"
              onChange={this.handleInputChange}
              required
            >
              <option value="">Select</option>
              <option value="true">TRUE</option>
              <option value="false">FALSE</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Style</ControlLabel>
            <FormControl
              type="input"
              placeholder="Style"
              value={this.state.data.style}
              name="style"
              onChange={this.handleInputChange}
              // required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Condition Name</ControlLabel>
            <FormControl
              type="input"
              placeholder="Condition Attribute Name"
              value={this.state.data.conditionName}
              name="conditionName"
              onChange={this.handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Condition Value</ControlLabel>
            <FormControl
              type="input"
              placeholder="Condition Attribute Value"
              value={this.state.data.conditionValue}
              name="conditionValue"
              onChange={this.handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Visibility</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="Visibility"
              value={this.state.data.visibility}
              name="visibility"
              onChange={this.handleInputChange}
            >
              <option value="">Select</option>
              <option value="true">TRUE</option>
              <option value="false">FALSE</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup required>
            <ControlLabel>Minimal Info Columns</ControlLabel>
            <FormControl
              type="input"
              placeholder="Minimal Info Columns"
              value={this.state.data.minimalInfoAttributes}
              name="minimalInfoAttributes"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup>
            <ControlLabel>Detailed Info Columns</ControlLabel>
            <FormControl
              type="textarea"
              placeholder="Detailed Info Columns"
              value={this.state.data.detailedInfoAttributes}
              name="detailedInfoAttributes"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={21} lg={12}>
          <hr className="hr-primary" />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup>
            <Button type="submit" bsStyle="success">
              Save
            </Button>{" "}
            <Button bsStyle="warning" onClick={this.handleCancel}>
              Cancel
            </Button>
          </FormGroup>
        </Col>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    saveSuccess: state.layer.saveSuccess,
    layer: state.layer.layer,
    layerCategories: state.dropdown.layerCategories,
    localBodies: state.dropdown.localBodies,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    addOrUpdateLayer: (data) => dispatch(addOrUpdateLayer(data)),
    fetchLayer: (layer_id) => dispatch(fetchLayer(layer_id)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getDropdownData: (type) => dispatch(getDropdownData(type)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddEditLayer)
);
