import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Help.css";
import ReactPlayer from "react-player/lazy";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  Label,
  Badge,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Container,
  Table,
  Jumbotron,
} from "react-bootstrap";
import backarrow from "../map_viewer/map/widgets/iconimages/back.svg";
class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBackClick = () => {
    this.props.history.push("/map");
  };
  handleContextMenu = (e) => {
    e.preventDefault();
  };
  render() {
    const url = [
      "http://gis.kwa.kerala.gov.in/videos/KWA_%20Main%20video.mp4",
      "http://gis.kwa.kerala.gov.in/videos/KWA_%20Admin%20Module.mp4",
    ];
    const videoName = ["Admin Module", "Main Video"];
    return (
      <div className="reportContainer">
        <React.Fragment>
          <div className="reportTitle">
            <Row>
              <img
                src={backarrow}
                className="back"
                onClick={this.handleBackClick}
              />
              KWA HELP MODULE
            </Row>
          </div>
          <div>
            {/* <div className="helptitle">DEMO VIDEOS</div> */}
            <div className="videoTitle">
              {videoName.map((name) => (
                <Badge key={name}>{name}</Badge>
              ))}
            </div>
            <div className="player-wrapper">
              {url.map((srcUrl) => {
                return (
                  <div key={srcUrl}>
                    {/* <video
                      controls="true"
                      controlsList="nodownload"
                      width="430"
                      height="430"
                      onContextMenu={this.handleContextMenu}
                    >
                      <source src={`${srcUrl}`} type="video/mp4" />
                    </video> */}

                    <ReactPlayer
                      url={`${srcUrl}`}
                      onContextMenu={this.handleContextMenu}
                      config={{
                        file: { attributes: { controlsList: "nodownload" } },
                      }}
                      controls
                    />
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(Help);
