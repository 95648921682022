import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { loadingActions } from "../../components/loader/data/action";
import { connect } from "react-redux";
import { snackbarActions } from "../snackbar/data/action";
import { setPageHeader } from "../../containers/page_layout/data/action";
import {
  getLayers,
  fetchcolumsByLayer,
  fetchDataInReport,
  requestSearchedLayer,
  generateReport,
  getReportLink,
} from "../map_viewer/map_header/advanced_filter/data/action";
import { toggleAdvancedFilterWindow } from "../map_viewer/map_header/navigation/navbar_form/data/action";
import Filter from "./components/Filter";
import Attribute from "./components/Attribute";
import getAttributeName from "../../util/attributes/";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { CSVLink, CSVDownload } from "react-csv";
import backarrow from "../map_viewer/map/widgets/iconimages/back.svg";
import localStorageHandler from "../../util/storage";
import "./Report.css";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  Label,
  Badge,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Container,
  Table,
  Jumbotron,
} from "react-bootstrap";
import Loader from "../loader/Loader";
// import TablePagination from "../pagination";
import TablePagination from "../pagination/pagination";

var temp;
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      showAtrbt: false,
      reportStatus: false,
      filterFormCount: [],
      result: [],
      index: 0,
      layers: {},
      layerCategorys: localStorageHandler.getLayerCategory(),
      data: {
        layer: "",
        layerCategory: "",
        reportName: "",
        display_name: "",
        fileFormat: "",
        layerName: "",
      },
      layer: null,
      params: "",
      parameters: [],
      columnlist: [],
      count: 0,
      propertyName: "",
      showSubmitSelect: false,
      temp: [],
      page: "1",
      limit: "10",
      recordCount: 0,
      parentModel: "",
      modelList: [],
      array: [],
    };
  }

  handleToUpdate = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["temp".concat(index)]: value },
    });
  };

  componentDidMount() {
    this.props.setPageHeader("", "", "");
    this.props.getLayers();
  }
  //USED IN FILTER FORM
  handleInputChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleInputChangeLimit = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleInputChangeAttribute = (
    event,
    index,
    field,
    model_name,
    parent_model,
    app_label
  ) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
        ["display_name".concat(index)]: event.target.value,
        ["attributeField".concat(index)]: field,
        ["model".concat(index)]: model_name,
        ["parentModel".concat(index)]: parent_model,
        ["app_label".concat(index)]: app_label,
      },
    });
  };

  handleInputChangeOperation = (event) => {
    this.props.showSnackbar("Please enter the Value");
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleInputChangedropdown = (value, index) => {
    this.setState({
      data: { ...this.state.data, ["value".concat(index)]: value },
    });
  };
  // TO ADD FILTER AND VISIBILITY OF FILTER COMPONENT
  filterCheck = () => {
    if (this.state.filterFormCount.length > 0)
      this.setState({
        showSubmitSelect: true,
      });
    else if (this.state.filterFormCount.length > 0)
      this.setState({
        showSubmitSelect: false,
      });
  };

  addFilterForm = (type) => {
    if (!this.props.show) {
      this.props.hide();
    }
    this.props.showSnackbar("Please select an attribute");
    if (
      (this.state.filterFormCount.length <= 8 &&
        type == "Add" &&
        this.state.filterFormCount.length == 0) ||
      (this.state.filterFormCount.length <= 8 && type == "AddMore")
    ) {
      this.setState(
        {
          filterFormCount: [...this.state.filterFormCount, 1],
          data: {
            ...this.state.data,
            ["operation".concat(this.state.index)]: "=",
            ["temp".concat(this.state.index)]: [],
          },
          index: this.state.index + 1,
        },
        () => {
          if (this.state.index >= 2) {
            this.setState({
              data: {
                ...this.state.data,
                ["condition".concat([this.state.index - 1])]: "and",
              },
            });
          }
        }
      );
    }
  };

  clearFilter = () => {
    this.setState({
      reportStatus: false,
      filterFormCount: [],
      index: 0,
    });
  };

  //FORMATING FILTER (CONDITIONS)
  getFilter = (data) => {
    let filter = "";
    let obj = [];
    Object.keys(data).map((key) => {
      if (key.includes("column")) {
        if (data["condition".concat(key.substr(-1))]) {
          filter = filter
            .concat(" ")
            .concat(data["condition".concat(key.substr(-1))])
            .concat(" ");
        }
        filter = filter
          .concat(data[key])
          .concat(data["operation".concat(key.substr(-1))])
          .concat("'")
          .concat(data["value".concat(key.substr(-1))])
          .concat("'");
        obj.push({
          // attribute: data[key],
          display_name: data["display_name".concat(key.substr(-1))],
          attribute: data["attributeField".concat(key.substr(-1))],
          op: data["operation".concat(key.substr(-1))],
          value: data["value".concat(key.substr(-1))],
          model_name: data["model".concat(key.substr(-1))],
          parent_model: data["parentModel".concat(key.substr(-1))],
          app_label: data["app_label".concat(key.substr(-1))],
          join_op:
            data["condition".concat(key.substr(-1))] == undefined
              ? null
              : data["condition".concat(key.substr(-1))],
        });
      }
    });
    return obj;
  };

  showAttribute = () => {
    if (this.state.columnlist.length == 0) {
      let selectedColumnsList = this.getFilter(this.state.data);
      console.log("selectedColumnsList: ", selectedColumnsList);
      selectedColumnsList.map((item) => {
        this.state.columnlist.push({
          field: item.attribute,
          model_name: item.model_name,
          display_name: item.display_name,
          app_label: item.app_label,
        });
      });
      this.setState({
        showAtrbt: !this.state.showAtrbt,
        columnlist: this.state.columnlist,
        parameters: this.state.parameters.concat(this.state.columnlist),
      });
    } else {
      this.setState({
        showAtrbt: !this.state.showAtrbt,
      });
    }
  };

  closeAttri = () => {
    this.setState({
      showAtrbt: !this.state.showAtrbt,
    });
  };

  handleShowTable = () => {
    this.setState({
      showTable: true,
    });
  };

  handleResponseHeader() {
    let advancefilterinfo = this.state.data.layer.advancefilterinfo;
    console.log(this.state.result);
    console.log(advancefilterinfo);
    let properties = {};
    let names = {};
    this.state.result.map((res, i) => {
      properties = res;
    });
    console.log("properties: ", properties);
    advancefilterinfo.map((res, i) => {
      names = res;
    });
    console.log("names: ", names);
    if (properties != null) {
      // return (
      //   <tr key={-1}>
      //     <th>SL NO</th>
      //     {Object.keys(properties).map((key) => {
      //       return Object.keys(advancefilterinfo).map((k) => {
      //         console.log("names[display_name]",advancefilterinfo["display_name"])
      //         console.log("k",k)
      //         if (!key.includes("photo") && !key.includes("p_d_f_")) {
      //           if(names[k] == key){
      //             return <th key={key}>{names.display_name}</th>;
      //           }
      //         }
      //       });
      //     })}
      //   </tr>
      // );
      return (
        <tr key={-1}>
          <th>SL NO</th>
          {Object.keys(properties).map((key) => {
            if (!key.includes("photo") && !key.includes("p_d_f_")) {
              return <th key={key}>{getAttributeName(key)}</th>;
            }
          })}
        </tr>
      );
    }
  }

  handleResponse() {
    let properties = {};
    return this.state.result.map((res, i) => {
      properties = res;
      if (properties != null) {
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            {Object.keys(properties).map((key) => {
              if (!key.includes("photo") && !key.includes("p_d_f_")) {
                return <td key={key}>{properties[key]}</td>;
              }
            })}
          </tr>
        );
      }
    });
  }

  handleBackClick = () => {
    this.props.history.push("/map");
  };

  handleInputChangeLayer = (event) => {
    this.props.layers.map((layer) => {
      if (layer.layer_id == event.target.value) {
        this.setState(
          {
            data: {
              ...this.state.data,
              layer: layer,
              reportName: layer.label,
              layerName: layer.label,
            },
          },
          () => {}
        );
      }
    });
  };

  //USED IN ATRIBUTE COMPONENT
  handleInputChangeSelect = (event, int) => {
    const attributeInformation = this.state.data.layer.advancefilterinfo.find(
      (attribute) => attribute.display_name === event.target.value
    );
    console.log("attributeInformation: ", attributeInformation);

    let newColumns = {
      field: attributeInformation.field,
      model_name: attributeInformation.model_name,
      display_name: event.target.value,
      app_label: attributeInformation.app_label,
    };

    let flag = true;
    if (this.state.parameters.length != 0) {
      // Uncheck the selected attribute
      this.state.parameters.map((item, index) => {
        if (item.display_name == event.target.value) {
          flag = false;
          var array = [...this.state.parameters];
          // make a separate copy of the array
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ parameters: array }, () => {});
          }
        }
      });
    }
    if (flag) {
      this.setState(
        {
          parameters: [...this.state.parameters, newColumns],
        },
        () => {}
      );
    }
  };

  handleInputSelectAll = () => {
    let array = [];
    this.state.data.layer.advancefilterinfo.map((item) => {
      let newColumns = {
        field: item.field,
        model_name: item.model_name,
        display_name: item.display_name,
        app_label: item.app_label,
      };
      array.push(newColumns);
    });
    this.setState(
      {
        parameters: array,
      },
      () => {}
    );
  };

  handleSubmit = (e) => {
    e.persist();
    console.log(e);
    e.preventDefault();
    e.stopPropagation();
    //filter the attribute by checking attribute have parent model or not
    let parentModel = this.state.data.layer.advancefilterinfo.filter(
      (attribute) => {
        return attribute.parent_model && attribute.app_label;
      }
    );

    // To get model names of selected columns in a list
    // List should contain the parent model name as first element
    let parentModelList = {};
    parentModelList = [
      {
        model_name: parentModel[0].parent_model,
        app_label: this.state.data.layer.app_label,
      },
    ];
    let modelNames = {};
    modelNames = this.state.parameters.map((model) => {
      return { model_name: model.model_name, app_label: model.app_label };
    });

    const finalModelList = [...parentModelList, ...modelNames];
    // To eliminate duplicate elements
    // let modelList = [...new Set(finalModelList)];
    let modelList = finalModelList.reduce((unique, o) => {
      if (
        !unique.some((obj) => obj.label === o.label && obj.value === o.value)
      ) {
        unique.push(o);
      }
      return unique;
    }, []);

    // logic: Id field of the parent model must be send along with the selected columns
    let idColumn = {
      field: "id",
      model_name: parentModel[0].parent_model,
      display_name: "ID Field",
    };
    this.state.parameters.push(idColumn);

    // Data to be send in request
    let filter = {
      page: "1",
      limit: this.state.limit,
      report: "False",
      file_name: "",
      file_extension: "",
      models: modelList,
      columns: this.state.parameters,
      layer_name: this.state.data.layerName,
      parent_model: parentModel[0].parent_model,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      app_label: this.state.data.layer.app_label,
    };
    this.setState({
      parentModel: parentModel[0].parent_model,
      modelList: modelList,
    });
    this.props.fetchDataInReport(filter);
  };

  generateReport = () => {
    let reportValues = {
      page: this.state.page,
      limit: this.props.recordCount,
      report: "True",
      file_name: this.state.data.reportName,
      file_extension: this.state.data.fileFormat,
      models: this.state.modelList,
      columns: this.state.parameters,
      layer_name: this.state.data.layerName,
      parent_model: this.state.parentModel,
      obj: this.getFilter(this.state.data),
      category_field: this.state.data.layer.category_field,
      category_id: this.state.data.layer.category_id,
      app_label: this.state.data.layer.app_label,
    };
    this.props.generateReport(reportValues);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data.layer.layer_id != this.state.data.layer.layer_id) {
      this.setState({
        filterFormCount: [],
        index: 0,
        modelList: [],
        columnlist: [],
        parameters: [],
      });
    }
    if (prevProps.tableData != this.props.tableData) {
      if (this.props.recordCount > 0) {
        this.setState(
          {
            result: this.props.tableData,
            layer: this.state.data.layer,
            reportStatus: true,
          },
          () => {
            this.handleShowTable();
          }
        );
      } else {
        console.log("Reaching else");
        this.props.showSnackbar("No Features Found");
      }
    }
    if (prevState.page != this.state.page) {
      // Data to be send in request
      let filter = {
        page: this.state.page,
        limit: this.state.limit,
        report: "False",
        file_name: "",
        file_extension: "",
        models: this.state.modelList,
        columns: this.state.parameters,
        layer_name: this.state.data.layerName,
        parent_model: this.state.parentModel,
        obj: this.getFilter(this.state.data),
        category_field: this.state.data.layer.category_field,
        category_id: this.state.data.layer.category_id,
        app_label: this.state.data.layer.app_label,
      };
      this.props.fetchDataInReport(filter);
    }
    if (prevProps.report_result != this.props.report_result) {
      if (this.props.report_result == "success") {
        let reportValues = {
          page: this.state.page,
          limit: this.props.recordCount,
          report: "True",
          file_name: this.state.data.reportName,
          file_extension: this.state.data.fileFormat,
          models: this.state.modelList,
          columns: this.state.parameters,
          layer_name: this.state.data.reportName,
          parent_model: this.state.parentModel,
          obj: this.getFilter(this.state.data),
        };
        this.props.getReportLink(reportValues);
      }
    }
    if (prevProps.reportLink != this.props.reportLink) {
      window.open(this.props.reportLink);
    }
  }

  // onPageChange = (page) => {
  //   if (!isNaN(page))
  //     this.setState({
  //       page: page,
  //     });
  // };

  afterPageClicked = (page) => {
    console.log("afterPageClicked: ", page);
    if (!isNaN(page))
      this.setState({
        page: page,
      });
  };

  render() {
    return (
      <div className="reportContainer">
        <React.Fragment>
          <div className="reportTitle">
            <Row>
              <img
                src={backarrow}
                className="back"
                onClick={this.handleBackClick}
              />
              Report
            </Row>
          </div>
          <Loader />
          <div className="reportCard">
            <div className="selectLayerRow">
              <Row>
                <Col xs={12} sm={4} md={4} lg={4}>
                  <FormGroup className="selectLayer">
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Layer Category
                    </ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layerCategory"
                      // className="drp_layer"
                      value={this.state.data.layerCategory}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="">Select Layer Category</option>
                      {this.state.layerCategorys
                        .sort((a, b) => (a["label"] > b["label"] ? 1 : -1))
                        .map((layerCategory) => {
                          return (
                            <option
                              key={layerCategory.value}
                              value={layerCategory.layer_category_id}
                            >
                              {layerCategory.label}
                            </option>
                          );
                        })}
                    </FormControl>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={4} md={4} lg={4}>
                  {" "}
                  <FormGroup className="selectLayer">
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Layer
                    </ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="layer"
                      value={this.state.data.layer.layer_id}
                      disabled={this.state.data.layerCategory == ""}
                      onChange={this.handleInputChangeLayer}
                      required
                    >
                      <option value="">Select layer</option>
                      {this.props.layers
                        .sort((a, b) => (a["label"] > b["label"] ? 1 : -1))
                        .map((layer) => {
                          // if (layer.adv_fltr_status) {
                          if (
                            layer.layer_category_id ==
                            this.state.data.layerCategory
                          ) {
                            return (
                              <option key={layer.value} value={layer.layer_id}>
                                {layer.label}
                              </option>
                            );
                          }
                          // }
                        })}
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <FormGroup required>
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Records per page
                    </ControlLabel>
                    <FormControl
                      type="input"
                      placeholder="Enter records per page"
                      value={this.state.limit}
                      name="limit"
                      onChange={this.handleInputChangeLimit}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={2} md={2} lg={2}>
                  <Button
                    bsSize="xsmall"
                    block
                    className="btn_sel_col"
                    onClick={() => this.addFilterForm("Add")}
                    disabled={this.state.data.layer === ""}
                  >
                    <i className="glyphicon glyphicon-plus-sign" /> Add Filter
                  </Button>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <Button
                    bsSize="xsmall"
                    type="submit"
                    block
                    disabled={!this.state.showSubmitSelect}
                    className="btn_sel_col"
                    onClick={this.showAttribute}
                  >
                    Select Columns To Display
                  </Button>
                </Col>
                <Col xs={12} sm={3} md={2} lg={2}>
                  <Button
                    bsSize="xsmall"
                    block
                    type="submit"
                    bsStyle="success"
                    disabled={this.state.parameters.length == 0}
                    className="btn_submit"
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>
                </Col>

                <Filter
                  data={this.state.data}
                  filterCheck={this.filterCheck}
                  addFilterForm={this.addFilterForm}
                  clearFilter={this.clearFilter}
                  handleInputChange={this.handleInputChange}
                  handleInputChangedropdown={this.handleInputChangedropdown}
                  filterFormCount={this.state.filterFormCount}
                  handleToUpdate={this.handleToUpdate}
                  handleInputChangeAttribute={this.handleInputChangeAttribute}
                  handleInputChangeOperation={this.handleInputChangeOperation}
                  temp={temp}
                />
                {this.state.data.layer != "" && (
                  <Attribute
                    data={this.state.data}
                    columnStatus={this.state.columnStatus}
                    show={this.state.showAtrbt}
                    showAttri={this.showAttribute}
                    closeAttri={this.closeAttri}
                    parameters={this.state.parameters}
                    handleInputChange={this.handleInputChange}
                    handleInputChangeSelect={this.handleInputChangeSelect}
                    handleInputSelectAll={this.handleInputSelectAll}
                  />
                )}
              </Row>
            </div>
            <Row>
              {this.state.showTable && (
                <React.Fragment>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="resultContainerModel">
                      <Table
                        striped
                        bordered
                        condensed
                        hover
                        className="detailedInfoContent"
                        id="table-to-xls"
                      >
                        <thead>{this.handleResponseHeader()}</thead>
                        <tbody>{this.handleResponse()}</tbody>
                      </Table>
                    </div>
                  </Col>
                  <TablePagination
                    recordCount={this.props.recordCount}
                    page={this.state.page}
                    limit={this.state.limit}
                    pageClicked={(item) => {
                      this.afterPageClicked(item);
                    }}
                    // onClick={(e) =>
                    //   this.onPageChange(parseInt(e.target.text))
                    // }
                    // onNext={() => this.onPageChange(this.state.page + 1)}
                    // onPrev={() => this.onPageChange(this.state.page - 1)}
                  />
                </React.Fragment>
              )}
            </Row>
            <div className="selectLayerRow">
              {this.state.showTable && (
                <React.Fragment>
                  <Row style={{ marginTop: "10px" }}>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      {" "}
                      <FormGroup className="selectLayer">
                        <ControlLabel style={{ fontWeight: "bold" }}>
                          Report
                        </ControlLabel>
                        <FormControl
                          type="input"
                          placeholder="File Name"
                          value={this.state.data.reportName}
                          name="reportName"
                          onChange={this.handleInputChange}
                          className="drp_layer"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      {" "}
                      <FormGroup className="selectLayer">
                        <ControlLabel style={{ fontWeight: "bold" }}>
                          File Format
                        </ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          name="fileFormat"
                          value={this.state.data.fileFormat}
                          onChange={this.handleInputChange}
                          required
                        >
                          <option value="">Select</option>
                          <option value="csv">Excel</option>
                          {/* <option value="pdf">PDF</option> */}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col xs={6} sm={3} md={2} lg={2}>
                      <Button
                        bsSize="xsmall"
                        block
                        type="submit"
                        bsStyle="success"
                        className="btn_submit"
                        onClick={this.generateReport}
                        disabled={this.state.data.fileFormat == ""}
                      >
                        Export
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <h5 style={{ padding: "0px", marginTop: "34px" }}>
                        Searched Count: {this.props.recordCount}
                        <Badge className="countBadge" />
                      </h5>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedfilterWindow,
    layerColumns: state.advancedFilter.layerColumns,
    layers: state.advancedFilter.layers,
    tableData: state.advancedFilter.tableData,
    recordCount: state.advancedFilter.recordCount,
    fetching: state.advancedFilter.fetching,
    data: state.advancedFilter.data,
    report_result: state.advancedFilter.report_result,
    reportLink: state.advancedFilter.reportLink,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    fetchcolumsByLayer: (layer) => dispatch(fetchcolumsByLayer(layer)),
    hide: () => dispatch(toggleAdvancedFilterWindow()),
    isLoading: () => dispatch(loadingActions.isloading()),
    loadingComplete: () => dispatch(loadingActions.loadingComplete()),
    getLayers: () => dispatch(getLayers()),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),

    fetchDataInReport: (filterValues) =>
      dispatch(fetchDataInReport(filterValues)),
    generateReport: (reportValues) => dispatch(generateReport(reportValues)),
    getReportLink: (reportValues) => dispatch(getReportLink(reportValues)),
    requestSearchedLayer: (layerToSearch, featureRequest) =>
      dispatch(requestSearchedLayer(layerToSearch, featureRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
