import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import "./AddAssetPanel.css";
import { ADMIN_URL } from "../../../../../../src/config";
import { accessibleLayers } from "./data/actions";
import LocalStorageHandler from "../../../../../util/storage";
import ToolsPopupLayout from "../../../../../containers/tools_popup_layouy/ToolsPopupLayout";
class AddAssetPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layer_id: "",
      app_name: "",
      model_name: "",
    };
  }
  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
    let layerInfo = this.props.layers_list.find(
      (attribute) => attribute.layer_name === event.target.value
    );
    console.log("layerInfo: ", layerInfo);
    this.setState({
      app_name: layerInfo.app_name,
      model_name: layerInfo.model_name,
    });
  };
  componentDidMount() {
    this.props.accessibleLayers();
  }
  render() {
    return (
      <ToolsPopupLayout
        widgetName="Add New Asset"
        panelheading="Layer Count"
        collapseStyle="addassetpanel"
        panelHeadBtn="panelheadbtn"
        panelBodyStyle="addassetpanelsBody"
      >
        <Row>
          <FormGroup className="marginSelect">
            <Col xs={12} sm={12} md={12} lg={12}>
              <ControlLabel style={{ fontWeight: "bold" }}>Layer</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                name="bufferType"
                value={this.state.layer_name}
                onChange={this.handleInputChange}
                required
              >
                <option value="">Select Layer</option>
                {this.props.layers_list != null
                  ? this.props.layers_list.map((layer) => {
                      return (
                        <option
                          // key={layer.value}
                          value={layer.layer_name}
                        >
                          {layer.layer_name}
                        </option>
                      );
                    })
                  : ""}
              </FormControl>
            </Col>
          </FormGroup>
        </Row>
        <Row>
          <Button
            className="addassetpanelButton "
            bsStyle="success"
            bsSize="small"
            onClick={(e) => {
              window.open(
                ADMIN_URL +
                  // "admin/" +
                  "map/login/" +
                  LocalStorageHandler.getToken() +
                  "/" +
                  this.state.app_name +
                  "/" +
                  this.state.model_name +
                  "/add//",
                "_blank"
              );
            }}
          >
            Add
          </Button>
        </Row>
      </ToolsPopupLayout>
    );
  }
}
function mapStateToProps(state) {
  return {
    layers_list: state.addAsset.layers_list,
    fetching: state.addAsset.fetching,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    accessibleLayers: () => dispatch(accessibleLayers()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddAssetPanel);
