import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getLayerCategories } from "./data/action";
import { setPageHeader } from "../../containers/page_layout/data/action";
import { snackbarActions } from "../snackbar/data/action";
import LayerCategoryListTable from "./LayerCategoryListTable";
import TablePagination from "../pagination";
import Button from "../../containers/button/";
import LBDropDown from "../../containers/localbody_dropdown/localbody_dropdown";

class LayerCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layerCategories: [],
      page: 1,
      limit: 10,
      localbody_id: null,
      recordCount: 0,
    };
  }
  componentDidMount() {
    this.props.setPageHeader(
      "Layer Category",
      "List",
      <Button
        text="Add Layer Category"
        className="pull-right"
        onClick={() => this.props.history.push("/layercategory/add/")}
        bsStyle="primary"
        page="/layercategory"
        type="add_permission"
      />
    );
    this.props.getLayerCategories(
      this.state.page,
      this.state.limit,
      this.state.localbody_id
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.layerCategories != this.props.layerCategories) {
      this.setState({
        layerCategories: this.props.layerCategories,
        recordCount: this.props.recordCount,
      });
    }
    if (prevState.page != this.state.page) {
      this.props.getLayerCategories(
        this.state.page,
        this.state.limit,
        this.state.localbody_id
      );
    }
    if (prevState.localbody_id != this.state.localbody_id) {
      this.props.getLayerCategories(
        this.state.page,
        this.state.limit,
        this.state.localbody_id
      );
    }
    if (prevProps.deleteSuccess != this.props.deleteSuccess) {
      this.props.showSnackbar("Deleted Successfully..!");
      this.props.getLayerCategories(
        this.state.page,
        this.state.limit,
        this.state.localbody_id
      );
    }
  }

  onLocalBodyChange = id => {
    let localbody_id = id;
    if (id === "") {
      localbody_id = null;
    }
    if (!isNaN(localbody_id))
      this.setState({
        localbody_id,
        page: 1
      });
  };

  onPageChange = (page) => {
    if (!isNaN(page))
      this.setState({
        page: page,
      });
  };
  render() {
    return (
      <React.Fragment>
        <LBDropDown onLocalBodyChange={this.onLocalBodyChange} />
        <LayerCategoryListTable layerCategories={this.state.layerCategories} />
        <TablePagination
          recordCount={this.state.recordCount}
          page={this.state.page}
          limit={this.state.limit}
          onClick={(e) => this.onPageChange(parseInt(e.target.text))}
          onNext={() => this.onPageChange(this.state.page + 1)}
          onPrev={() => this.onPageChange(this.state.page - 1)}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    fetching: state.layerCategory.fetching,
    layerCategories: state.layerCategory.layerCategories,
    recordCount: state.layerCategory.recordCount,
    deleteSuccess: state.layerCategory.deleteSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLayerCategories: (page, limit) =>
      dispatch(getLayerCategories(page, limit)),
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LayerCategoryList)
);
