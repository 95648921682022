import React, { Component } from "react";
import { FormGroup, Col, FormControl, ControlLabel } from "react-bootstrap";
import FilterForm from "./FilterForm";
class ConditionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index } = this.props;
    return (
      <FormGroup controlId="formHorizontalEmail">
        <Col xs={9} sm={9} md={9} lg={9} />
        <Col xs={9} sm={9} md={9} lg={9}>
          <FormControl
            componentClass="select"
            placeholder="select"
            name={"condition".concat(index)}
            value={this.props.data["condition".concat(index)]}
            onChange={this.props.handleInputChange}
            style={{width: "100px", marginLeft : "49%"}}
          >
            <option value="and">And</option>
            <option value="or">Or</option>
          </FormControl>
        </Col>
        <Col xs={9} sm={9} md={9} lg={9} />
      </FormGroup>
    );
  }
}

export default ConditionSelector;
