import React, { Component } from "react";
import { Pagination } from "react-bootstrap";

class TablePagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let totalPages = Math.ceil(this.props.recordCount / this.props.limit);
    console.log("TotalPages", totalPages);
    let half = Math.ceil(parseInt(totalPages.length / 2));
    console.log("HALF", half);
    console.log("HALF +", half + 3);
    console.log("HAL - ", half - 3);
    let nextDisabled = totalPages === this.props.page;
    let prevDisabled = this.props.page === 1;
    return (
      <Pagination bsSize="small" className="pull-right">
        <Pagination.Prev
          disabled={prevDisabled}
          onClick={!prevDisabled ? this.props.onPrev : null}
        />
        {[...Array(totalPages)].map((e, i) => (
          <Pagination.Item
            key={i}
            value={i + 1}
            active={this.props.page === i + 1}
            onClick={this.props.onClick}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={nextDisabled}
          onClick={!nextDisabled ? this.props.onNext : null}
        />
      </Pagination>
    );
  }
}

export default TablePagination;
