import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./BufferPanel.css";
import ToolsPopupLayout from "../../../../../containers/tools_popup_layouy/ToolsPopupLayout";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import {
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Polygon,
  Point,
} from "ol/geom";
import LinearRing from "ol/geom/LinearRing";
import Draw from "ol/interaction/Draw.js";
import { unByKey } from "ol/Observable.js";
import { transform, toLonLat, fromLonLat } from "ol/proj.js";
import Feature from "ol/Feature.js";
import { mapQueryBuffer } from "./data/action";
import ReportModel from "./components/ReportModel";
import { GeoJSON } from "ol/format.js";
import styleFunction from "../../../map/helper/vectorStyle";
import getLayerTree from "../../../map/helper/LayerTreeLoader";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
class BufferPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawType: null,
      vectorSource: null,
      draw: null,
      sketch: null,
      listener: null,
      showReprort: false,
      reportStatus: false,
      resultLayer: null,
      geometryOfBuffer: null,
      geometryOfFeature: null,
      bufferType: "",
      bufferValue: 0,
      vectorLayer: null,
      resultVectorSource: null,
      bufferSource: null,
      tempFeature: null,
      point: null,
      flagPole: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    this.setState({
      vectorSource: new VectorSource(),
      resultVectorSource: new VectorSource(),
      bufferSource: new VectorSource(),
    });
  }

  handleShowReport = () => {
    this.setState({
      showReprort: !this.state.showReprort,
    });
  };

  handleshowBufferPanelControl = () => {
    const { vectorSource, resultVectorSource, bufferSource } = this.state;
    const { mapComponent } = this.props;
    this.setState({
      drawType: null,
      bufferValue: 0,
      bufferType: "",
    });
    mapComponent.removeInteraction(this.state.draw);
    mapComponent.removeInteraction(this.state.tempFeature);
    this.props.handleshowBufferPanelControl();
  };

  handlePolygonTypeControl = (drawType) => {
    const { mapComponent } = this.props;
    const { vectorSource, draw, resultVectorSource, bufferSource } = this.state;
    mapComponent.removeInteraction(draw);
    mapComponent.removeInteraction(this.state.tempFeature);
    vectorSource.clear();
    resultVectorSource.clear();
    bufferSource.clear();
    mapComponent.addLayer(this.getVectorLayer());
    let listener;
    this.setState(
      {
        drawType: drawType,
        draw: this.addInteraction(drawType),
        tempFeature: this.addInteraction(drawType),
        flagPole: this.getVectorLayer(),
      },
      () => {
        // console.log("flagPole: ", this.state.flagPole);
        // console.log("drawType: ", this.state.drawType);
        // console.log("tempFeature: ", this.state.tempFeature);
        // console.log("draw: ", this.state.draw);
        mapComponent.addInteraction(this.state.tempFeature);
        mapComponent.addInteraction(this.state.draw);
        const { draw } = this.state;
        draw.on("drawstart", (evt) => {
          vectorSource.clear();
          listener = this.handleDrawStart(evt);
        });
        draw.on("drawend", (listener) => {
          this.handleDrawEnd(listener);
        });
      }
    );
  };

  handleDrawStart = (evt) => {
    // console.log("EVENT", evt);
    // console.log("flagPole: ", this.state.flagPole);
    this.setState({
      sketch: evt.feature,
    });
    if (this.state.drawType == "Point" && this.state.point == null) {
      const { mapComponent } = this.props;
      var pointlayer = null;
      var coordinate = evt.target.sketchCoords_;
      var latLong = transform(coordinate, "EPSG:3857", "EPSG:4326");
      var centerLongitudeLatitude = fromLonLat(latLong);
      pointlayer = new VectorLayer({
        source: new VectorSource({
          projection: "EPSG:4326",
          features: [new Feature(new Point(centerLongitudeLatitude))],
        }),
        zIndex: 1000,
        style: new Style({
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: "blue",
            }),
            fill: new Fill({
              color: "blue",
            }),
          }),
        }),
      });
      mapComponent.addLayer(pointlayer);
      this.setState({
        point: pointlayer,
      });
    }
  };

  handleDrawEnd = (listener) => {
    // console.log("LISTENER", listener);
    const { mapComponent } = this.props;
    var source = new VectorSource();
    var parser = new jsts.io.OL3Parser();
    parser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon
    );
    var geometryOfFeature = this.state.sketch
      .getGeometry()
      .clone()
      .transform("EPSG:3857", "EPSG:4326");
    this.setState({
      geometryOfFeature: geometryOfFeature,
    });

    var jstsGeom = parser.read(this.state.sketch.getGeometry());
    // var range = parseInt(bufferValue);
    var bufferRange = Math.round(this.state.bufferValue);
    // Buffered in meter
    var buffered = jstsGeom.buffer(bufferRange);
    this.state.sketch.setGeometry(parser.write(buffered));
    // console.log("(buffered)", this.state.sketch.getGeometry());

    source.addFeatures(this.state.sketch);

    var vectorLayer = new VectorLayer({
      source: source,
      zIndex: 1000,
    });

    this.setState({
      bufferSource: source,
    });

    var geometryOfBuffer = this.state.sketch
      .getGeometry()
      .clone()
      .transform("EPSG:3857", "EPSG:4326");
    this.setState({
      geometryOfBuffer: geometryOfBuffer,
    });

    mapComponent.addLayer(vectorLayer);
    this.setState({
      vectorLayer: vectorLayer,
    });

    this.queryBuffer();
    this.setState({
      sketch: null,
      flagPole: false,
    });
    unByKey(listener);
  };

  queryBuffer = () => {
    var res = [];
    var layerdetails = {};
    var layers =
      this.props.mapComponent.values_.layergroup.values_.layers.array_[1]
        .values_.layers.array_;
    layers.forEach((element) => {
      if (element.values_.visible) {
        var obj = {};
        // var layer = element.values_.name;
        let layerTree = getLayerTree();
        layerTree.map((item) => {
          if (item.hasOwnProperty("children")) {
            let res = item.children.filter((layer) => {
              if (layer.value == element.values_.name) {
                obj["modelname"] = layer.model_name;
                obj["field"] = layer.category_field;
                obj["field_value"] = layer.category_id;
                obj["layer_label"] = item.label + " - " + layer.label;
              }
            });
          }
        });
        obj["layername"] = element.values_.name;
        obj["service_name"] = element.values_.source.params_.LAYERS;
        res.push(obj);
      }
    });
    if (res.length > 0) {
      layerdetails["layer"] = res;
      this.setState({
        layerlist: res,
      });
      if (this.state.drawType == "Polygon") {
        this.props.mapQueryBuffer(
          this.state.layerlist,
          this.state.geometryOfBuffer.flatCoordinates,
          this.state.geometryOfFeature.flatCoordinates
        );
      } else {
        this.props.mapQueryBuffer(
          this.state.layerlist,
          this.state.geometryOfBuffer.flatCoordinates
        );
      }
    } else {
      this.props.showSnackbar("Select Required Layer Before Drawing");
    }
  };

  getVectorLayer() {
    return new VectorLayer({
      source: this.state.vectorSource,
      zIndex: 1000,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "blue",
          width: 2,
        }),
      }),
    });
  }

  addInteraction(drawType) {
    return new Draw({
      source: this.state.vectorSource,
      zIndex: 1000,
      type: drawType,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
  }

  clearMap = () => {
    const { mapComponent } = this.props;
    const { vectorSource, resultVectorSource, bufferSource } = this.state;
    this.setState({
      drawType: null,
      sketch: null,
    });
    mapComponent.removeLayer(this.state.resultLayer);
    mapComponent.removeInteraction(this.state.draw);
    mapComponent.removeInteraction(this.state.tempFeature);
    mapComponent.removeLayer(this.state.point);
    vectorSource.clear();
    resultVectorSource.clear();
    bufferSource.clear();
    mapComponent.removeInteraction(this.state.sketch);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.response_features != this.props.response_features) {
      const response_features = this.props.response_features;
      if (this.props.response_features.length > 0) {
        var i = 0,
          flag = 0;
        while (i < this.props.response_features.length) {
          response_features.forEach((element) => {
            if (element.count != 0) {
              flag = 1;
              return;
            }
          });
          i++;
        }
        if (flag == 1) {
          this.addSearchResultToMap();
          this.setState(
            {
              result: this.props.response_features,
              layer: this.props.response_features,
            },
            () => {
              // this.handleShowReport();
            }
          );
        }
      } else {
        this.props.showSnackbar("No Features Found");
      }
    }
  }

  addSearchResultToMap() {
    const { mapComponent, response_features } = this.props;
    let resultVectorSource = this.state.resultVectorSource;
    response_features.forEach((element) => {
      let infoAttributes = this.getInfoAttributes(element);
      let resultLayer = new VectorLayer({
        name: element.layer,
        infoAttributes: infoAttributes,
        visible: true,
        source: resultVectorSource,
        zIndex: 1000,
        style: (feature, resolution) => styleFunction(feature, resolution),
      });
      let features = new GeoJSON().readFeatures(element.result);
      resultVectorSource.addFeatures(features);
      mapComponent.addLayer(resultLayer);
    });
    mapComponent.getView().fit(resultVectorSource.getExtent());
  }

  getInfoAttributes(element) {
    let infoAttributes;
    let layerTree = getLayerTree();
    layerTree.map((item) => {
      if (item.hasOwnProperty("children")) {
        let res = item.children.filter((layer) => {
          if (layer.label == element.layer) {
            infoAttributes = {
              minimalInfoAttributes: layer.minimalInfoAttributes,
              detailedInfoAttributes: layer.detailedInfoAttributes,
            };
          }
        });
      }
    });
    return infoAttributes;
  }

  render() {
    const { showWidget } = this.props;
    return (
      <React.Fragment>
        {this.state.showReprort && (
          <ReportModel
            show={this.state.showReprort}
            handleShowReport={this.handleShowReport}
            result={this.state.result}
            layer={this.state.layer}
          />
        )}
        <ToolsPopupLayout
          widgetName="Buffer"
          panelheading="Buffer"
          collapseStyle="bufferPanels"
          panelHeadBtn="panelheadbtn"
          panelBodyStyle="bufferPanelsBody"
        >
          <Form onSubmit={this.onSubmit}>
            <Row>
              <FormGroup className="marginSelect">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="menu">Select layers</Tooltip>}
                  >
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="bufferType"
                      value={this.state.bufferType}
                      onChange={this.handleInputChange}
                      required
                    >
                      <option value="">Select Buffer Type</option>
                      <option value="Point">Point Buffer</option>
                      <option value="LineString">Line Buffer</option>
                      <option value="Polygon">Polygon Buffer</option>
                    </FormControl>
                  </OverlayTrigger>
                </Col>
              </FormGroup>
            </Row>
            <Row className="marginRange">
              <Col xs={7} sm={7} md={7} lg={7}>
                <FormGroup>
                  <div class="range">
                    <input
                      type="range"
                      className="range"
                      name="bufferValue"
                      value={this.state.bufferValue}
                      min={0}
                      max={50000}
                      step={1}
                      disabled={this.state.bufferType == ""}
                      onChange={this.handleInputChange}
                    ></input>
                  </div>
                </FormGroup>
              </Col>
              <FormGroup>
                <Col xs={5} sm={5} md={5} lg={5}>
                  {this.state.bufferValue > 1000 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="menu">Max Limit : 50000</Tooltip>}
                    >
                      <InputGroup>
                        <FormControl
                          type="input"
                          placeholder="Buffer"
                          value={this.state.bufferValue}
                          name="bufferValue"
                          disabled={this.state.bufferType == ""}
                          onChange={this.handleInputChange}
                          required
                        />
                        <InputGroup.Addon>m</InputGroup.Addon>
                      </InputGroup>
                    </OverlayTrigger>
                  ) : (
                    <InputGroup>
                      <FormControl
                        type="input"
                        placeholder="Buffer"
                        value={this.state.bufferValue}
                        name="bufferValue"
                        disabled={this.state.bufferType == ""}
                        onChange={this.handleInputChange}
                        required
                      />
                      <InputGroup.Addon>m</InputGroup.Addon>
                    </InputGroup>
                  )}
                </Col>
              </FormGroup>
            </Row>
            <FormGroup>
              <Row style={{ margin: "20px 10px 10px 10px" }}>
                <Button
                  bsStyle="danger"
                  className="pull-left clearButton"
                  onClick={this.clearMap}
                  disabled={this.state.vectorSource == null}
                >
                  Clear Map
                </Button>
                <Button
                  inline="true"
                  bsStyle="success"
                  className="pull-right clearButton"
                  style={{ marginLeft: "3px" }}
                  disabled={
                    this.state.bufferValue == 0 ||
                    this.state.bufferValue > 50000
                  }
                  onClick={(bufferType) =>
                    this.handlePolygonTypeControl(this.state.bufferType)
                  }
                >
                  Draw
                </Button>
                <Button
                  inline="true"
                  bsStyle="primary"
                  className="pull-right clearButton"
                  disabled={this.props.response_features == null}
                  onClick={this.handleShowReport}
                >
                  Show Report
                </Button>
              </Row>
            </FormGroup>
          </Form>
        </ToolsPopupLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    response_features: state.buffer.response_features,
    fetching: state.buffer.fetching,
    data: state.buffer.data,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    mapQueryBuffer: (layerlist, geometry, featureGeometry) =>
      dispatch(mapQueryBuffer(layerlist, geometry, featureGeometry)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BufferPanel);
