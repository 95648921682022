import { IS_LOADING, LOADING_COMPLETE } from "./constants";

export const loadingActions = {
  isloading,
  loadingComplete
};

function isloading() {
  return { type: IS_LOADING };
}

function loadingComplete() {
  return { type: LOADING_COMPLETE };
}
