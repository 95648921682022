import React, { Component } from "react";
import { connect } from "react-redux";
import "./GetCount.css";
import getcount from "../iconimages/count.svg";
import GetCountPanel from "./GetCountPanel";
import localStorageHandler from "../../../../../util/storage";
import Tools from "../../../../../containers/tools/Tools";
import { updateWidgetEnable } from "../../map_component/data/actions";
const widgets = "Get count";
class GetCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleFlag: false,
    };
  }
  handleshowPanelControl = () => {
    this.props.updateWidgetEnable(widgets, this.props.showWidget);
  };
  isVisible = () => {
    const userMenu = localStorageHandler.getMenu();
    let visibleFlag = false;
    if (userMenu != null) {
      let currentItem = userMenu.filter((menu) => menu.menu_url == "/getcount");
      if (currentItem.length > 0) {
        visibleFlag = currentItem[0].permission[0].add_permission;
      }
    }
    return visibleFlag;
  };
  render() {
    const visibility = this.isVisible();
    return (
      <React.Fragment>
        <Tools
          tooltip={widgets}
          styles="countButton"
          functions={this.handleshowPanelControl}
          icon={getcount}
          visibleFlag={true}
        />
        {this.props.showWidget === widgets && <GetCountPanel />}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget, preWidget) =>
      dispatch(updateWidgetEnable(showWidget, preWidget)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(GetCount);
