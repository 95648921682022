import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  Alert,
  Glyphicon,
  Checkbox,
  Modal,
  ButtonToolbar,
} from "react-bootstrap";
// import Recaptcha from "react-recaptcha";
import localStorageHandler from "../../../util/storage/";
import { connect } from "react-redux";
import { doForgotPassword } from "./data/actions";
import { withRouter } from "react-router-dom";
import { snackbarActions } from "../../snackbar/data/action";
import "./ForgotPasswordModel.css";
import Snackbar from "../../snackbar";
import closebtn from "../../map_viewer/map/widgets/iconimages/close_icon.svg";
import username from "../../map_viewer/map/widgets/iconimages/username.svg";


class ForgotPasswordModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameOremail: "",
      isVerified: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.APIRequestModel !== this.props.APIRequestModel) {
      if (this.props.responses.message == "Success") {
        this.props.showSnackbar(this.props.responses.data);
        this.props.handleForgotModel();
      } else {
        this.props.showSnackbar(this.props.responses.data);
      }
    }
  }
  validateForgotForm() {
    return this.state.usernameOremail.length > 0;
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleSubmitForgot = (event) => {
    let data = {
      usernameOremail: this.state.usernameOremail,
    };
    if (this.state.isVerified == false) {
      alert("Please verify that you are a human!");
    } else {
      this.props.doForgotPassword(data);
    }

    event.preventDefault();
  };

  recaptchaLoaded = () => {
    console.log("capcha successfully loaded");
  };

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  };

  render() {
    return (
      <Modal
        className="modelContainer"
        show={this.props.show}
        // onHide={this.props.handleForgotModel}
      >
        <Snackbar />
        <form onSubmit={this.handleSubmitForgot}>
          <Modal.Header className="fPassword">
            <Modal.Title>Forgot Password</Modal.Title>
            <ButtonToolbar className="pull-right button">
                      <img
                        src={closebtn}
                        className="fpanelheadbtn"
                        onClick={this.props.handleForgotModel}
                      />
                    </ButtonToolbar>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="usernameOremail" bsSize="large">
              <FormControl
                autoFocus
                type="text"
                placeholder="Username/Email"
                value={this.state.usernameOremail}
                onChange={this.handleChange}
                className="loginText"
              />
              <FormControl.Feedback>
              <img src={username} style={{marginRight: "20px", width: "16px"}}/>
              </FormControl.Feedback>
            </FormGroup>
            {this.props.responses.message == "fail" && (
              <Alert bsStyle="danger">Invalid email or usrname</Alert>
            )}
            {/* <FormGroup bsSize="large"> */}
              {/* <Recaptcha
                sitekey="6Lc6N7EZAAAAAE7PF41HoEi1OqA5cAjYPS24leku"
                render="explicit"
                onloadCallback={this.recaptchaLoaded}
                verifyCallback={this.verifyCallback}
              /> */}
            {/* </FormGroup> */}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={3} />
              <Col xs={6}>
                <Button
                  block
                  bsSize="large"
                  bsStyle="success"
                  disabled={!this.validateForgotForm()}
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </Col>
              <Col xs={3} />
            </Row>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    APIRequestModel: state.forgotPasswordReducer.APIRequestModel,
    responses: state.forgotPasswordReducer.responses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doForgotPassword: (usernameOremail) =>
      dispatch(doForgotPassword(usernameOremail)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPasswordModel)
);
