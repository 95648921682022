import {
  SET_SELECTED_LAYER,

} from "./constants";
const initialState = {
  selectedLayers: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_LAYER:
      return {
        ...initialState,
        selectedLayers: action.value
      };
    default:
      return state;
  }
}
