import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { loadingActions } from "../../components/loader/data/action";
import { connect } from "react-redux";
import { snackbarActions } from "../snackbar/data/action";
import { getUpdateReport } from "./data/action";
import backarrow from "../map_viewer/map/widgets/iconimages/back.svg";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "react-modern-calendar-datepicker";
// import "./Report.css";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  Label,
  Badge,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Container,
  Table,
  Jumbotron,
} from "react-bootstrap";
import Loader from "../loader/Loader";

var temp;
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      startDate: "",
      endDate: "",
    };
  }

  handleBackClick = () => {
    this.props.history.push("/map");
  };

  handleStartDateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updateReport != this.props.updateReport) {
      this.setState({
        showTable: true,
      });
    }
  }

  handleResponse = () => {
    return Object.entries(this.props.updateReport).map(([key, value]) => {
      return Object.entries(value).map(([key1, value1]) => {
        return (
          <tr>
            <td>{key}</td>
            <td>{value1.created_at}</td>
            <td>
              {value1.created_by
                .replace(/_/g, " ")
                .replace(/\w\S*/g, function (txt) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                })}
            </td>
            <td>{value1.updated_at}</td>
            <td>
              {value1.updated_by
                .replace(/_/g, " ")
                .replace(/\w\S*/g, function (txt) {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                })}
            </td>
            <td>{value1.remarks}</td>
          </tr>
        );
      });
    });
  };

  handleSubmit = () => {
    if (this.state.startDate !== "" && this.state.endDate !== "") {
      let startDateYear = this.state.startDate.substring(0, 4);
      console.log("startDateYear: ", startDateYear);
      if (Number(startDateYear) < 2022) {
        alert("Start date year should be greater than or equal to 2022");
      } else {
        let startDate = new Date(this.state.startDate);
        let endDate = new Date(this.state.endDate);
        let diff = endDate.getTime() - startDate.getTime();
        let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        if (diffDays > 180) {
          alert("Start date should be less than 180 days");
        } else if (diffDays <= 0) {
          alert("End date should be greater than start date");
        } else {
          this.props.getUpdateReport({
            from: this.state.startDate,
            to: this.state.endDate,
          });
        }
      }
    } else {
      alert("Please select start date and end date");
    }
  };

  render() {
    return (
      <div className="reportContainer">
        <React.Fragment>
          <div className="reportTitle">
            <Row>
              <img
                src={backarrow}
                className="back"
                onClick={this.handleBackClick}
              />
              Update Report
            </Row>
          </div>
          <Loader />
          <div className="reportCard">
            <div className="selectLayerRow">
              <Row>
                <Col xs={12} md={12}>
                  <h3> Update Report</h3>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <FormGroup required>
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      Start date
                    </ControlLabel>
                    <FormControl
                      type="date"
                      name="startDate"
                      onChange={(e) => this.handleStartDateChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <FormGroup required>
                    <ControlLabel style={{ fontWeight: "bold" }}>
                      End date
                    </ControlLabel>
                    <FormControl
                      type="date"
                      name="endDate"
                      onChange={(e) => this.handleStartDateChange(e)}
                      required
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={3} md={2} lg={2}>
                  <Button
                    bsSize="xsmall"
                    block
                    bsStyle="success"
                    className="btn_submit"
                    onClick={() => this.handleSubmit()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
            <Row>
              {this.state.showTable && (
                <React.Fragment>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="resultContainerModel2">
                      <Table
                        striped
                        bordered
                        condensed
                        hover
                        className="detailedInfoContent"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr>
                            <th>Layer</th>
                            <th>Created At</th>
                            <th>Created By</th>
                            <th>Updated At</th>
                            <th>Updated By</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        <tbody>{this.handleResponse()}</tbody>
                      </Table>
                    </div>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    updateReport: state.updateReport.updateReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    isLoading: () => dispatch(loadingActions.isloading()),
    getUpdateReport: (data) => dispatch(getUpdateReport(data)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
