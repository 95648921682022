export const GET_LAYER_CATEGORIES = "GET_LAYER_CATEGORIES";
export const GET_LAYER_CATEGORIES_SUCCESS = "GET_LAYER_CATEGORIES_SUCCESS";
export const GET_LAYER_CATEGORIES_FAILED = "GET_LAYER_CATEGORIES_FAILED";

export const ADD_LAYER_CATEGORY = "ADD_LAYER_CATEGORY";
export const ADD_LAYER_CATEGORY_SUCCESS = "ADD_LAYER_CATEGORY_SUCCESS";
export const ADD_LAYER_CATEGORY_FAILED = "ADD_LAYER_CATEGORY_FAILED";

export const FETCHING_LAYER_CATEGORY = "FETCHING_LAYER_CATEGORY";
export const FETCH_LAYER_CATEGORY_SUCCESS = "FETCH_LAYER_CATEGORY_SUCCESS";
export const FETCH_LAYER_CATEGORY_FAILED = "FETCH_LAYER_CATEGORY_FAILED";

export const DELETE_LAYER_CATEGORY = "DELETE_LAYER_CATEGORY";
export const DELETE_LAYER_CATEGORY_SUCCESS = "DELETE_LAYER_CATEGORY_SUCCESS";
export const DELETE_LAYER_CATEGORY_FAILED = "DELETE_LAYER_CATEGORY_FAILED";
