import React, { Component } from "react";
import { connect } from "react-redux";
import "./Loader.css";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isLoading } = this.props;
    return isLoading ? (
      <div className="load-bar">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.loading.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader);
