import {
  GET_LAYERS_SUCCESS,
  GET_LAYERS_FAILED,
  FETCH_LAYER_SUCCESS,
  FETCH_LAYER_FAILED,
  ADD_LAYER_SUCCESS,
  ADD_LAYER_FAILED,
  DELETE_LAYER_SUCCESS,
  DELETE_LAYER_FAILED
} from "./constants";
const initialState = {
  layers: [],
  recordCount: 0,
  saveSuccess: false,
  deleteSuccess: false,
  layer: {}
};
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LAYERS_SUCCESS:
      return {
        ...initialState,
        layers: action.response.data,
        recordCount: action.response.data.recordCount
      };
    case GET_LAYERS_FAILED:
      return {
        ...initialState
      };

    case ADD_LAYER_SUCCESS:
      return {
        ...initialState,
        saveSuccess: true
      };
    case ADD_LAYER_FAILED:
      return {
        ...initialState,
        saveSuccess: false
      };

    case FETCH_LAYER_SUCCESS:
      return {
        ...initialState,
        layer: action.response
      };
    case FETCH_LAYER_FAILED:
      return {
        ...initialState
      };

    case DELETE_LAYER_SUCCESS:
      return {
        ...initialState,
        deleteSuccess: true
      };
    case DELETE_LAYER_FAILED:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
