import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import layers from "../../../map/widgets/iconimages/layers.svg";
import baselayers from "../../../map/widgets/iconimages/base_maps.svg";
import legend from "../../../map/widgets/iconimages/legend.svg";
import opacity from "../../../map/widgets/iconimages/opacity.svg";
import draw from "../../images/draw.png";
import logout from "../../../map/widgets/iconimages/logout.svg";
// import settings from "../../../map/widgets/iconimages/settings.png";
import help from "../../../map/widgets/iconimages/help.svg";
import more from "../../../map/widgets/iconimages/property.svg";
import moreDetails from "../../../map/widgets/iconimages/more.svg";
// import MenuItem from "./MenuItem";
import ToolbarMenus from "./ToolbarMenus";
import localStorageHandler from "../../../../../util/storage";
// import analysis from "../../../map/widgets/iconimages/analysis.png";
import dataexplorer from "../../images/search.png";
import report from "../../../map/widgets/iconimages/report.svg";
import disastermanagement from "../../images/disaster.png";

class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  mapWidgets() {
    return [
      "Analysis",
      "Disaster Management",
      // "Data Explorer",
      // "Reports",
      "Layers",
      "Legend",
      "Opacity",
      "Base Maps",
      "Draw",
      "More",
      "Styles",
      // "Asset Updation",
      // "Asset Approval",
      "Help",
      "Settings",
    ];
  }

  handleHelpModule = () => {
    window.open("http://map.ulgis.com/help.html", "_blank");
  };

  handleAnalysisModule = () => {
    const localboby = localStorageHandler.getLocalBody().local_body_name;
    if (localboby == "Vadakara Municipality") {
      window.open(
        "http://182.156.226.211/#/site/IPMS/views/IPMS_Vadakara/Home",
        "_blank"
      );
    } else if (localboby == "Kunnothparamba Panchayath") {
      window.open("https://www.google.com/", "_blank");
    }
  };

  handleDataExplorerModule = () => {
    const localboby = localStorageHandler.getLocalBody().local_body_name;
    if (localboby == "Vadakara Municipality") {
      window.open(
        "https://analysis.ulgis.com/t/IPMS/views/DataExplorer/Explorer?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        "_blank"
      );
    } else if (localboby == "Kunnothparamba Panchayath") {
      window.open(
        "https://analysis.ulgis.com/t/IPMS/views/Kunnothparamba/Home?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        "_blank"
      );
    } else if (localboby == "Mayyanad Panchayat") {
      window.open(
        "https://analysis.ulgis.com/t/IPMS/views/IPMS_Mayyanad/Home/vishnuprasad/b84aa851-9988-4a36-8a87-5b811772c275?:display_count=n&:showVizHome=n&:origin=viz_share_link",
        "_blank"
      );
    } else if (localboby == "Munroethuruth Grama Panchayath") {
      window.open(
        "https://analysis.ulgis.com/t/IPMS/views/IPMS_Munroehuruthu/Home?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
        "_blank"
      );
    } else if (localboby == "Mangattidam Grama Panchayat") {
      window.open(
        "https://analysis.ulgis.com/#/signin?redirect=%2Fsite%2FIPMS%2Fworkbooks%2F218%3F:origin%3Dcard_share_link",
        "_blank"
      );
    }
  };

  handleDisasterManagementModule = () => {
    const localboby = localStorageHandler.getLocalBody().local_body_name;
    if (localboby == "Feroke Municipality") {
      window.open("http://feroke.ulgis.com/", "_blank");
    }
  };

  handleReportModule = () => {
    {
      this.props.history.push("/reportnew");
    }
  };
  handleApprovalButtonClick = () => {
    this.props.history.push("/approveform6");
  };

  getWidget(menuName) {
    // console.log(menuName);
    switch (menuName) {
      case "Disaster Management":
        return this.generateMenuItem(
          disastermanagement,
          this.handleDisasterManagementModule,
          menuName
        );
      // case "Data Explorer":
      //   return this.generateMenuItem(
      //     dataexplorer,
      //     this.handleDataExplorerModule,
      //     menuName
      //   );
      // case "Reports":
      //   return this.generateMenuItem(report, this.handleReportModule, menuName);
      case "Layers":
        return this.generateMenuItem(
          layers,
          this.props.handleShowLayerControl,
          menuName
        );

      case "Legend":
        return this.generateMenuItem(
          legend,
          this.props.handleShowLegentControl,
          menuName
        );
      case "Opacity":
        return this.generateMenuItem(
          opacity,
          this.props.handleShowOpacityControl,
          menuName
        );
      case "Base Maps":
        return this.generateMenuItem(
          baselayers,
          this.props.handleShowBaseMapControl,
          menuName
        );
      case "Draw":
        return this.generateMenuItem(draw, null, menuName);

      case "Asset Updation":
        return this.generateMenuItem(
          more,
          this.props.handleSettingsButtonClick,
          menuName
        );
      case "Asset Approval":
        return this.generateMenuItem(
          more,
          this.handleApprovalButtonClick,
          menuName
        );
      case "Styles":
        return this.generateMenuItem(
          draw,
          this.props.handleShowStyleControl,
          menuName
        );
      case "Help":
        return this.generateMenuItem(help, this.handleHelpModule, menuName);
      case "Settings":
        return this.generateMenuItem(
          moreDetails,
          this.props.handleShowSettingsControl,
          menuName
        );

      default:
        return;
    }
  }
  generateMenuItem(img, handleClick, tooltipText) {
    return (
      <ToolbarMenus
        img={img}
        handleClick={handleClick}
        tooltipText={tooltipText}
        key={tooltipText}
      />
    );
  }
  getRoleBasedMenu() {
    let userMenu = localStorageHandler.getMenu();
    let items = [];
    this.mapWidgets().forEach((element) => {
      userMenu.map((menuItem) => {
        if (element === menuItem.menu_name) {
          if (menuItem.permission.length > 0)
            if (menuItem.permission[0].view_permission)
              items.push(this.getWidget(menuItem.menu_name));
        }
      });
    });
    return items;
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-right">
        {this.getRoleBasedMenu()}
        {/* <ToolbarMenus
          img={logout}
          tooltipText={"Logout"}
          handleClick={this.props.handleLogout}
        /> */}
      </ul>
    );
  }
}

export default withRouter(MenuItems);
