import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Col,
  Button,
  ButtonToolbar,
  Dropdown,
  MenuItem,
  Glyphicon,
  FormControl,
} from "react-bootstrap";
import "./ChatBot.css";
import UserMessage from "./ChatMessages";
import axios from "axios";
import sendIcon from "../iconimages/send_icon.svg";
import { updateWidgetEnable } from "../../map_component/data/actions";

class ChatBotPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userMessage: null,
      botMessage: null,
      message: "",
      clearChat: false,
      chatMessages: [],
    };
  }
  componentDidMount() {
    axios("http://199.34.21.14:3548/webhooks/rest/webhook", {
      method: "POST",
      data: {
        message: "Hello",
        sender: "username",
      },
    })
      .then((response) => {
        this.setState({
          botMessage: response.data[0].text,
        });
        this.chatMessages();
      })
      .catch((e) => {
        console.log(e);
      });
  }
  handleInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handlesClearChat = () => {
    this.setState({
      clearChat: !this.state.clearChat,
      chatMessages: [],
    });
  };

  handleSend = () => {
    if (!this.state.message == "") {
      let userMessageArray = {
        user: true,
        userMessage: this.state.message,
      };
      this.state.chatMessages.push(userMessageArray);
    }
    this.setState({
      chatMessages: this.state.chatMessages,
      clearChat: false,
      message: "",
      // isLoading: true,
    });
    axios("http://199.34.21.14:3548/webhooks/rest/webhook", {
      method: "POST",
      data: {
        message: this.state.message,
        sender: "admin",
      },
    })
      .then((response) => {
        this.setState({
          botMessage: response.data[0].text,
          // isLoading: false,
        });
        this.chatMessages();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  handleClosePopup = () => {
    this.props.updateWidgetEnable("Chat Bot", this.props.showWidget);
  };
  chatMessages = () => {
    if (!this.state.botMessage == "") {
      let botMessageArray = {
        user: false,
        botMessage: this.state.botMessage,
      };
      this.state.chatMessages.push(botMessageArray);
    }
    this.setState({
      chatMessages: this.state.chatMessages,
      clearChat: false,
      botMessage: "",
    });
  };

  render() {
    const { showWidget } = this.props;
    return (
      <React.Fragment>
        <Collapse in={showWidget === "Chat Bot"} className="chatBotPanels">
          <div className="widget">
            <div className="chat_header">
              <span className="chat_header_title">KWA Bot</span>
              <ButtonToolbar className="dropdown-trigger">
                <Dropdown pullRight className="dropdown">
                  <Dropdown.Toggle noCaret className="dropdown-dottoggle">
                    <Glyphicon glyph="option-vertical" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="super-colors">
                    <MenuItem eventKey="1" onClick={this.handlesClearChat}>
                      Clear
                    </MenuItem>
                    <MenuItem eventKey="2">Restart</MenuItem>
                    <MenuItem eventKey="3" onClick={this.handleClosePopup}>
                      Close
                    </MenuItem>
                  </Dropdown.Menu>
                </Dropdown>
              </ButtonToolbar>
            </div>
            {!this.state.clearChat && (
              <UserMessage chatMessages={this.state.chatMessages} />
            )}
            <div className="keypad">
              <Col xs={9} sm={9}>
                <div className="textarea">
                  <FormControl
                    componentClass="textarea"
                    placeholder="Type a message..."
                    className="usrInput"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={3} sm={3}>
                <Button
                  className="sendButton"
                  onClick={this.handleSend}
                  bsSize="large"
                >
                  <img className="iconStyle" src={sendIcon} />
                  {/* <Glyphicon glyph="send" /> */}
                </Button>
              </Col>
            </div>
          </div>
        </Collapse>
        {/* )} */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget, preWidget) =>
      dispatch(updateWidgetEnable(showWidget, preWidget)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatBotPanel);
