import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Collapse,
  Row,
  Col,
  Grid,
  Panel,
  ButtonToolbar,
} from "react-bootstrap";
import closebtn from "../../components/map_viewer/map/widgets/iconimages/close_icon.svg";
import { updateWidgetEnable } from "../../components/map_viewer/map/map_component/data/actions";
class ToolsPopupLayout extends Component {
  handleClosePopup = () => {
    this.props.updateWidgetEnable(this.props.widgetName, this.props.showWidget);
  };
  render() {
    const {
      showWidget,
      widgetName,
      collapseStyle,
      panelBodyStyle,
      panelHeadBtn,
      panelheading,
    } = this.props;
    return (
      <Collapse in={showWidget === widgetName} className={collapseStyle}>
        <Grid>
          <Row className="show-grid">
            <Col>
              <Panel bsStyle="primary">
                <Panel.Heading>
                  <Panel.Title componentClass="h3">{panelheading}</Panel.Title>
                  <ButtonToolbar className="pull-right button">
                    <img
                      src={closebtn}
                      className={panelHeadBtn}
                      onClick={this.handleClosePopup}
                    />
                  </ButtonToolbar>
                </Panel.Heading>
                <Panel.Body className={panelBodyStyle}>
                  {this.props.children}
                </Panel.Body>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </Collapse>
    );
  }
}
function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget, preWidget) =>
      dispatch(updateWidgetEnable(showWidget, preWidget)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ToolsPopupLayout);
