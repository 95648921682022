import APIRequest from "../util/api/APIRequest";
import mapDefaults from "../components/map_viewer/map/helper/mapDefaults";

const fetchDataByType = (searchBy, query) => {
  let searchtype = mapDefaults.getLayerToSearch();
  return APIRequest({
    url: `map/basicsearch/` + searchBy + `/`,
    method: "GET",
    // params: {
    //   type: searchtype,
    //   value: searchBy,
    //   query: query,
    // },
  });
};

const fetchDataByValue = (searchBy, query) => {
  return APIRequest({
    url: `map/basicsearchresult/` + searchBy + `/`,
    method: "GET",
    params: {
      search_value: query,
    },
  });
};

const fetchValueByAttribute = (
  model,
  attribute,
  data_type,
  category_field,
  category_id,
  app_label
) => {
  return APIRequest({
    url:
      `map/advancesearchdistinct/` +
      model +
      `/` +
      attribute +
      `/` +
      data_type +
      `/`,
    method: "GET",
    params: {
      category_field: category_field,
      category_id: category_id,
      app_label: app_label,
    },
  });
};

const fetchDataByFilter = (filterValues) => {
  return APIRequest({
    url: `map/advancesearchresult/`,
    method: "POST",
    data: filterValues,
  });
};

const fetchDataInReport = (filterValues) => {
  return APIRequest({
    url: `map/reportresult/`,
    method: "POST",
    data: filterValues,
  });
};

const generateReport = (reportValues) => {
  return APIRequest({
    url: `map/reportresult/`,
    method: "POST",
    data: reportValues,
  });
};

const getReportLink = (reportValues) => {
  return APIRequest({
    url: `map/reportoutput/`,
    method: "POST",
    data: reportValues,
  });
};

const mapQueryPolygon = (layerlist, geometry) => {
  return APIRequest({
    url: "map/selectpolygon/",
    method: "POST",
    data: layerlist,
  });
};

// const downloadPdf = (unique_id) => {
//   return APIRequest({
//     url: "asset/values",
//     method: "GET",
//     params: {
//       type: table,
//       value: searchBy,
//       query: query,
//     },
//   });
// };

const MapSearchService = {
  fetchDataByType,
  fetchDataByValue,
  fetchValueByAttribute,
  fetchDataByFilter,
  fetchDataInReport,
  generateReport,
  getReportLink,
  // fetchDataByAttribute,
  // fetchDataByAttribute,
  mapQueryPolygon,
  // downloadPdf
};
export default MapSearchService;
