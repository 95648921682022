import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Button from "../../containers/button/";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { deleteLayerCategory } from "./data/action";
import ConfirmBox from "../confirmation_box/";

class LayerCategoryListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmation: false,
      deleteId: 0
    };
  }

  onDeleteClick = layer_category_id => {
    this.setState({
      showDeleteConfirmation: true,
      deleteId: layer_category_id
    });
  };
  confirmDelete = () => {
    if (this.state.deleteId > 0) {
      this.props.deleteLayerCategory(this.state.deleteId);
      this.handleClose();
    }
  };
  handleClose = () => {
    this.setState({ showDeleteConfirmation: false });
  };
  render() {
    return (
      <React.Fragment>
        <ConfirmBox
          show={this.state.showDeleteConfirmation}
          action={this.confirmDelete}
          onHide={this.handleClose}
        />
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Label</th>
              <th>Value</th>
              <th className="col-md-2">Action</th>
            </tr>
          </thead>
          {this.props.layerCategories.data && (
            <tbody>
              {this.props.layerCategories.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.label}</td>
                    <td>{item.value}</td>
                    <td>
                      <NavLink
                        to={`/layercategory/edit/${item.layer_category_id}`}
                      >
                        <Button
                          text="Edit"
                          bsStyle="success"
                          bsSize="xsmall"
                          page="/layercategory"
                          type="update_permission"
                        />{" "}
                      </NavLink>
                      <Button
                        text="Delete"
                        onClick={() =>
                          this.onDeleteClick(item.layer_category_id)
                        }
                        bsStyle="danger"
                        bsSize="xsmall"
                        page="/layercategory"
                        type="delete_permission"
                      />{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    deleteLayerCategory: layer_category_id =>
      dispatch(deleteLayerCategory(layer_category_id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerCategoryListTable);
