import React, {Component} from "react";

import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class LoginRoute extends Component{
    constructor(props){
        super(props);

    }
    componentDidMount() {
        const a=this.props.path;
        // if (!this.props.isAuthenticated) {
        //     this.props.history.push("/login");
        // }
    }
    render() {
        const { component: Component, ...rest } = this.props;
        return (
          <Route
            {...rest}
            render={props =>
              !this.props.isAuthenticated ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: props.location }
                  }}
                />
              )
            }
          />
        );
      }
}
function mapStateToProps(state) {
    return {
      isAuthenticated: state.loginReducer.isAuthenticated
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {};
  }
  
  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LoginRoute)
  );