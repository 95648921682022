export const FETCHING = "FETCHING";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILED = "FETCH_FAILED";
export const VALUE_FETCHING = "VALUE_FETCHING";
export const VALUE_FETCH_SUCCESS = "VALUE_FETCH_SUCCESS";
export const VALUE_FETCH_FAILED = "VALUE_FETCH_FAILED";
export const FETCHING_LAYER = "FETCHING_LAYER";
export const FETCHING_LAYER_SUCCESS = "FETCHING_LAYER_SUCCESS";

export const UPDATE_SEARCH_LAYER = "UPDATE_SEARCH_LAYER";

export const TOGGLE_ADVANCED_FILTER_WINDOW = "TOGGLE_ADVANCED_FILTER_WINDOW";
