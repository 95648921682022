import React, { Component } from "react";
import { NavItem, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Toolbar.css";

class ToolbarMenus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <NavItem id="toolnav" eventKey={2} style={{ marginRight: "25px" }}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="menu">{this.props.tooltipText}</Tooltip>}
        >
          <button className="toolIcons" onClick={this.props.handleClick}>
            <Image className="iconStyle" src={this.props.img} />
          </button>
        </OverlayTrigger>
        {/* <p className="tooltext">{this.props.tooltipText}</p> */}
      </NavItem>
    );
  }
}

export default ToolbarMenus;
