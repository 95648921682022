import {
  ACCESSIBLE_LAYER_REQUEST,
  ACCESSIBLE_LAYER_FAILURE,
  ACCESSIBLE_LAYER_SUCCESS,
} from "./constants";
import { loadingActions } from "../../../../../loader/data/action";
import { snackbarActions } from "../../../../../snackbar/data/action";
import LayerService from "../../../../../../service/LayerService";

export const accessibleLayers = (layerlist, geometry, featureGeometry) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    LayerService.accessibleLayers(layerlist, geometry, featureGeometry).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(response));
        } else {
          dispatch(failure(response));
          dispatch(snackbarActions.showSnackbar("Something went wrong"));
        }
        dispatch(loadingActions.loadingComplete());
      }
    );
    function request() {
      return { type: ACCESSIBLE_LAYER_REQUEST };
    }
    function success(response) {
      return { type: ACCESSIBLE_LAYER_SUCCESS, response };
    }
    function failure(error) {
      return { type: ACCESSIBLE_LAYER_FAILURE, error };
    }
  };
};
