import React, { Component } from "react";
import Button from "../../../containers/button/";
import UserListTable from "./UserListTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchUsers } from "../master/data/action";
import { setPageHeader } from "../../../containers/page_layout/data/action";
import { snackbarActions } from "../../snackbar/data/action";
import TablePagination from "../../pagination";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      page: 1,
      limit: 10,
      recordCount: 0
    };
  }
  componentDidMount() {
    this.props.setPageHeader(
      "User",
      "List",
      <Button
        text="Add User"
        className="pull-right"
        onClick={() => this.props.history.push("/user/add/")}
        bsStyle="primary"
        page="/user"
        type="add_permission"
      />
    );
    this.props.fetchUsers(this.state.page, this.state.limit);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userList != this.props.userList) {
      this.setState({
        userList: this.props.userList,
        recordCount: this.props.recordCount
      });
    }
    if (prevState.page != this.state.page) {
      this.props.fetchUsers(this.state.page, this.state.limit);
    }
    if (this.deleteSuccess != this.props.deleteSuccess) {
      this.props.showSnackbar("Deleted Successfully..!");
      // this.props.fetchUsers(this.state.page, this.state.limit);
    }
  }

  onPageChange = page => {
    if (!isNaN(page))
      this.setState({
        page: page
      });
  };
  render() {
    return (
      <React.Fragment>
        <UserListTable userList={this.state.userList} />
        <TablePagination
          recordCount={this.props.recordCount}
          page={this.state.page}
          limit={this.state.limit}
          onClick={e => this.onPageChange(e.target.text)}
          onNext={() => this.onPageChange(this.state.page + 1)}
          onPrev={() => this.onPageChange(this.state.page - 1)}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.userReducer.fetching,
    userList: state.userReducer.userList,
    deleteSuccess: state.userReducer.deleteSuccess,
    recordCount: state.userReducer.recordCount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (page, limit) => dispatch(fetchUsers(page, limit)),
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    showSnackbar: snackbarMessage =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage))
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
