import React, { Component } from "react";
import { transform } from "ol/proj.js";
import { connect } from "react-redux";
import "./CurrentLocation.css";
import mapDefaults from "../../helper/mapDefaults";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import TileJSON from "ol/source/TileJSON.js";
import VectorSource from "ol/source/Vector.js";
import { Icon, Style } from "ol/style.js";
import draw from "./images/draw.png";
import { Fill, Stroke, Circle } from "ol/style.js";
import styleFunction from "../../helper/vectorStyle";
import pin from "../current_location/images/pin.png";

class DefaultExtend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vectersource: null
    };
  }
  componentDidMount() {
    var iconStyle = new Style({
      image: new Icon(
        /** @type {module:ol/style/Icon~Options} */ ({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: "https://openlayers.org/en/v4.6.4/examples/data/icon.png"
        })
      )
    });
    this.setState({
      vectersource: new VectorLayer({
        style: iconStyle,
        source: new VectorSource()
      })
    });
  }
  findPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setToCurrentPosition);
    }
  };
  setToCurrentPosition = position => {
    const { vectersource } = this.state;
    const { mapComponent } = this.props;
    console.log(position.coords.longitude + "___" + position.coords.latitude);
    var iconFeature = new Feature({
      geometry: new Point(
        transform(
          [position.coords.longitude, position.coords.latitude],
          "EPSG:4326",
          "EPSG:3857"
        )
      )
    });
    var iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: pin,
        scale: 0.5
      })
    });
    iconFeature.setStyle(iconStyle);
    var vectorSource = new VectorSource({
      features: [iconFeature]
    });
    var vectorSource = new VectorSource({
      features: [iconFeature]
    });

    var vectorLayer = new VectorLayer({
      source: vectorSource,
      wfsstyle: new Style({
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 1.0)",
          width: 2
        })
      })
    });
    mapComponent.addLayer(vectorLayer);
    mapComponent
      .getView()
      .setCenter(
        transform(
          [position.coords.longitude, position.coords.latitude],
          "EPSG:4326",
          "EPSG:3857"
        )
      );
    mapComponent.getView().setZoom(13);
  };
  render() {
    return (
      <button className="currentLocationButton" onClick={this.findPosition}>
        <i className="fa fa-map-marker" />
      </button>
    );
  }
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultExtend);
