import {
  GET_UPDATE_REPORT_SUCCESS,
  GET_UPDATE_REPORT_FAILED,
} from "./constants";
const initialState = {
  updateReport: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_UPDATE_REPORT_SUCCESS:
      return {
        ...initialState,
        updateReport: action.response.data,
      };
    case GET_UPDATE_REPORT_FAILED:
      return {
        ...initialState,
        updateReport: null,
      };

    default:
      return state;
  }
}
