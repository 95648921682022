import React , {Component} from "react";
import {
    Form,
    Col,
    ControlLabel,
    FormGroup,
    FormControl,
    Button
  } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDropdownData } from "../dropdown/data/action";
import dropdownTypes from "../dropdown/dropdownTypes";


class LBDropDown extends Component{
    constructor(props){
        super(props);
        this.state={
            value:null
        }
    }
    componentDidMount(){
        this.props.getDropdownData(dropdownTypes.LOCAL_BODY);
    }
    handleInputChange = event => {
        this.setState({
            value:event.target.value
        })
        this.props.onLocalBodyChange(event.target.value);
    };
    render() {
        return (
            <Col xs={12} sm={2} md={2} lg={2}>
          <FormGroup>
            <FormControl
              componentClass="select"
              value={this.state.value}
              name="local_body_id"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              {this.props.localBodies.map(localBody => {
                return (
                  <option value={localBody.id} key={localBody.id}>
                    {localBody.value}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        );
    }
}
function mapStateToProps(state) {
    return {
      localBodies: state.dropdown.localBodies
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getDropdownData: type => dispatch(getDropdownData(type))
    };
  }
  
  export default withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LBDropDown)
  );