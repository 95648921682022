import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "./constants";
const initialState = {
  showSnackbar: false,
  snackbarMessage: ""
};
export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        showSnackbar: true,
        snackbarMessage: action.snackbarMessage
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
        snackbarMessage: ""
      };
    default:
      return state;
  }
}
