import React, { Component } from "react";
import { connect } from "react-redux";
import "./MapHeader.css";
import { Jumbotron } from "react-bootstrap";
import { doLogout } from "../../user/login/data/actions";
import { toggleAdvancedFilterWindow } from "../map_header/navigation/navbar_form/data/action";
// import MenuItems from "./navigation/navbar_menus/MenuItems";
import NavBarFormNew from "./navigation/navbar_form/NavBarForm";
// import NavBarForm from "./navigation/navbar_form/NavBarForm";
import AdvancedFilter from "../map_header/advanced_filter/AdvancedFilter";
// import LocalStorageHandler from "../../../util/storage";

class MapHeaderNew extends Component {
  render() {
    return (
      <div>
        <Jumbotron className="jumbobox">
          <div>
            <NavBarFormNew />
          </div>
          <AdvancedFilter />
        </Jumbotron>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    show: state.mapSearch.showAdvancedfilterWindow,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(doLogout()),
    toggleAdvancedFilterWindow: () => dispatch(toggleAdvancedFilterWindow()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapHeaderNew);
