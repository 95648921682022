export const GET_LAYERS = "GET_LAYERS";
export const GET_LAYERS_SUCCESS = "GET_LAYERS_SUCCESS";
export const GET_LAYERS_FAILED = "GET_LAYERS_FAILED";

export const ADD_LAYER = "ADD_LAYER";
export const ADD_LAYER_SUCCESS = "ADD_LAYER_SUCCESS";
export const ADD_LAYER_FAILED = "ADD_LAYER_FAILED";

export const FETCHING_LAYER = "FETCHING_LAYER";
export const FETCH_LAYER_SUCCESS = "FETCH_LAYER_SUCCESS";
export const FETCH_LAYER_FAILED = "FETCH_LAYER_FAILED";

export const DELETE_LAYER = "DELETE_LAYER";
export const DELETE_LAYER_SUCCESS = "DELETE_LAYER_SUCCESS";
export const DELETE_LAYER_FAILED = "DELETE_LAYER_FAILED";
