import React, { Component } from "react";
import "./GoToXY.css";
import gotoxyred from "../iconimages/measure.svg";
import GoToXYPanel from "./GoToXYPanel";
import { connect } from "react-redux";
import { updateWidgetEnable } from "../../map_component/data/actions";
import Tools from "../../../../../containers/tools/Tools";
const widgets = "Go To Location";
class GoToXY extends Component {
  handleShowPanelControl = () => {
    this.props.updateWidgetEnable(widgets, this.props.showWidget);
  };
  render() {
    return (
      <React.Fragment>
        <Tools
          tooltip={widgets}
          styles="gotoXY"
          functions={this.handleShowPanelControl}
          icon={gotoxyred}
          visibleFlag={true}
        />
        {this.props.showWidget === widgets && <GoToXYPanel />}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    showWidget: state.mapReducer.showWidget,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateWidgetEnable: (showWidget, preWidget) =>
      dispatch(updateWidgetEnable(showWidget, preWidget)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(GoToXY);
