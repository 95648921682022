import React, { Component } from "react";
import "./ChatBot.css";

class UserMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  render() {
    return (
      <div className="messages">
        {this.props.chatMessages.map((item) => {
          if (item.user == true) {
            return (
              <div className="message-container">
                <div className="user-message">{item.userMessage}</div>
              </div>
            );
          } else {
            return (
              <div className="message-container">
                <div className="bot-message">{item.botMessage}</div>
              </div>
            );
          }
        })}
        <div
          style={{ float: "left", clear: "both" }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}
export default UserMessage;
