//Local
// export const BASE_URL = "http://localhost:8000/";
// export const ADMIN_URL = "http://localhost:8000/";
// //DEV
// export const BASE_URL = "http://dev.kwa.kerala.gov.in:6070/";
// export const ADMIN_URL = "http://dev.kwa.kerala.gov.in:6070/";
// //KWA SDC SERVER
// export const BASE_URL = "http://117.239.77.85:6070/";
// export const ADMIN_URL = "http://117.239.77.85:6070/";
//QA
// export const BASE_URL = "http://gis.kwa.kerala.gov.in:6070/";
// export const ADMIN_URL = "http://gis.kwa.kerala.gov.in:6070/";

//DEMO
//PROD
export const BASE_URL = "https://dev.kwa.kerala.gov.in/";
export const ADMIN_URL = "https://dev.kwa.kerala.gov.in/";
