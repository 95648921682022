import { GET_DROPDOWN_DATA_FAILURE } from "./constants";
import { loadingActions } from "../../../components/loader/data/action";
import { snackbarActions } from "../../../components/snackbar/data/action";
import dropDownService from "../../../service/DropdownService";
import { getActionByComboType } from "../dropdown";

export const getDropdownData = (type) => {
  let dropdown = getActionByComboType(type);
  return (dispatch) => {
    loadingActions.isloading();
    dropDownService.getDropdownData(dropdown.url).then((response) => {
      if (response.data) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar(response.response.data.error));
      }
      dispatch(loadingActions.loadingComplete());
    });
  };
  function success(response) {
    return { type: dropdown.action, response };
  }
  function failure(error) {
    return { type: GET_DROPDOWN_DATA_FAILURE, error };
  }
};
