import React, { Component } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  Alert,
  Glyphicon,
  Checkbox,
} from "react-bootstrap";
// import Recaptcha from "react-recaptcha";
import localStorageHandler from "../../../util/storage/";
import { connect } from "react-redux";
import { doLogin } from "../login/data/actions";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import ForgotPasswordModel from "../forgot_password/ForgotPasswordModel";
import "./Login.css";
import Snackbar from "../../snackbar";
import { snackbarActions } from "../../snackbar/data/action";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      show: false,
      isVerified: false,
    };
  }
  handleForgotModel = () => {
    this.setState({ show: !this.state.show });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  validateForm = () => {
    return this.state.username.length > 0 && this.state.password.length > 0;
  };

  recaptchaLoaded = () => {
    console.log("capcha successfully loaded");
  };

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const userCredentials = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.doLogin(userCredentials);
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/map");
    }
  }

  // componentDidMount() {
  //   const userCredentials = {
  //     "X-username": "vdk_planning",
  //     "X-password": "Vadakara@123",
  //   };
  //   this.props.doLogin(userCredentials);
  // }

  componentDidUpdate(prevprops) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevprops.isAuthenticated
    ) {
      localStorageHandler.setLoginResponse(this.props.userInfo);
      this.props.history.push("/map");
    }
  }
  render() {
    return (
      // <div className="loginContainer">
      //   <Loader />
      //   <div className="withoutLogin">
      //     <div className="withoutappTitle">Loading...</div>
      //   </div>
      // </div>
      <div className="loginContainer">
        <Loader />
        <ForgotPasswordModel
          show={this.state.show}
          handleForgotModel={this.handleForgotModel}
        />
        <Snackbar />
        <div className="login">
          <div className="appTitle">KWA</div>
          <form onSubmit={this.handleSubmit} className="loginFormTwo">
            <FormGroup controlId="username" bsSize="large">
              <FormControl
                autoFocus
                type="text"
                placeholder="Username"
                value={this.state.username}
                onChange={this.handleChange}
                className="loginText"
              />
              <FormControl.Feedback>
                <Glyphicon glyph="user" />
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                placeholder="Password"
                className="loginText"
                autoComplete="off"
              />
              <FormControl.Feedback>
                <Glyphicon glyph="lock" />
              </FormControl.Feedback>
            </FormGroup>
            {(this.props.loginFailed || this.props.serverUnavilable) && (
              <p style={{ color: "red" }}>
                <strong>{this.props.message}</strong>
              </p>
            )}
            <FormGroup>
              <Row>
                <Col xs={12}>
                  <Button
                    block
                    type="submit"
                    bsSize="large"
                    bsStyle="success"
                    disabled={!this.validateForm()}
                    className="loginButton"
                  >
                    {this.props.loggingIn && !this.props.loginFailed ? (
                      <span>
                        Logging in... &nbsp;
                        <i className="fa fa-spinner fa-pulse fa-fw" />
                      </span>
                    ) : (
                      <span> Login</span>
                    )}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={6}
                  // className="remember"
                  style={{
                    top: "10px",
                    justifyContent: "center",
                    display: "flex",
                    color: "#2B2B2B",
                  }}
                >
                  <Checkbox inline>Remember Me</Checkbox>
                </Col>
                <Col
                  xs={6}
                  // className="forgot"
                  style={{
                    top: "10px",
                    justifyContent: "center",
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <a
                    style={{
                      top: "10px",
                      right: "5px",
                      color: "#204B84 ",
                      textDecoration: "none",
                    }}
                    onClick={this.handleForgotModel}
                  >
                    Forgot Password?
                  </a>
                </Col>
              </Row>
            </FormGroup>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggingIn: state.loginReducer.loggingIn,
    isAuthenticated: state.loginReducer.isAuthenticated,
    userInfo: state.loginReducer.userInfo,
    loginFailed: state.loginReducer.loginFailed,
    message: state.loginReducer.message,
    serverUnavilable: state.loginReducer.serverUnavilable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    doLogin: (userCredentials) => dispatch(doLogin(userCredentials)),
    showSnackbar: (snackbarMessage) =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
