import { ButtonToolbar, Modal, Panel, Button } from "react-bootstrap";
import React, { Component } from "react";
import "./ResultModel.css";
import closebtn from "../../../widgets/iconimages/close_icon.svg";

class ResultModel extends Component {
  handleResponse() {
    let properties = {};
    let filterdedProps = {};
    console.log(this.props.result);
    return this.props.result.map((res) => (
      <h4>
        {res.layer_label} : {res.count}
      </h4>
    ));
  }

  render() {
    const count = this.handleResponse();
    return (
      <Modal className="resultModal" show={this.props.show}>
        <Panel bsStyle="primary" className="resultModalPanel">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Selected Features</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closebtn}
                className="panelheadbtn"
                onClick={this.props.handleShowResult}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body className="panelBody">
            <p>{count}</p>
            <Button
              bsStyle="danger"
              className="resultbutton"
              onClick={this.props.handleShowResult}
            >
              OK
            </Button>
          </Panel.Body>
        </Panel>
      </Modal>
    );
  }
}
export default ResultModel;
