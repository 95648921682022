import React, { Component } from "react";
import { Link } from "react-router-dom";
class MenuItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { userMenu } = this.props;
    return (
      <ul id="menu-content" className="menu-content collapse out">
        {userMenu.map((userMenuItem) => {
          console.log("----userMenuItem----", userMenuItem);
          return (
            <React.Fragment key={userMenuItem.menu_id}>
              {userMenuItem.sub_menus.length > 0 && (
                <li
                  data-toggle={
                    userMenuItem.sub_menus.length > 0 ? "collapse" : ""
                  }
                  data-target={
                    userMenuItem.sub_menus.length > 0
                      ? "#".concat(userMenuItem.menu_name).replace(/ /g, "")
                      : ""
                  }
                  className={
                    userMenuItem.sub_menus.length > 0 ? "collapsed" : ""
                  }
                  key={userMenuItem.menu_id}
                >
                  <a href="#">
                    <span className="nav-label">{userMenuItem.menu_name}</span>
                    {userMenuItem.sub_menus.length > 0 && (
                      <span className="arrow" />
                    )}
                  </a>
                </li>
              )}
              {/* to collaps ad class collapse */}
              <ul
                className="sub-menu"
                id={userMenuItem.menu_name.replace(/ /g, "")}
              >
                {userMenuItem.sub_menus.length > 0 &&
                  userMenuItem.sub_menus.map((subMenuItem, index) => {
                    return (
                      <Link key={index} to={subMenuItem.menu_url}>
                        <li className="active" key={subMenuItem.menu_id}>
                          {subMenuItem.menu_name}
                        </li>
                      </Link>
                    );
                  })}
              </ul>
            </React.Fragment>
          );
        })}
      </ul>
    );
  }
}

export default MenuItems;
