import { SET_PAGE_HEADER } from "./constants";
export const setPageHeader = (title, subTitle, action) => {
  return dispatch => {
    let headers = {
      title: title,
      subTitle: subTitle,
      action: action
    };
    dispatch(setPageHeader(headers));
  };
  function setPageHeader(headers) {
    return { type: SET_PAGE_HEADER, headers };
  }
};

