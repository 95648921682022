import {
  PASSWORD_CHANGING,
  PASSWORD_CHANGING_SUCCES,
  PASSWORD_CHANGING_FAILED,
} from "./constants";
import { loadingActions } from "../../../loader/data/action";
import { snackbarActions } from "../../../snackbar/data/action";
import UserService from "../../../../service/UserService";
export const changePassword = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    dispatch(request(data));
    UserService.changePassword(data).then((response) => {
      if (response.status === 200) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(snackbarActions.showSnackbar("Something went wrong"));
      }
      dispatch(loadingActions.loadingComplete());
    });
    function request() {
      return { type: PASSWORD_CHANGING };
    }
    function success(response) {
      return { type: PASSWORD_CHANGING_SUCCES, response };
    }
    function failure(error) {
      return { type: PASSWORD_CHANGING_FAILED, error };
    }
  };
};
