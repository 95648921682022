import React, { Component } from "react";
import { connect } from "react-redux";
import getLayerTree from "../../helper/LayerTreeLoader";
import "./GetCountPanel.css";
import ToolsPopupLayout from "../../../../../containers/tools_popup_layouy/ToolsPopupLayout";
class GetCountPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layers: getLayerTree(),
    };
  }
  render() {
    return (
      <ToolsPopupLayout
        widgetName="Get count"
        panelheading="Layer Count"
        collapseStyle="getCountPanels"
        panelHeadBtn="panelheadbtn"
        panelBodyStyle="getCountPanelsBody"
      >
        <ul className="listStyleUl">
          {this.state.layers.map((parent, index) => (
            <li key={index}>
              <b>{parent.label}</b>
              <ul className="listStyleLi">
                {parent.children.map((child, i) => (
                  <li key={i} className="listStyleLi">
                    {child.label} - {child.count}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </ToolsPopupLayout>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(GetCountPanel);
