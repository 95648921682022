import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./Filter.css";
import {
  Modal,
  Panel,
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  Row,
  Checkbox,
} from "react-bootstrap";
import { toggleAdvancedFilterWindow } from "../../map_viewer/map_header/navigation/navbar_form/data/action";
import getAttributeName from "../../../util/attributes";
import closebtn from "../../map_viewer/map/widgets/iconimages/close_icon.svg";

import FilterForm from "../../map_viewer/map_header/advanced_filter/components/FilterForm";

class Attribute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkedStatus = (name) => {
    const { parameters } = this.props;
    let status = false;
    if (parameters.length != 0) {
      parameters.map((item, index) => {
        if (item.display_name == name) {
          status = true;
        }
      });
    }
    return status;
  };

  attributeList = () => {
    return this.props.data.layer.advancefilterinfo
      .sort((a, b) => (a["display_name"] > b["display_name"] ? 1 : -1))
      .map((column, index) => {
        if (
          !(
            this.props.data.layer.layer_id == 2 &&
            column.model_name == "Establishment"
          ) &&
          !(
            this.props.data.layer.layer_id != 2 &&
            (column.model_name == "Member_Details" ||
              column.model_name == "Property_Other_Details" ||
              column.model_name == "Member_Diseases")
          )
        ) {
          return (
            <Checkbox
              value={column.display_name}
              key={index}
              checked={this.checkedStatus(column.display_name)}
              onChange={(event) =>
                this.props.handleInputChangeSelect(event, index)
              }
            >
              {column.display_name}
            </Checkbox>
          );
        }
      });
  };

  render() {
    const { data, layerColumns, showAttri, closeAttri } = this.props;

    return (
      <Modal show={this.props.show} className="filterFilterModal">
        <Panel bsStyle="primary" className="filterFilterPanel">
          <Panel.Heading>
            <Panel.Title componentClass="h3">Filter</Panel.Title>
            <ButtonToolbar className="pull-right button">
              <img
                src={closebtn}
                className="panelheadbtn"
                onClick={closeAttri}
              />
            </ButtonToolbar>
          </Panel.Heading>
          <Panel.Body className="panelBody">
            {this.props.show && this.attributeList()}
          </Panel.Body>
          <Panel.Footer className="panelFooter">
            <Button
              bsStyle="success"
              style={{ marginLeft: "3px", left: "5px" }}
              onClick={this.props.handleInputSelectAll}
            >
              Select All
            </Button>
            <Button
              bsStyle="success"
              style={{ marginLeft: "3px", left: "5px" }}
              onClick={closeAttri}
            >
              OK
            </Button>
          </Panel.Footer>
        </Panel>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    // show: state.mapSearch.showAdvancedfilterWindow
    layerColumns: state.advancedFilter.layerColumns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // hide: () => dispatch(toggleAdvancedFilterWindow())
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Attribute)
);
