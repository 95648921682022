import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
  } from "./constants";
  import { loadingActions } from "../../../../components/loader/data/action";
  import userService from "../../../../service/UserService";

  export const doForgotPassword = usernameOremail => {
    return dispatch => {
      dispatch(loadingActions.isloading());
      dispatch(request());
      userService.doForgotPassword(usernameOremail).then(
        response => {
          if (response.data) {
            dispatch(success(response));
            dispatch(loadingActions.loadingComplete());
          } else {
            dispatch(failure(response));
            dispatch(loadingActions.loadingComplete());
          }
        },
        error => {
          dispatch(failure(error));
          dispatch(loadingActions.loadingComplete());
        }
      );
    };
    function request() {
      return { type: FORGOT_PASSWORD_REQUEST };
    }
    function success(response) {
      return { type: FORGOT_PASSWORD_SUCCESS, response };
    }
    function failure(error) {
      return { type: FORGOT_PASSWORD_FAILURE, error };
    }
  };
  export const reasetPassword = data => {
    return dispatch => {
      dispatch(loadingActions.isloading());
      dispatch(request());
      userService.reasetPassword(data).then(
        response => {
          if (response.data) {
            dispatch(success(response));
            dispatch(loadingActions.loadingComplete());
          } else {
            dispatch(failure(response));
            dispatch(loadingActions.loadingComplete());
          }
        },
        error => {
          dispatch(failure(error));
          dispatch(loadingActions.loadingComplete());
        }
      );
    };
    function request() {
      return { type: RESET_PASSWORD_REQUEST };
    }
    function success(response) {
      return { type: RESET_PASSWORD_SUCCESS, response };
    }
    function failure(error) {
      return { type: RESET_PASSWORD_FAILURE, error };
    }
  };