import APIRequest from "../util/api/APIRequest";

const foreignKeyInfo = (foreignKeyDeatails) => {
  return APIRequest({
    url: "map/getrelateddata/",
    method: "POST",
    data: foreignKeyDeatails,
  });
};

const spatialQueryData = (queryData) => {
  return APIRequest({
    url: "map/spatialquery/",
    method: "POST",
    data: queryData,
  });
};

const LayerInfoService = {
  foreignKeyInfo,
  spatialQueryData,
};
export default LayerInfoService;
