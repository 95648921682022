import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";
import { snackbarActions } from "./data/action";

class SnackBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevprops) {
    if (this.props.showSnackbar)
      setTimeout(() => {
        this.props.hideSnackbar();
      }, 4000);
  }

  render() {
    const { showSnackbar, snackbarMessage } = this.props;
    return showSnackbar ? <div id="snackbar">{snackbarMessage}</div> : null;
  }
}

function mapStateToProps(state) {
  return {
    showSnackbar: state.snackbar.showSnackbar,
    snackbarMessage: state.snackbar.snackbarMessage
  };
}
function mapDispatchToProps(dispatch) {
  return {
    hideSnackbar: () => dispatch(snackbarActions.hideSnackbar())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackBar);
