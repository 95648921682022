import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SERVER_UNAVAILABLE,
} from "./constants";
import { loadingActions } from "../../../../components/loader/data/action";
import authenticationService from "../../../../service/AuthenticationService";
import localStorageHandler from "../../../../util/storage/";
import { Redirect } from "react-router-dom";
import { BASE_URL, ADMIN_URL } from "../../../../config";

export const doLogin = (userCredentials) => {
  return (dispatch) => {
    dispatch(loadingActions.isloading());
    localStorageHandler.removeSession();
    dispatch(request(userCredentials));

    authenticationService.login(userCredentials)
    .then((response) => {
        if (response.data.status==200) {
          dispatch(success(response));
          dispatch(loadingActions.loadingComplete());
        } else if (response.data.status==401) {
          dispatch(loginfailure(response));
          dispatch(loadingActions.loadingComplete());
        }               
      })      
      .catch((error) => {
        dispatch(failure(error));
        dispatch(loadingActions.loadingComplete());
      })
    
  };

  function request(userCredentials) {
    return { type: LOGIN_REQUEST, userCredentials };
  }
  function success(response) {
    return { type: LOGIN_SUCCESS, response };
  }
  function failure(error) {
    return { type: SERVER_UNAVAILABLE, error };
  }
  function loginfailure(response){
    return { type: LOGIN_FAILURE, response };
  }
};

export const doLogout = () => {
  return (dispatch) => {
    // authenticationService.logout().then(response => {
    localStorageHandler.removeSession();
    dispatch(lgoutAction());
    window.location.reload(ADMIN_URL + `map/logout/`);
    // });
  };

  function lgoutAction() {
    return { type: LOGOUT };
  }
};
export const doLogoutFomAdmin = () => {
  return (dispatch) => {
    console.log("logout");
    localStorageHandler.removeSession();
    dispatch(lgoutAction());
    window.location.href = "/";
  };
  function lgoutAction() {
    return { type: LOGOUT };
  }
};
