import{
    PASSWORD_CHANGING,
    PASSWORD_CHANGING_SUCCES,
    PASSWORD_CHANGING_FAILED
} from "./constants";
const initialState={
    message : "",
    apiStatus : false
};
export default function(state = initialState, action) {
    switch (action.type) {
        case PASSWORD_CHANGING:
        return {
          ...initialState
        };
        case PASSWORD_CHANGING_SUCCES:
        return {
          ...initialState,
          message:action.response.data.message,
          apiStatus:true
        };
        case PASSWORD_CHANGING_FAILED:
        return {
            ...initialState
        };
        default:
            return state;
    }
}
