const LAYER = "LAYER";
const LAYER_CATEGORY = "LAYER_CATEGORY";
const ROLE = "ROLE";
const LOCAL_BODY = "LOCAL_BODY";
const MAIN_MENU = "MAIN_MENU";

const dropdownTypes = {
  LAYER,
  LAYER_CATEGORY,
  ROLE,
  LOCAL_BODY,
  MAIN_MENU
};
export default dropdownTypes;
