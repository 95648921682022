import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import jsPDF from "jspdf";
import { transform } from "ol/proj.js";
import { toStringHDMS } from "ol/coordinate";
import {
  Collapse,
  Row,
  Col,
  Grid,
  Panel,
  Button,
  ButtonToolbar,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import LocalStorageHandler from "../../../../../util/storage";
import "./ExportPdfPanel.css";
import compass from "./images/compass.png";
import emblem from "./images/emblem.png";
import ulccs_emblem from "./images/ULCCS emblem.png";
import closebtn from "../iconimages/close_icon.svg";
import getLayerTree from "../../helper/LayerTreeLoader";
class ExportPdfPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: "a3",
      resolution: "72",
      layout: "L1",
      heading: "",
      subHeading: "",
      legendUrls: [],
      selectedLayers: [],
    };
    this.pdf = null;
  }
  handleChange = (e, validation) => {
    var field = {};
    if (
      validation &&
      validation.maxLength &&
      e.target.value.length > validation.maxLength
    )
      field[e.target.name] = this.state[e.target.name];
    else field[e.target.name] = e.target.value;
    this.setState(field);
  };
  // handleChangePageSize = (e) => {
  //   this.setState({ pageSize: e.target.value });
  // };
  // handleChangeLayout = (e) => {
  //   this.setState({ layout: e.target.value });
  // };
  // handleChangeHeading = (e) => {
  //   this.setState({ heading: e.target.value });
  // };
  // handleChangeSubheading = (e) => {
  //   this.setState({ subHeading: e.target.value });
  // };
  // handleChangeResolution = (e) => {
  //   this.setState({ resolution: e.target.value });
  // };
  handleExportPdf = () => {
    this.handleMapRenderCompiete(this.props.renderEvent);
  };
  getBase64Image(pdf) {
    let images = [];
    const legendUrls = this.getLegendUrls();
    legendUrls.map((item) => {
      axios(item.url, {
        responseType: "arraybuffer",
      }).then((response) => {
        let imgString = new Buffer(response.data, "binary").toString("base64");
        let image = "data:image/jpeg;base64,".concat(imgString);
        images.push(image);
        pdf.addImage(image, "PNG", 16, 170, 50, 30);
        // pdf.save("map.pdf");
      });
    });
  }

  urlTobase64(url) {
    axios(url, {
      responseType: "arraybuffer",
    }).then((response) => {
      let imgString = new Buffer(response.data, "binary").toString("base64");
      let image = "data:image/jpeg;base64,".concat(imgString);
      // images.push(image);
      // pdf.addImage(image, "JPEG", 16, 170, 50, 30);
      return image;
    });
  }
  toDegreesLong(coord) {
    // var out=[];
    var out1 = toStringHDMS(coord);
    var l = out1.length;
    var out = out1.substring(l / 2 + 1, l);
    var str = out.split(" ");
    var min_val = str[1].substring(0, 2);
    var sec_val = str[2].substring(0, 2);
    var newstr = str[0] + " " + min_val + "' " + sec_val + '" ' + str[3];
    return newstr;
  }
  toDegreesLat(coord) {
    var out1 = toStringHDMS(coord);
    var l = out1.length;
    var out = out1.substring(0, l / 2);
    var str = out.split(" ");
    var min_val = str[1].substring(0, 2);
    var sec_val = str[2].substring(0, 2);
    var newstr = str[0] + " " + min_val + "' " + sec_val + '" ' + str[3];
    return newstr;
  }
  //   getTextWidth(txt, font) {
  //     this.element = document.createElement('canvas');
  //     this.context = this.element.getContext("2d");
  //     this.context.font = font;
  //     var tsize = {'width':this.context.measureText(txt).width, 'height':parseInt(this.context.font)};
  //     return tsize;
  // };

  handleMapRenderCompiete = (event) => {
    const { mapComponent } = this.props;
    this.getLegendUrls();
    // var specs={
    //   "layout":this.state.pageSize + " " + this.state.orientation,
    //   "srs":"EPSG:4326",
    //   "units":this.state.unit,
    //   "bottomText":"ajmal",
    //   "dpi":this.state.resolution,
    //   "mapTitle":this.state.heading,
    //   "layers":[
    //     {
    //       "baseURL":"http://localhost:8080/geoserver/test/wms",
    //       "opacity":1.0,
    //       "singleTile":false,
    //       "type":"WMS",
    //       // "layers":this.props.selectedLayers,
    //       "format":"image/png"
    //     }
    //   ],
    //   "pages":[
    //     {
    //       "center":transform(mapComponent.getView().getCenter(),
    //           "EPSG:3857",
    //           "EPSG:4326"),
    //       "scale":this.state.scale,
    //       //  mapComponent.controls.array_[3],
    //       "comment":""
    //     }
    //   ]
    // };
    // alert(JSON.stringify(specs))
    var dims = {
      a0: [1189, 841],
      a1: [841, 594],
      a2: [594, 420],
      a3: [420, 297],
      a4: [297, 210],
      a5: [210, 148],
    };
    // alert(transform(mapComponent.getView().getCenter(),
    //   "EPSG:3857",
    //   "EPSG:4326"))
    //   alert(mapComponent.getView().getZoom())

    var format = this.state.pageSize;
    var resolution = this.state.resolution;
    var layout = this.state.layout;
    var heading = this.state.heading;
    var subheading = this.state.subHeading;
    var dim = dims[format];
    var width = Math.round((dim[0] * resolution) / 25.4);
    var height = Math.round((dim[1] * resolution) / 25.4);
    var size = /** @type {module:ol/size~Size} */ (mapComponent.getSize());
    var extent = mapComponent.getView().calculateExtent(size);
    var canvas = event.context.canvas;
    var data = canvas.toDataURL("image/jpeg");
    var pdf = new jsPDF("landscape", undefined, format, undefined);
    var textx,
      texty,
      compassx,
      compassy,
      compassw,
      compassh,
      imgx,
      imgy,
      imgw,
      imgh,
      size,
      legendx,
      legendy,
      srectx,
      srecty,
      srectw,
      srecth,
      stextx,
      stexty,
      crtextx,
      crtexty,
      crfont,
      sfont,
      xoffset,
      yoffset,
      ylimit,
      hrx,
      hry,
      hrw,
      hrh,
      scrx,
      scry,
      scrw,
      scrh,
      lgrx,
      lgry,
      lgrw,
      lgrh,
      lgfx,
      lgfy,
      lsgdrx,
      lsgdry,
      lsgdrw,
      lsgdrh,
      lsgdtx,
      lsgdty,
      ultsx,
      ultsy,
      borx,
      bory,
      borw,
      borh;
    switch (format) {
      case "a0": {
        textx = 570;
        texty = 35;
        compassx = 28;
        compassy = 40;
        compassw = 120;
        compassh = 120;
        imgx = 28;
        imgy = 40;
        imgw = 1035.1;
        imgh = 556.6;
        size = 35;
        legendx = 1130;
        legendy = 40;
        srectx = 28;
        srecty = 808;
        srectw = 60;
        srecth = 10;
        stextx = 44;
        stexty = 816;
        crtextx = 28;
        crtexty = 826;
        crfont = 30;
        sfont = 30;
        xoffset = 0;
        yoffset = 5;
        ylimit = 850;
        hrx = 18;
        hry = 15;
        hrw = 300;
        hrh = 30;
        scrx = 0;
        scry = 0;
        scrw = 0;
        scrh = 0;
        lgrx = 0;
        lgry = 0;
        lgrw = 0;
        lgrh = 0;
        lgfx = 0;
        lgfy = 0;
        lsgdrx = 0;
        lsgdry = 0;
        lsgdrw = 0;
        lsgdrh = 0;
        lsgdtx = 0;
        lsgdty = 0;
        ultsx = 0;
        ultsy = 0;
        break;
      }

      case "a3": {
        switch (layout) {
          case "L1": {
            textx = 205;
            texty = 35;
            compassx = 350.5;
            compassy = 15;
            compassw = 23;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 393;
            borh = 279;
            imgx = 21;
            imgy = 51;
            imgw = 382;
            imgh = 157;
            size = 25;
            legendx = 143;
            legendy = 219;
            srectx = 342;
            srecty = 41;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 40;
            crtextx = 15;
            crtexty = 294;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 300;
            hrh = 30;
            scrx = 321;
            scry = 15;
            scrw = 82;
            scrh = 30;
            lgrx = 141;
            lgry = 211;
            lgrw = 262;
            lgrh = 50;
            lgfx = 143;
            lgfy = 216;
            lsgdrx = 141;
            lsgdry = 264;
            lsgdrw = 262;
            lsgdrh = 22;
            lsgdtx = 160;
            lsgdty = 280;
            ultsx = 360;
            ultsy = 280;
            // offset = 5;
            break;
          }
          case "L2": {
            textx = 205;
            texty = 35;
            compassx = 350.5;
            compassy = 220;
            compassw = 23;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 393;
            borh = 279;
            imgx = 21;
            imgy = 51;
            imgw = 382;
            imgh = 157;
            size = 25;
            legendx = 143;
            legendy = 219;
            srectx = 342;
            srecty = 247;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 245;
            crtextx = 15;
            crtexty = 294;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 385;
            hrh = 30;
            scrx = 321;
            scry = 211;
            scrw = 82;
            scrh = 50;
            lgrx = 141;
            lgry = 211;
            lgrw = 177;
            lgrh = 50;
            lgfx = 143;
            lgfy = 216;
            lsgdrx = 141;
            lsgdry = 264;
            lsgdrw = 262;
            lsgdrh = 22;
            lsgdtx = 160;
            lsgdty = 280;
            ultsx = 360;
            ultsy = 280;
            // offset = 5;
            break;
          }
          case "L3": {
            textx = 205;
            texty = 35;
            compassx = 350.5;
            compassy = 15;
            compassw = 23;
            compassh = 23;
            borx = 14;
            bory = 11;
            borw = 393;
            borh = 279;
            imgx = 21;
            imgy = 51;
            imgw = 382;
            imgh = 157;
            size = 25;
            legendx = 143;
            legendy = 219;
            srectx = 342;
            srecty = 41;
            srectw = 40;
            srecth = 3;
            stextx = 358;
            stexty = 40;
            crtextx = 15;
            crtexty = 294;
            crfont = 10;
            sfont = 10;
            xoffset = 0;
            yoffset = 5;
            ylimit = 250;
            hrx = 18;
            hry = 15;
            hrw = 300;
            hrh = 30;
            scrx = 321;
            scry = 15;
            scrw = 82;
            scrh = 30;
            lgrx = 141;
            lgry = 211;
            lgrw = 177;
            lgrh = 50;
            lgfx = 143;
            lgfy = 216;
            lsgdrx = 141;
            lsgdry = 264;
            lsgdrw = 262;
            lsgdrh = 22;
            lsgdtx = 160;
            lsgdty = 280;
            ultsx = 360;
            ultsy = 280;
            pdf.setLineWidth(0.5);
            pdf.setDrawColor(0);
            pdf.rect(321, 211, 82, 50); //rectangle outside map
            pdf.setFontSize(15);
            pdf.setFont("Arial");
            pdf.text(323, 216, "Approved By: ");

            // offset = 5;
            break;
          }
        }

        break;
      }
      case "a4": {
        textx = 135;
        texty = 25;
        compassx = 20;
        compassy = 30;
        compassw = 35;
        compassh = 35;
        imgx = 16;
        imgy = 30;
        imgw = 200;
        imgh = 130;
        size = 15;
        legendx = 217;
        legendy = 30;
        srectx = 16;
        srecty = 155;
        srectw = 35;
        srecth = 5;
        stextx = 30;
        stexty = 159;
        crtextx = 16;
        crtexty = 163;
        crfont = 10;
        sfont = 10;
        xoffset = 0;
        yoffset = 5;
        ylimit = 850;
        hrx = 18;
        hry = 15;
        hrw = 300;
        hrh = 30;
        scrx = 0;
        scry = 0;
        scrw = 0;
        scrh = 0;
        lgrx = 0;
        lgry = 0;
        lgrw = 0;
        lgrh = 0;
        lgfx = 0;
        lgfy = 0;
        lsgdrx = 0;
        lsgdry = 0;
        lsgdrw = 0;
        lsgdrh = 0;
        lsgdtx = 0;
        lsgdty = 0;
        ultsx = 0;
        ultsy = 0;
        break;
      }
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var date = dd + "/" + mm + "/" + yyyy;
    // document.write(today);
    var text = LocalStorageHandler.getLocalBody().local_body_name;
    pdf.addImage(data, "JPEG", imgx, imgy, imgw, imgh); //Map Image
    pdf.setDrawColor(0);
    pdf.setLineWidth(1.0);
    pdf.rect(borx, bory, borw, borh); //rectangle for border
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0);
    pdf.rect(imgx, imgy, imgw, imgh); //rectangle outside map
    var xspace = imgw / 7;
    var yspace = imgh / 5;
    pdf.setFillColor(0);
    pdf.rect(imgx - 1, imgy - 1, 2, 2, "F"); //rectangle for scale
    pdf.setFillColor(0);
    pdf.rect(imgx + imgw / 2 - 1, imgy - 1, 2, 2, "F"); //small squares around map
    pdf.rect(imgx + imgw - 1, imgy - 1, 2, 2, "F");
    pdf.rect(imgx + imgw - 1, imgy + imgh / 2 - 1, 2, 2, "F");
    pdf.rect(imgx + imgw - 1, imgy + imgh - 1, 2, 2, "F");
    pdf.rect(imgx + imgw / 2 - 1, imgy + imgh - 1, 2, 2, "F");
    pdf.rect(imgx - 1, imgy + imgh - 1, 2, 2, "F");
    pdf.rect(imgx - 1, imgy + imgh / 2 - 1, 2, 2, "F");
    pdf.setDrawColor(0);
    pdf.line(imgx + xspace, imgy, imgx + xspace, imgy - 2); //small lines around map
    pdf.line(imgx + 2 * xspace, imgy, imgx + 2 * xspace, imgy - 2);
    pdf.line(imgx + 3 * xspace, imgy, imgx + 3 * xspace, imgy - 2);
    pdf.line(imgx + 4 * xspace, imgy, imgx + 4 * xspace, imgy - 2);
    pdf.line(imgx + 5 * xspace, imgy, imgx + 5 * xspace, imgy - 2);
    pdf.line(imgx + 6 * xspace, imgy, imgx + 6 * xspace, imgy - 2);
    //pdf.line(imgx+7*xspace,imgy,imgx+7*xspace,imgy-2);
    pdf.line(imgx, imgy + yspace, imgx - 2, imgy + yspace);
    pdf.line(imgx, imgy + 2 * yspace, imgx - 2, imgy + 2 * yspace);
    pdf.line(imgx, imgy + 3 * yspace, imgx - 2, imgy + 3 * yspace);
    pdf.line(imgx, imgy + 4 * yspace, imgx - 2, imgy + 4 * yspace);
    //pdf.line(imgx,imgy+5*yspace,imgx-2,imgy+5*yspace);
    pdf.setLineWidth(0.5);
    var topextent = [],
      bottomextent = [];
    topextent = transform(
      [mapComponent.frameState_.extent[0], mapComponent.frameState_.extent[1]],
      "EPSG:3857",
      "EPSG:4326"
    );
    bottomextent = transform(
      [mapComponent.frameState_.extent[2], mapComponent.frameState_.extent[3]],
      "EPSG:3857",
      "EPSG:4326"
    );
    // var topdegree = toStringHDMS(topextent);
    // var bottomdegree = toStringHDMS(bottomextent);
    // console.log(bottomdegree-topdegree);
    // var ns = ol.Util.getFormattedLonLat(point.lat, 'lat', 'dms');
    // console.log(topextent+":"+bottomextent);
    var latoffset = (bottomextent[1] - topextent[1]) / 5;
    var longoffset = (bottomextent[0] - topextent[0]) / 8;
    pdf.setTextColor(0);
    pdf.setFontSize(8);
    // pdf.addFont('Arial', 'Arial', 'normal');
    pdf.setFont("Arial");
    // this.toDegreesLong([topextent[0],topextent[1]]);

    // pdf.text(imgx-10,imgy-3,this.toDegreesLong([topextent[0],topextent[1]]));
    // pdf.text(imgx-3+xspace,imgy-3,((topextent[0]+longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3+2*xspace,imgy-3,((topextent[0]+2*longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3+3*xspace,imgy-3,((topextent[0]+3*longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3+4*xspace,imgy-3,((topextent[0]+4*longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3+5*xspace,imgy-3,((topextent[0]+5*longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3+6*xspace,imgy-3,((topextent[0]+6*longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3+7*xspace,imgy-3,((topextent[0]+7*longoffset).toFixed(4)).toString());
    // pdf.text(imgx-3,imgy+yspace+3,((topextent[1]+latoffset).toFixed(4)).toString(),null,90);
    // pdf.text(imgx-3,imgy+2*yspace+3,((topextent[1]+2*latoffset).toFixed(4)).toString(),null,90);
    // pdf.text(imgx-3,imgy+3*yspace+3,((topextent[1]+3*latoffset).toFixed(4)).toString(),null,90);
    // pdf.text(imgx-3,imgy+4*yspace+3,((topextent[1]+4*latoffset).toFixed(4)).toString(),null,90);
    // pdf.text(imgx-3,imgy+5*yspace+3,((topextent[1]+5*latoffset).toFixed(4)).toString(),null,90);
    pdf.text(
      imgx - 10 + xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 2 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 2 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 3 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 3 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 4 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 4 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 5 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 5 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 10 + 6 * xspace,
      imgy - 3,
      this.toDegreesLong([topextent[0] + 6 * longoffset, topextent[1]])
    );
    pdf.text(
      imgx - 3,
      imgy + yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 5 * latoffset]),
      null,
      90
    );
    pdf.text(
      imgx - 3,
      imgy + 2 * yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 4 * latoffset]),
      null,
      90
    );
    pdf.text(
      imgx - 3,
      imgy + 3 * yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 3 * latoffset]),
      null,
      90
    );
    pdf.text(
      imgx - 3,
      imgy + 4 * yspace + 10,
      this.toDegreesLat([topextent[0], topextent[1] + 2 * latoffset]),
      null,
      90
    );
    // pdf.text(imgx-3,imgy+5*yspace+10,this.toDegreesLat([topextent[0],(topextent[1]+latoffset)]),null,90);

    pdf.setDrawColor(0);
    pdf.rect(hrx, hry, hrw, hrh); //rectangle for headings
    pdf.setDrawColor(0);
    pdf.rect(scrx, scry, scrw, scrh); //rectangle for scale and compass
    pdf.setTextColor(0);
    pdf.setFontSize(30);
    // var heading = "Road Layer";
    // var hlen = heading.length;
    // var sizeh = this.getTextWidth(heading,"30pt Arial");
    pdf.text(heading, (hrw + hrx + hrx) / 2, hry + 15, "center");
    // var subheading = "Different roads in Mayyanad Panchayat";
    // var sizes = this.getTextWidth(subheading,"30pt Arial");
    pdf.setFontSize(20);
    pdf.text(subheading, (hrw + hrx + hrx) / 2, hry + 25, "center");
    pdf.setDrawColor(0);
    pdf.rect(lgrx, lgry, lgrw, lgrh); //rectangle for legends
    pdf.setTextColor(0);
    pdf.setFontSize(15);
    pdf.text(lgfx, lgfy, "Legend");
    pdf.rect(lsgdrx, lsgdry, lsgdrw, lsgdrh); //rectangle for LSGD
    pdf.setTextColor(0);
    pdf.setFontSize(12);
    pdf.text(lsgdtx + 50, lsgdty - 10, "Project :");
    pdf.setFontSize(14);
    pdf.text(
      lsgdtx,
      lsgdty,
      "INTELLIGENT PROPERTY MANAGEMENT SYSTEM - " + text.split(" ")[0]
    );
    pdf.setTextColor(0);
    pdf.setFontSize(12);
    pdf.text(ultsx - 6, ultsy - 10, "Developed by : ");
    pdf.setFontSize(14);
    pdf.text(ultsx, ultsy, "ULCCS LTD");
    var scale = mapComponent.controls.array_[3];
    pdf.setDrawColor(0);
    // pdf.setFillColor(49, 115, 173);
    // pdf.line(35, 200, 100, 200);
    pdf.rect(srectx, srecty, srectw, srecth); //rectangle for scale
    // pdf.setDrawColor(0);
    pdf.setFillColor(0); //scale
    var w = srectw / 4;
    pdf.rect(srectx, srecty, w, srecth, "F");
    pdf.rect(srectx + w, srecty, w, srecth);
    pdf.rect(srectx + 2 * w, srecty, w, srecth, "F");
    pdf.rect(srectx + 3 * w, srecty, w, srecth);
    pdf.setTextColor(0);
    pdf.setFontSize(sfont);
    pdf.text(stextx, stexty, scale.renderedHTML_);

    // var latlon = transform([mapComponent.frameState_.focus[0], mapComponent.frameState_.focus[1]], 'EPSG:3857', 'EPSG:4326');
    // var centre = toStringHDMS(latlon);
    // pdf.setTextColor(0);
    // pdf.setFontSize(12);
    // pdf.text(142,200, "Center : "+latlon);

    pdf.setTextColor(0);
    pdf.setFontSize(crfont);
    pdf.setFont("SimSun");
    pdf.text(crtextx, crtexty, "Copyright © 2020 " + text);
    pdf.text(380, 294, "Date : " + date);
    // pdf.setFont("Arial");

    var img1 = new Image();
    // img1.crossOrigin = 'null';
    img1.crossOrigin = "Anonymous";
    img1.src = compass;
    img1.onload = function () {
      var canvas1 = document.createElement("canvas"),
        ctx1 = canvas1.getContext("2d");
      canvas1.height = img1.naturalHeight;
      canvas1.width = img1.naturalWidth;
      ctx1.drawImage(img1, 0, 0);
      var imgData = canvas1.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", compassx, compassy, compassw, compassh);
      // pdf.addImage(scaleImg, 'PNG', 18, 1000, 40, 40);
    };

    var img2 = new Image();
    // img1.crossOrigin = 'null';
    img2.crossOrigin = "Anonymous";
    img2.src = "http://maps.ulgis.com/mapImages/" + text + ".PNG";
    img2.onload = function () {
      var canvas2 = document.createElement("canvas"),
        ctx2 = canvas2.getContext("2d");
      canvas2.height = img2.naturalHeight;
      canvas2.width = img2.naturalWidth;
      ctx2.drawImage(img2, 0, 0);
      pdf.setDrawColor(0);
      pdf.rect(18, 211, 120, 75); //rectangle for Insetmap
      var imgData = canvas2.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 19, 212, 118, 73);

      // pdf.addImage(scaleImg, 'PNG', 18, 1000, 40, 40);
    };

    var img3 = new Image();
    // img1.crossOrigin = 'null';
    img3.crossOrigin = "Anonymous";
    img3.src = emblem;
    img3.onload = function () {
      var canvas3 = document.createElement("canvas"),
        ctx3 = canvas3.getContext("2d");
      canvas3.height = img3.naturalHeight;
      canvas3.width = img3.naturalWidth;
      ctx3.drawImage(img3, 0, 0);
      var imgData = canvas3.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", lsgdtx - 12, lsgdty - 7, 10, 10);

      // pdf.addImage(scaleImg, 'PNG', 18, 1000, 40, 40);
    };
    var img4 = new Image();
    // img1.crossOrigin = 'null';
    img4.crossOrigin = "Anonymous";
    img4.src = ulccs_emblem;
    img4.onload = function () {
      var canvas4 = document.createElement("canvas"),
        ctx4 = canvas4.getContext("2d");
      canvas4.height = img4.naturalHeight;
      canvas4.width = img4.naturalWidth;
      ctx4.drawImage(img4, 0, 0);
      var imgData = canvas4.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", ultsx - 12, ultsy - 7, 10, 10);

      // pdf.addImage(scaleImg, 'PNG', 18, 1000, 40, 40);
    };
    // legends = LegendControl.getLegendUrls;
    var layers =
      this.props.mapComponent.values_.layergroup.values_.layers.array_[1]
        .values_.layers.array_;
    var offset = 0;
    var legends = [];
    layers.forEach((element) => {
      if (
        element.values_.visible &&
        this.state.selectedLayers.indexOf(element.values_.name) >= 0
      ) {
        var layer = element.values_.name;

        var url =
          element.values_.source.urls[0] +
          "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
          element.values_.source.params_.LAYERS +
          // .split(":")[0]+":"+layer +
          "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        legends.push(url);

        // ?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&STRICT=false&style=line
      }
    });
    var images = [];
    const legendUrls = this.getLegendUrls();
    legendUrls.forEach((urlObj) => {
      var url = urlObj.url;
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;

      img.onload = function () {
        var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);

        var uri = {
          uri: canvas.toDataURL("image/png"),
          h: canvas.height,
          w: canvas.width,
        };
        // var uri = canvas.toDataURL("image/png");
        images.push(uri); //-> "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWP4z8DwHwAFAAH/q842iQAAAABJRU5ErkJggg=="
        if (images.length == legends.length) {
          var offset = 0;
          var height = 0;
          var coulumns = 0;
          images.forEach((element) => {
            if (element.h + offset + height >= ylimit - 70) {
              xoffset = xoffset + 50;
              offset = 0;
              height = 0;
              coulumns = coulumns + 1;
            } else {
              // offset += 5;
            }
            height = height + element.h;
            if (
              (layout == "L1" && coulumns < 5) ||
              (layout != "L1" && coulumns < 3)
            ) {
              pdf.addImage(
                element.uri,
                "JPEG",
                legendx + xoffset,
                legendy + offset
              );
              offset = offset + element.h / 4;
            }
          });
          window.open(pdf.output("bloburl"), "_blank");
        }
      };
    });
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    this.setState({ heading: "" });
    this.setState({ subHeading: "" });
    // pdf.save("map.pdf");
  };

  getLegendUrls = () => {
    const { mapLayers } = this.props;
    let layerTree = getLayerTree();
    let urls = [];
    for (let i = 0; i < mapLayers.length; i++) {
      if (mapLayers[i].getVisible() && mapLayers[i].type == "TILE") {
        let layer = mapLayers[i].getSource().getParams().LAYERS;
        let layerName = "";
        let layerNameValue = mapLayers[i].getProperties().name;
        let url = null;
        let style = null;
        layerTree.map((treeItem) => {
          if (treeItem.hasOwnProperty("children")) {
            treeItem.children.forEach((layer) => {
              if (layer.value == layerNameValue) {
                layerName = layer.label;
                if (layer.style != null) style = layer.style;
              }
            });
          }
        });
        if (style == null) {
          url =
            mapLayers[i].getSource().urls[0] +
            "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
            layer +
            "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        } else {
          url =
            mapLayers[i].getSource().urls[0] +
            "?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LAYER=" +
            layer +
            "&STYLE=" +
            style +
            "&LEGEND_OPTIONS=forceLabels:on;fontName:Lucida%20Sans%20Regular";
        }
        var element = {};
        element.layerName = layerName;
        element.url = url;
        element.layer = layerNameValue;

        urls.push(element);
      }
    }
    // this.setState({legendUrls:urls})
    // this.props.updateLegendUrls(urls);
    return urls;
  };
  componentDidMount() {}
  render() {
    const { showWidget } = this.props;
    return (
      <React.Fragment>
        {showWidget === "Print Map" && (
          <Collapse in={showWidget === "Print Map"} className="exportPanels">
            <Grid>
              <Row className="show-grid">
                <Col>
                  <Panel bsStyle="primary">
                    <Panel.Heading>
                      <Panel.Title componentClass="h3">
                        Export To PDF
                      </Panel.Title>
                      <ButtonToolbar className="pull-right button">
                        <img
                          src={closebtn}
                          className="panelheadbtn"
                          onClick={this.props.handleShowExportPanelControl}
                        />
                      </ButtonToolbar>
                    </Panel.Heading>
                    <Panel.Body className="exportPanelsBody">
                      <form>
                        <p>
                          Disclaimer : Select required layers before printing
                        </p>

                        <FormGroup controlId="formControlsText">
                          <ControlLabel>Heading</ControlLabel>
                          <FormControl
                            componentClass="input"
                            placeholder="Heading"
                            onChange={this.handleChangeHeading}
                            value={this.state.heading}
                          />
                          <ControlLabel>Sub Heading</ControlLabel>
                          <FormControl
                            componentClass="input"
                            placeholder="Sub Heading"
                            onChange={this.handleChangeSubheading}
                            value={this.state.subHeading}
                          />
                        </FormGroup>
                        <FormGroup controlId="formControlsSelect">
                          <ControlLabel>Page size</ControlLabel>
                          <FormControl
                            componentClass="select"
                            placeholder="select"
                            onChange={this.handleChangePageSize}
                            value={this.state.pageSize}
                          >
                            {/* <option value="a0">A0</option> */}
                            <option value="a3">A3</option>
                            {/* <option value="a4">A4</option> */}
                          </FormControl>
                          <ControlLabel>Layout</ControlLabel>
                          <FormControl
                            componentClass="select"
                            placeholder="select"
                            onChange={this.handleChangeLayout}
                            value={this.state.layout}
                          >
                            <option value="L1">Layout 1</option>
                            <option value="L2">Layout 2</option>
                            <option value="L3">Layout 3</option>
                          </FormControl>
                          {/* <ControlLabel>Resolution </ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          name="resolution"
                          value={this.state.resolution}
                          onChange={this.handleChange}>
                          <option value="72" defaultValue>
                            72 dpi (fast)
                          </option>
                          <option value="150">150 dpi</option>
                          <option value="300">300 dpi (slow)</option>
                        </FormControl> */}
                        </FormGroup>
                      </form>
                      <div className="exportButtonContainer">
                        <div className="exportButtons">
                          <Button
                            bsStyle="success"
                            bsSize="small"
                            onClick={this.handleExportPdf}
                          >
                            Export PDF
                          </Button>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            </Grid>
          </Collapse>
        )}
      </React.Fragment>
    );
  }
  legendLayerSelected = (e, layer) => {
    // e.preventDefault();
    var layers = [...this.state.selectedLayers];
    var index = layers.indexOf(layer);
    if (index < 0) layers.push(layer);
    else layers.splice(index, 1);
    this.setState({ selectedLayers: layers });
  };
}

function mapStateToProps(state) {
  return {
    mapComponent: state.mapReducer.OlMap,
    legendUrls: state.mapReducer.legendUrls,
    showWidget: state.mapReducer.showWidget,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportPdfPanel);
