import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Col,
  ControlLabel,
  FormGroup,
  FormControl,
  Button
} from "react-bootstrap";
import { setPageHeader } from "../../containers/page_layout/data/action";
import { addOrUpdateLayerCategory, fetchLayerCategory } from "./data/action";
import { snackbarActions } from "../snackbar/data/action";
import { getDropdownData } from "../../containers/dropdown/data/action";
import dropdownTypes from "../../containers/dropdown/dropdownTypes";

class AddEditLayerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        value: "",
        label: "",
        local_body_id: "",
        layer_category_order: ""
      }
    };
  }
  componentDidMount() {
    this.props.getDropdownData(dropdownTypes.LOCAL_BODY);
    if (this.props.match.params.layercategory_id) {
      this.props.setPageHeader("Layer Category", "Edit", "");
      this.props.fetchLayerCategory(this.props.match.params.layercategory_id);
    } else this.props.setPageHeader("Layer Category", "Add", "");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saveSuccess !== this.props.saveSuccess) {
      this.props.showSnackbar("Saved Successfully..!");
      this.props.history.push("/layercategory");
    }
    if (prevProps.layerCategory !== this.props.layerCategory) {
      this.setState({
        data: { ...this.props.layerCategory }
      });
    }
  }
  handleInputChange = event => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value }
    });
  };
  onSubmit = event => {
    event.preventDefault();
    this.props.addOrUpdateLayerCategory(this.state.data);
  };
  handleCancel = () => {
    this.props.history.push("/layercategory");
  };
  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup>
            <ControlLabel>Label</ControlLabel>
            <FormControl
              type="input"
              placeholder="Label"
              value={this.state.data.label}
              name="label"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup controlId="formHorizontalPassword">
            <ControlLabel>Value</ControlLabel>
            <FormControl
              type="input"
              placeholder="Value"
              value={this.state.data.value}
              name="value"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup controlId="formHorizontalPassword">
            <ControlLabel>Layer Category Order</ControlLabel>
            <FormControl
              type="input"
              placeholder="Layer Category Order"
              value={this.state.data.layer_category_order}
              name="layer_category_order"
              onChange={this.handleInputChange}
              required
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <FormGroup controlId="local_body_id">
            <ControlLabel>Local Body </ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="select"
              value={this.state.data.local_body_id}
              defaultValue={this.state.data.local_body_id}
              name="local_body_id"
              onChange={this.handleInputChange}
              required
            >
              <option value="">None</option>
              {this.props.localBodies.map(localBody => {
                return (
                  <option value={localBody.id} key={localBody.id}>
                    {localBody.value}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={21} lg={12}>
          <hr className="hr-primary" />
        </Col>
        <Col xs={12} sm={12} md={21} lg={12}>
          <FormGroup>
            <Button type="submit" bsStyle="success">
              Save
            </Button>{" "}
            <Button bsStyle="warning" onClick={this.handleCancel}>
              Cancel
            </Button>
          </FormGroup>
        </Col>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    saveSuccess: state.layerCategory.saveSuccess,
    layerCategory: state.layerCategory.layerCategory,
    localBodies: state.dropdown.localBodies
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPageHeader: (title, subtitle, action) =>
      dispatch(setPageHeader(title, subtitle, action)),
    addOrUpdateLayerCategory: data => dispatch(addOrUpdateLayerCategory(data)),
    fetchLayerCategory: layercategory_id =>
      dispatch(fetchLayerCategory(layercategory_id)),
    showSnackbar: snackbarMessage =>
      dispatch(snackbarActions.showSnackbar(snackbarMessage)),
    getDropdownData: type => dispatch(getDropdownData(type))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddEditLayerCategory)
);
