import axios from "axios";
import { BASE_URL, ADMIN_URL } from "../../config";
import localStorageHandler from "../storage/";

const API = () => {
  const defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use(
    (config) => onRequest(config),
    (error) => onRequestError(error)
  );
  instance.interceptors.response.use(
    (response) => onResponse(response),
    (error) => onResponseError(error)
  );
  return instance;
};
export default API();

//Request Interceptors
function onRequest(config) {
  const token = localStorageHandler.getToken();
  if (
    config.url.indexOf("login") < 0 ||
    config.url.indexOf("forgotpassword") < 0 ||
    config.url.indexOf("reset-password")
  )
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
}

//Response Interceptors
function onResponse(response) {
  return response;
}

function onRequestError(error) {
  console.log("error", error);
  return error;
}
function onResponseError(error) {
  console.log("error", error.response);

  if (error.response.status == 401) {
    localStorageHandler.removeSession();
    window.location.reload(ADMIN_URL + `map/logout/`);
    if (window.location.pathname != "/login") {
      window.location.reload("/login");
    }
  }
  if (error.response.status == 500) {
    console.log("Somthing went wrong");
  }
  return error;
}
