import {
  BUFFER_FETCHING,
  BUFFER_FETCH_SUCCESS,
  BUFFER_FETCH_FAILED,
  FETCHING_BUFFERLAYER_SUCCESS,
  UPDATE_BUFFERSEARCH_LAYER,
} from "./constants";
import BufferService from "../../../../../../service/BufferService";
import { loadingActions } from "../../../../../../components/loader/data/action";
import { snackbarActions } from "../../../../../../components/snackbar/data/action";

export const mapQueryBuffer = (layerlist, geometry, featureGeometry) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(loadingActions.isloading());
    BufferService.mapQueryBuffer(layerlist, geometry, featureGeometry).then(
      (response) => {
        if (response.status === 200) {
          dispatch(success(response));
        } else {
          dispatch(failure(response));
          dispatch(snackbarActions.showSnackbar("Something went wrong"));
        }
        dispatch(loadingActions.loadingComplete());
      }
    );
    function request() {
      return { type: BUFFER_FETCHING };
    }
    function success(response) {
      return { type: BUFFER_FETCH_SUCCESS, response };
    }
    function failure(error) {
      return { type: BUFFER_FETCH_FAILED, error };
    }
  };
};
