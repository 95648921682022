import APIRequest from "../util/api/APIRequest";

const getDropdownData = url => {
  return APIRequest({
    url: url,
    method: "GET",
    params: {
      page: 1,
      limit: 1000
    }
  });
};

const DropdownService = {
  getDropdownData
};
export default DropdownService;
